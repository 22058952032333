import React from 'react'
import { AccordionItem } from '../AccordionItem/AccordionItem';
import { FinishGroup } from '../FinishGroup/FinishGroup';
import { ImgRadio } from '../ImgRadio/ImgRadio';
import { PresetsOption } from '../PresetsOption/PresetsOption';
import { StandartOptionsWrap } from '../StandartOptionsWrap/StandartOptionsWrap';
import { StandartOptionsWrapScroll } from '../StandartOptionsWrapScroll/StandartOptionsWrapScroll';
import { SwitchCenter } from '../SwitchCenter/SwitchCenter';

export const DistributeComponents = ({ ...props }) => {

    const { type, data } = props;
    let params: any = { ...data };

    switch (type) {

        case 'Accordion': {
            return <AccordionItem {...params} />
        }

        case 'StandartOptionsWrapScroll': {
            return <StandartOptionsWrapScroll {...params} />
        }

        case 'StandartOptionsWrap': {
            return <StandartOptionsWrap {...params} />
        }

        case 'SwitchCenter': {
            return <SwitchCenter {...params} />
        }

        case 'FinishGroup': {
            return <FinishGroup {...params} />
        }

        case 'ImgRadio': {
            return <ImgRadio {...params} />
        }
        
        case 'PresetsOption': {
            return <PresetsOption {...params} />
        }

        case 'hiddenProperty': {
            return (<></>)
        }
        
        default:
            return (<div>default </div>)

    }
}