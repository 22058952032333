import { objSetConfigurationT } from "../../redux/features/threekit/threekit.types";
import { modelSectionalT, objectActiveParamsT, optionValueT } from "../../redux/features/ui/ui.types";
import { getObjAssetIdFromThreekit, getObjNameThreekitAtributeComponentIdUI, getValueFromThreekit } from "../../redux/selectors/ui.selector";
import { RootState } from "../../redux/store";
import { getNameForArmNotFirstWord, isSameWordsInStrings } from "../supportUtils";

export const getNewValueForArmSelector = (
    arrValuesArmSelector: optionValueT[],
    oldValueArmSelector: string
) => {
    let newValue = "";
    arrValuesArmSelector.forEach((value: optionValueT) => {
        const isSameNames = isSameWordsInStrings(
            value["name"].toLocaleLowerCase(),
            oldValueArmSelector.toLocaleLowerCase()
        );
        if (isSameNames) {
            newValue = value.name;
        }
    });
    return newValue;
};

export const getObjForUpdateThreekit = (
  store: RootState,
  idValueParamUI: string,
  valueParamUI: string
): { [key: string]: any } | null => {
  const objNameThreekitAtributeComponentIdUI =
    getObjNameThreekitAtributeComponentIdUI(store)(idValueParamUI);

  if (Object.keys(objNameThreekitAtributeComponentIdUI).length === 0)
    return null;

  const nameThreekitAttribute = Object.keys(
    objNameThreekitAtributeComponentIdUI
  )[0];

  const objAssetIdForValue = getValueFromThreekit(store)(
    nameThreekitAttribute,
    valueParamUI
  );

  // const valueForThreekit = !!objAssetIdForValue && !!objAssetIdForValue.assetId ? objAssetIdForValue : valueParamUI;

  return { [nameThreekitAttribute]: objAssetIdForValue };
};

export const getFullObjForUpdateThreekit = (store: RootState, objParamsUI: objectActiveParamsT) => {
    let objUpdatedAttributes: objSetConfigurationT = {};
    Object.keys(objParamsUI).forEach((objKeyParamUI: string) => {
        const idValueParamUI = objKeyParamUI;
        const valueParamUI = objParamsUI[objKeyParamUI];
        if (["ArmSelectorWidth"].includes(idValueParamUI)) return;
        const objForUpdateThreekit = getObjForUpdateThreekit(store, idValueParamUI, valueParamUI);
        objUpdatedAttributes = { ...objUpdatedAttributes, ...objForUpdateThreekit }
    })

    return objUpdatedAttributes;
    
}