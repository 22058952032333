import React from 'react'
import s from './InfoHorizentalMode.module.scss'
export const InfoHorizentalMode = () => {
    return (
        <div className={s.wrap} >
            <div className={s.icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                    <path d="M0 94.5813C-3.24398e-08 97.3017 2.20544 99.5074 4.92611 99.5074H20.4431C20.586 99.6974 20.7825 99.8446 21.0103 99.9269C21.1409 99.974 21.2816 100 21.4286 100H25.8621C26.265 100 26.6229 99.8066 26.8475 99.5074H30.2953C30.52 99.8066 30.8779 100 31.2808 100H35.7143C36.1172 100 36.4751 99.8066 36.6997 99.5074H94.5813C97.3019 99.5074 99.5074 97.3017 99.5074 94.5813V56.1576C99.5074 53.4372 97.3019 51.2315 94.5813 51.2315H34.7293C34.5046 50.9323 34.1467 50.7389 33.7438 50.7389H24.3842C24.1863 50.7389 23.9991 50.7856 23.8332 50.8688C23.6617 50.9549 23.513 51.0795 23.3988 51.2315H4.92611C2.20544 51.2315 4.9064e-07 53.4372 4.58199e-07 56.1576L0 94.5813Z" fill="#9AA0A7" />
                    <path d="M5.41872 97.5369C3.78635 97.5369 2.46305 96.2137 2.46305 94.5813L2.46305 56.1576C2.46305 54.5253 3.78635 53.202 5.41872 53.202H94.0887C95.721 53.202 97.0443 54.5253 97.0443 56.1576V94.5813C97.0443 96.2137 95.721 97.5369 94.0887 97.5369H5.41872Z" fill="#101820" />
                    <path d="M56.1576 0C53.4372 0 51.2315 2.20544 51.2315 4.92611V20.4431C51.0415 20.586 50.8943 20.7825 50.812 21.0103C50.7649 21.1409 50.7389 21.2816 50.7389 21.4286V25.8621C50.7389 26.265 50.9323 26.6229 51.2315 26.8475V30.2953C50.9323 30.52 50.7389 30.8779 50.7389 31.2808V35.7143C50.7389 36.1172 50.9323 36.4751 51.2315 36.6997V94.5813C51.2315 97.3019 53.4372 99.5074 56.1576 99.5074H94.5813C97.3019 99.5074 99.5074 97.3017 99.5074 94.5813V34.7293C99.8066 34.5046 100 34.1467 100 33.7438V24.3842C100 24.1863 99.9533 23.9991 99.8701 23.8332C99.784 23.6617 99.6594 23.513 99.5074 23.3988V4.92611C99.5074 2.20544 97.3017 0 94.5813 0H56.1576Z" fill="#B57345" />
                    <path d="M53.202 5.41872C53.202 3.78635 54.5253 2.46305 56.1576 2.46305H94.5813C96.2137 2.46305 97.5369 3.78635 97.5369 5.41872V94.0887C97.5369 95.721 96.2137 97.0443 94.5813 97.0443H56.1576C54.5253 97.0443 53.202 95.721 53.202 94.0887V5.41872Z" fill="#E9E7DB" />
                    <path d="M19.2118 41.2562H16.2562C16.2562 27.266 28.243 22.7833 34.2365 22.2906V18.5961L44.5813 25.7389L34.2365 32.1429V28.202C23.399 28.202 19.7044 36.9048 19.2118 41.2562Z" fill="#B57345" />
                </svg>
            </div>
            <span className={s.text}>Sorry, horizontal mode is not supported for this application. Please use portrait mode.</span>
        </div>
    )
}
