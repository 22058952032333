import React, { useEffect, useRef, useState } from "react";
import { getModalInfo } from "../../../redux/selectors/ui.selector";
import s from "./RefreshConfiguratorMesagge.module.scss";

import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

export default function ModalRefreshConfiguratorMesagge() {
  const dispatch = useAppDispatch();
  const [copied, setCopied]: any = useState(false);

  const { isOpen, data }: any = useAppSelector((state) =>
    getModalInfo(state)("RefreshConfiguratorMesagge")
  );
  const wrapperRef: any = useRef(null);
  const copyedTextInput: any = useRef<HTMLInputElement>(null);

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.wrapper__block} ref={wrapperRef}>
            <div className={s.title}>
              Your session has expired due to inactivity.
            </div>
            <div className={s.subtitle}>
              Please follow this{" "}
              <a href={!!data ? data["linkText"] : ""}>link</a> to continue or
              copy & paste the link below into the browser
            </div>
            <div className={s.linkWrap}>
              <input
                ref={copyedTextInput}
                type="text"
                value={!!data ? data["linkText"] : ""}
                readOnly={true}
                className={s.linkText}
              />
              <CopyToClipboard
                text={data["linkText"]}
                onCopy={() => setCopied(true)}
              >
                <button className={s.btnCopyLink}>
                  <img
                    src="/images/assets/svg/CopyTextIcon.svg"
                    alt=""
                  />
                  {!copied ? "Copy link" : "Link Copied"}
                </button>
              </CopyToClipboard>
            </div>
            <div onClick={() => (window.location.href = "/")} className={s.btn}>
              Return to Configurator
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
