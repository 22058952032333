import { RootState } from "../../store";
import { getEffectiveAccountId } from "../ui.selector";

export const getChectIsUserRoolGuest = (state: RootState) => {
  const effectiveAccountId = getEffectiveAccountId(state);

  return (
    effectiveAccountId === undefined ||
    effectiveAccountId === "DEFAULT" ||
    effectiveAccountId === "Base account: no price"
  );
};
