import {
  getActiveCollectionId,
  getActiveParamFromIdComponentUI,
} from "../../redux/selectors/ui.selector";
import { store } from "../../redux/store";

export const checkIsOttomanNotMinsky = (arrAccordionsForActiveTab: any) => {
  const storeCopy = JSON.parse(JSON.stringify(store.getState()));
  const CollectionId = getActiveCollectionId(storeCopy);
  const valueActiveItemType =
    getActiveParamFromIdComponentUI("item_type")(storeCopy);

  if (
    CollectionId === "ottoman" &&
    arrAccordionsForActiveTab.length > 1 &&
    !!valueActiveItemType &&
    valueActiveItemType !== "Minsky Ottomans"
  ) {
    return arrAccordionsForActiveTab.slice(0, 1);
  } else {
    return arrAccordionsForActiveTab;
  }
};
