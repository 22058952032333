import { modelT } from "../../redux/features/ui/ui.types";

export const modelsChairs: modelT[] = [
  {
    img: "/images/assets/ClassicSofa/SofaPlus.png",
    label: "Hexley Chair",
    nameValueThreekit: "Hexley Chair",
  },
  {
    img: "/images/assets/ClassicSofa/Sofa.png",
    label: "Ada Chair",
    nameValueThreekit: "Ada Chair",
  },
  // {
  //     img: '/images/assets/ClassicSofa/LoftSofa.png',
  //     label: 'Ada Swivel Chair',
  //     nameValueThreekit: 'Ada Swivel Chair',
  // },
  {
    img: "/images/assets/ClassicSofa/Loveseat.png",
    label: "Watson Chair",
    nameValueThreekit: "Watson Chair",
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Watson Swivel Chair",
    nameValueThreekit: "Watson Swivel Chair",
  },
  {
    img: "/images/assets/ClassicSofa/CuddleChair.png",
    label: "Hexley Swivel Chair",
    nameValueThreekit: "Hexley Swivel Chair",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Zenon Chair",
    nameValueThreekit: "Zenon Chair",
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Zenon Swivel Chair",
    nameValueThreekit: "Zenon Swivel Chair",
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Popper Chair",
    nameValueThreekit: "Popper Chair",
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Popper Swivel Chair",
    nameValueThreekit: "Popper Swivel Chair",
  },
  // {
  //     img: '/images/assets/ClassicSofa/CuddleOttoman.png',
  //     label: 'Crick Swivel Chair',
  //     nameValueThreekit: "Crick Swivel Chair",
  // },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Crick Chair",
    nameValueThreekit: "Crick Chair",
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Naomi Accent Chair",
    nameValueThreekit: "Naomi Accent Chair",
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Sofie Accent Chair",
    nameValueThreekit: "Sofie Accent Chair",
  },
];
