import { modelT } from "../../redux/features/ui/ui.types";

export const modelsClassicSofa: modelT[] = [
  {
    img: "/images/assets/ClassicSofa/SofaPlus.png",
    label: "Sofa Plus",
    nameValueThreekit: "Sofa Plus",
    description: "Longer in length",
    Clarification: () => {
      return (
        <span>
        3 seat cushions
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/Sofa.png",
    label: "Sofa",
    nameValueThreekit: "Sofa",
    description: "Classic sofa length",
    Clarification: () => {
      return (
        <span>
        2 seat cushions
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/LoftSofa.png",
    label: "Loft Sofa",
    nameValueThreekit: "Loft Sofa",
    description: "A more compact sofa for smaller spaces",
    Clarification: () => {
      return (
        <span>
         2 seat cushions
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/Loveseat.png",
    label: "Loveseat",
    nameValueThreekit: "Loveseat",
    description: "Most compact two-seater",
    Clarification: () => {
      return (
        <span>
        2 seat cushions
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Arm Chair",
    nameValueThreekit: "Arm Chair",
    description: "Classic size chair",
    Clarification: () => {
      return (
        <span>
        
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/CuddleChair.png",
    label: "Cuddle Chair",
    nameValueThreekit: "Cuddle Chair",
    description: "Extra wide chair",
    Clarification: () => {
      return (
        <span>
       
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Ottoman",
    nameValueThreekit: "Ottoman",
    description: "Classic ottoman",
    Clarification: () => {
      return (
        <span>
       
        </span>
      );
    },
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Cuddle Ottoman",
    nameValueThreekit: "Cuddle Ottoman",
    description: "Wider and longer ottoman",
    Clarification: () => {
      return (
        <span>
        
        </span>
      );
    },
  },
];
