import { getParamsForApiPriceInfo, updatePriceInfoModels } from "../../api";
import { instanceApiFunc } from "../../api/instanceApi";
import { PricesAndSkuResponseApiT } from "../../api/types/typesModelsPriceInfo";
import { getMaxPillowCurentSofaRelativeThreekit } from "../../function/pillows/pilowThreekitFunc";
import { updateThreeekitPillow } from "../../redux/actions/player.action";
import { changeLoadPlayerState } from "../../redux/features/ui/uiSlice";
import {
  getPillows,
  getThreekitModels,
} from "../../redux/selectors/threekit.selectors";
import {
  getEffectiveAccountId,
  getObjActiveParamsUI,
  getUserType,
} from "../../redux/selectors/ui.selector";
import { store } from "../../redux/store";
import { isSectionalConfigurator } from "../supportUtils";
import {
  getDisplayAttributes,
  getPillowMaterialInModel,
  updateMaterialPillow,
} from "../threekit/attributesFunctions";

export const getInfoPillowByLastAssetId = (assetId: string) => {
  const configuration = window.configurator.getConfiguration();
  let indexActivePillow: any = null;
  configuration["setPillows"].map((item: any, index: number) => {
    if (item.assetId === assetId) {
      indexActivePillow = index;
    }
  });
  
  const name = `Pillow ${indexActivePillow + 1}`
  let id = null;
  id = window.player.scene.find({
    from: window.player.player.api.instanceId,
    hierarchical: true,
    name: name,
  });
  const namePillow = window.player.scene.get({
    from: window.player.player.api.instanceId,
    id: id[0],
  }).name;
  const numberMaxPillowCurentSofaRelativeThreekit =
    getMaxPillowCurentSofaRelativeThreekit();

  if (
    indexActivePillow >= numberMaxPillowCurentSofaRelativeThreekit &&
    !isSectionalConfigurator()
  ) {
    id = [];
  }

  return { id: id[0], namePillow, indexActivePillow };
};

export const getInfoPillowInStateByName = (name: string) => {
  const storeCopy = JSON.parse(JSON.stringify(store.getState()));
  const pillows = storeCopy.threeKit.pillows;

  let pillow;

  pillows.map((item: any, index: number) => {
    if (item.name === name) {
      pillow = item;
    }
  });
  return pillow;
};

type updateAllPillowsMaterialT = {
  objUpdatedAttributes?: any;
  updateMaterial?: any;
};

export async function updatePillowState({
  objUpdatedAttributes,
  item,
  newMaterial,
}: any) {
  const id = item.id;
  const MaterialInModel: any = await getPillowMaterialInModel(id);
  updatePillow(MaterialInModel[1], newMaterial);

  async function updatePillow(material: any, newMaterial: any) {
    const newMaterialAssetId = objUpdatedAttributes["Pillow material"].assetId;
    const PillowMaterial = material.values.find(
      (el: any) => el.assetId === newMaterialAssetId
    );
    let sku = PillowMaterial.metadata.sku
      ? PillowMaterial.metadata.sku
      : undefined;
    const storeCopy = JSON.parse(JSON.stringify(store.getState()));

    const userType = getUserType(storeCopy);
    const effectiveAccountId = getEffectiveAccountId(storeCopy);

    const namePillow = item.name;
    const pillowPrice: any = await getPricePillow({
      pillows: namePillow,
      fabric: newMaterial,
      sku,
      userType,
      effectiveAccountId,
    });
    //

    store.dispatch(
      updateThreeekitPillow({
        id: item.id,
        newAttributes: {
          materialObg: objUpdatedAttributes,
          material: newMaterial,
          skuMaterial: sku,
          price: pillowPrice.price,
          skuFull: pillowPrice.skuFull,
          skuShort: pillowPrice.skuShort,
        },
      })
    );
  }
}

export const updateAllPillowsMaterial = async ({
  objUpdatedAttributes = undefined,
}: updateAllPillowsMaterialT) => {
  const pillows = getPillows(store.getState());
  let listPillows: any[] = [];
  const selectionIds = window.player.selectionSet.ids;
  const material: any = await getPillowMaterialInModel(selectionIds[0]);
  const activeMaterialInfo = material[1].values.find(
    (item: any) =>
      item.assetId === objUpdatedAttributes["Pillow material"].assetId
  );
  pillows.map((item: any) => {
    if (
      item.id === selectionIds[0] &&
      selectionIds.length >= 1 &&
      objUpdatedAttributes
    ) {
      listPillows.push({
        id: item.id,
        materialObg: objUpdatedAttributes,
        material: activeMaterialInfo.name,
      });

      updatePillowState({
        objUpdatedAttributes,
        item,
        newMaterial: activeMaterialInfo.name,
      });
    } else {
      listPillows.push({
        id: item.id,
        materialObg: item.materialObg,
      });
    }
  });

  updateMaterialPillow(listPillows).then(async () => {
    store.dispatch(changeLoadPlayerState(false));
  });
};
export async function getPricePillow({
  pillows,
  fabric,
  sku,
  userType,
  effectiveAccountId,
}: any) {
  const objActiveParams = getObjActiveParamsUI(store.getState());
  const requestPillowData = {
    ...getParamsForApiPriceInfo("Pillow", { pillows, fabric }),
    sku,
    userType,
    effectiveAccountId,
  };
  const instanceApi = instanceApiFunc({
    authToken: process.env.REACT_APP_API_TOKEN || "",
    urlApi: process.env.REACT_APP_API_URL || "/api",
  });

  if (objActiveParams.pillows.length < 1) {
    return;
  }

  try {
    const resultUpdate = await updatePriceInfoModels(
      instanceApi,
      requestPillowData
    );

    return resultUpdate.data.Pillow;
  } catch (err) {
    console.error("error --- ==== ", err);
  }
}
