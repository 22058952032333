import {
  checkSideBindNextModel,
  createNameModel,
  getAngleCurrentModelFunc,
  getIndexModel,
  getKeyPrevModel,
} from "./../../utils/configSofa/utilsFuncModel";
import { RootState } from "../store";
import {
  bindDirectionType,
  objectModelT,
  objectNewPointT,
} from "../types/typeModels";
import {
  getPositionModificationPointT,
  modelsT,
  positionObjectT,
  positionOptions,
} from "../types/typeThreekitStateModal";
import {
  getPositionModificationPoint,
  getPointIndexPoint,
  getFollowingPointAfterKey,
  getPrevPoint,
  getNameActivePoint,
} from "./points.selector";
import { getModification } from "../../utils/configSofa/turningUtils";
import {
  baseLogicForAttached,
  cornerLogicForAttached,
  getSideBind,
  isCornerModels,
} from "../../function/BuildSection/baseFuncReletiveModel";
import {
  allListReverseRollsNotValid,
  getAllListRollsNotValid,
} from "../../function/BuildSection/constantion/validSectionConfiguration";

export const getAllModels = (state: RootState) => state.threeKit.allModels;
export const getModels = (state: RootState) =>
  Object.assign({}, state.threeKit.models);

// export const getPoints = (state: RootState) =>
//   Object.assign({}, state.threeKit.newPoints);

export const getActiveIdModel = (state: RootState) =>
  state.threeKit.activeModel;

export const getActiveUserModel = (state: RootState) =>
  state.threeKit.activeUserModel;

export const getSelectedModel = (state: RootState) => {
  const activeIdModel = getActiveIdModel(state);
  const models: any = getModels(state);
  let selectedKeyModel: any = Object.keys(models).filter(
    (keyModel: any) => models[keyModel]["id"] === activeIdModel
  );

  // if (!selectedKeyModel) return null;
  if (Object.keys(models).length === 1 && selectedKeyModel.length === 0) {
    selectedKeyModel = [models[Object.keys(models)[0]].id];
  }
  return {
    modification: getModification(selectedKeyModel[0]),
    selectedKeyModel: selectedKeyModel[0],
  };
};

export const checkIsFirst = (state: RootState) => {
  const activeModel = getActiveIdModel(state);
  const models = getModels(state);

  const isFirst = Object.keys(models).find(
    (key: any) => models[key].isFirst && activeModel === models[key].id
  );

  if (isFirst) {
    return true;
  }
  return false;
};
export const getSelectedModelId = (IdModel: any) => (state: RootState) => {
  const activeIdModel = IdModel;
  const models = getModels(state);
  let selectedKeyModel = Object.keys(models).filter(
    (keyModel: any) => models[keyModel]["id"] === activeIdModel
  );
  if (!selectedKeyModel) return null;
  return {
    modification: getModification(selectedKeyModel[0]),
    selectedKeyModel: selectedKeyModel[0],
  };
};

export const getModificationModel =
  (modification: getPositionModificationPointT) => (state: RootState) => {
    const models = getModels(state);
    return Object.keys(models).filter((modelKey) =>
      modelKey.includes(modification)
    );
  };
export const getFollowingKeyModelAfterCurrentModel =
  ({ modification, currentModel }: any) =>
  (state: RootState) => {
    const models = getModels(state);
    const listsKeyModelModification = getModificationModel(modification)(state);
    let listKeyFollowingKeyModelAfterKeyModel =
      listsKeyModelModification.filter((key: any) => {
        let model = models[key];
        if (model["position"] > currentModel["position"]) return true;
      });
    return listKeyFollowingKeyModelAfterKeyModel;
  };

export const getModelsForObject =
  (keysObj: any) =>
  (state: RootState): any => {
    const models = getModels(state);
    let listKeyFollowingKeyModelAfterKeyModel = {};
    keysObj.forEach((key: any) => {
      listKeyFollowingKeyModelAfterKeyModel = {
        ...listKeyFollowingKeyModelAfterKeyModel,
        [key]: models[key],
      };
    });
    return listKeyFollowingKeyModelAfterKeyModel;
  };

const getPrevModal =
  (currentIndexModal: number, modification: getPositionModificationPointT) =>
  (state: RootState) => {
    const models = getModels(state);
    let keyPrevModel = getKeyPrevModel({ currentIndexModal, modification });
    return { [keyPrevModel]: models[keyPrevModel] };
  };

export const getInfoDeleteModel = (state: RootState): any => {
  const models = getModels(state);

  const selectedModelInfo: any = getSelectedModel(state);

  if (!selectedModelInfo) return null;

  const modification: any = selectedModelInfo["modification"];
  const selctedModel = models[selectedModelInfo["selectedKeyModel"]];
  const positionSelectionModel =
    models[selectedModelInfo["selectedKeyModel"]]["position"];

  const followingKeyModelAfterKeyModel = getFollowingKeyModelAfterCurrentModel({
    modification,
    currentModel: selctedModel,
  })(state);
  let followingModelAfterKeyModel = getModelsForObject(
    followingKeyModelAfterKeyModel
  )(state);
  let newFollowingModelAfterKeyModel = {};

  Object.keys(followingModelAfterKeyModel).forEach((key: any) => {
    let indexModel = Number(key.split(`${modification}_`)[1].split("#")[0]);
    let nextIndexModel = indexModel - 1;
    const keyNewModel = createNameModel(modification, nextIndexModel);
    let model: objectModelT = JSON.parse(
      JSON.stringify(followingModelAfterKeyModel[key])
    );

    model["position"] = nextIndexModel;
    newFollowingModelAfterKeyModel = {
      ...newFollowingModelAfterKeyModel,
      [keyNewModel]: model,
    };
  });

  const prevModal = getPrevModal(positionSelectionModel, modification)(state);

  let newObjectModel = {
    ...prevModal,
    ...newFollowingModelAfterKeyModel,
  };

  let followingPointAfterKeyModel = getFollowingPointAfterKey(
    positionSelectionModel,
    modification
  )(state);
  let newFollowingPointAfterKeyModel = {};

  Object.keys(followingPointAfterKeyModel).forEach((key: any) => {
    let indexModel = Number(key.split(`${modification}_`)[1].split("#")[0]);
    let prevIndexModel = indexModel - 1;
    const keyNewModel = `point_${modification}_${prevIndexModel}#`;
    let point: objectNewPointT = JSON.parse(
      JSON.stringify(followingPointAfterKeyModel[key])
    );
    point["pointIndex"] = prevIndexModel;
    newFollowingPointAfterKeyModel = {
      ...newFollowingPointAfterKeyModel,
      [keyNewModel]: point,
    };
  });

  let prevPoint = getPrevPoint(positionSelectionModel, modification)(state);

  let newObjectPoint = {
    ...prevPoint,
    ...newFollowingPointAfterKeyModel,
  };

  return {
    newObjectModel,
    newObjectPoint,
    modification,
    positionSelectionModel,
  };
};

export const getAssetIdById = (state: RootState, id: string) => {
  const models = getModels(state);

  let assetId = "";

  Object.keys(state.threeKit.models).forEach((key: any) => {
    if (models[key].id === id) {
      assetId = models[key].assetId;
    }
  });

  return assetId;
};

export const getIdPrevModel = (
  state: RootState
): positionObjectT<positionOptions> | null => {
  const positionModificationPoint = getPositionModificationPoint(state);
  const positionPoint = getPointIndexPoint(state);
  if (!positionPoint) return null;
  return getKeyPrevModel({
    modification: positionModificationPoint,
    currentIndexModal: positionPoint,
  });
};

export const getPrevModel = (state: RootState): objectModelT | null => {
  const models = getModels(state);
  const idPrevModel = getIdPrevModel(state);

  if (!idPrevModel) return null;
  return models[idPrevModel];
};
export const getNewNextKeyModel = (state: RootState) => {
  const positionModificationPoint = getPositionModificationPoint(state);
  const positionPoint = getPointIndexPoint(state);
  return createNameModel(positionModificationPoint, positionPoint);
};

export const checkIsEdgeElement = (state: RootState) => {
  const newKeyModel = getNewNextKeyModel(state);
  const modelsInView = getModels(state);
  return Object.keys(modelsInView).includes(newKeyModel);
};
export const getAllFollowingKeyModel = (state: RootState) => {
  const modelsInView = getModels(state);
  const positionModificationPoint = getPositionModificationPoint(state);
  const pointIndexPoint = getPointIndexPoint(state);

  const listKeys = Object.keys(modelsInView).filter((modelKey) =>
    modelKey.includes(positionModificationPoint)
  );

  let listKeyFollowingObject = listKeys.filter((modelKey: any) => {
    let model = modelsInView[modelKey];
    if (pointIndexPoint <= model.position) return true;
  });

  return listKeyFollowingObject;
};

export const getAllFollowingModel = (state: RootState) => {
  const allFollowingKeyModel = getAllFollowingKeyModel(state);
  const modificationPoint = getPositionModificationPoint(state);
  const modelsInView: modelsT = getModels(state);
  let followingModel: modelsT = {};

  allFollowingKeyModel.forEach((key: any) => {
    let indexModel = Number(
      key.split(`${modificationPoint}_`)[1].split("#")[0]
    );
    let nextIndexModel = indexModel + 1;

    const keyNewModel = createNameModel(modificationPoint, nextIndexModel);

    let model: objectModelT = modelsInView[key];
    model["position"] = nextIndexModel;

    followingModel = {
      ...followingModel,
      [keyNewModel]: modelsInView[key],
    };
  });

  return followingModel;
};

export const getAngleCurrentModel =
  (typeCurrentModel: any) => (state: RootState) => {
    const positionModificationPoint = getPositionModificationPoint(state);
    const prevModel = getPrevModel(state);
    if (!prevModel) return null;
    return getAngleCurrentModelFunc({
      prevModel,
      positionModificationPoint,
      typeCurrentModel,
    });
  };

export const getNameModelById = (state: RootState) => (assetId: string) => {
  const allModels = getAllModels(state);
  const findModel = allModels.find(
    (modelObj: any) => modelObj["assetId"] === assetId
  );
  return findModel?.["name"];
};

export const getArrActiveModelsNames = (state: RootState) => {
  const activeModels = getModels(state);

  const activeModelsKeys = Object.keys(activeModels) as Array<
    keyof typeof activeModels
  >;

  const arrActiveModelsNames = activeModelsKeys.map(
    (modelKey: positionObjectT<positionOptions>) => {
      return activeModels[modelKey]["name"];
    }
  );

  return arrActiveModelsNames;
};

export const getCountActiveModels = (
  state: RootState
): { [key: string]: number } => {
  const activeModels = getModels(state);

  const activeModelsKeys = Object.keys(activeModels) as Array<
    keyof typeof activeModels
  >;

  const objCountModels = activeModelsKeys.reduce(
    (
      accumulator: { [key: string]: number },
      modelKey: positionObjectT<positionOptions>
    ) => {
      const name = activeModels[modelKey]["name"];
      return { ...accumulator, [name]: accumulator[name] + 1 || 1 };
    },
    {}
  );

  return objCountModels;
};

export const getNextKeyModel = (state: RootState) => {
  let positionModificationPoint = getPositionModificationPoint(state);
  const nameActivePoint = getNameActivePoint(state);
  let indexPoint = getIndexModel(nameActivePoint, positionModificationPoint);
  const keyNewModel = createNameModel(positionModificationPoint, indexPoint);
  return keyNewModel;
};
export const checkMiddleInsertModel = (state: RootState) => {
  const modelsForUI = getModels(state);
  const nextKeyModel = getNextKeyModel(state);
  const middleInsertModel = !!modelsForUI[nextKeyModel];
  return middleInsertModel;
};

export const getModelsAvailableForAttachment = (state: RootState) => {
  const drawModel = getPrevModel(state);
  if (!drawModel) return [];

  const allModels = getAllModels(state);
  const middleInsertModel = checkMiddleInsertModel(state);
  const nameActivePoint = getNameActivePoint(state);
  const side = checkSideBindNextModel(nameActivePoint);

  const positionModificationPoint = getPositionModificationPoint(state);

  let modelsAvailableForAttachment = allModels.filter((model: any) => {
    const isCorner = isCornerModels(model);
    const sidesBind = getSideBind(model);

    if (middleInsertModel) {
      if (baseLogicForAttached(sidesBind, side) && cornerLogicForAttached())
        return true;

      if (positionModificationPoint === "positive") {
        if (
          baseLogicForAttached(sidesBind, side) &&
          sidesBind.includes("Right")
        )
          return true;
      }
      if (positionModificationPoint === "negative") {
        if (baseLogicForAttached(sidesBind, side) && sidesBind.includes("Left"))
          return true;
      }
    } else {
      return baseLogicForAttached(sidesBind, side);
    }
    if (isCorner) {
      return cornerLogicForAttached();
    }
    return false;
  });

  return modelsAvailableForAttachment;
};

export const getNameModelsAvailableForAttachment = (state: RootState) => {
  const modelsAvailableForAttachment = getModelsAvailableForAttachment(state);

  return modelsAvailableForAttachment.map((item) => item["name"]);
};

export const getListSornNameModel = (state: RootState) => {
  const models = getModels(state);
  let objectNameodel = {};
  Object.keys(models).map((item) => {
    objectNameodel = {
      ...objectNameodel,
      [item]: models[item]["name"],
    };
  });

  let allNegativeKey: string[] = [];
  Object.keys(objectNameodel)
    .reverse()
    .forEach((key) => {
      if (key.includes("negative")) {
        allNegativeKey.push(key);
      }
    });
  let allFirstKey: string[] = [];
  Object.keys(objectNameodel).forEach((key) => {
    if (key.includes("first")) {
      allFirstKey.push(key);
    }
  });
  let allPositiveeKey: string[] = [];
  Object.keys(objectNameodel).forEach((key) => {
    if (key.includes("positive")) {
      allPositiveeKey.push(key);
    }
  });

  const sortName: any[] = [];

  allNegativeKey.forEach((key) => {
    sortName.push(models[key]["name"]);
  });
  allFirstKey.forEach((key) => {
    sortName.push(models[key]["name"]);
  });
  allPositiveeKey.forEach((key) => {
    sortName.push(models[key]["name"]);
  });

  return sortName;
};

export const isInvalidConfigurations = (state: RootState) => {
  let listSornNameModel = getListSornNameModel(state);

  let stringListNameModel = listSornNameModel.join();

  const allListRollsNotValid = getAllListRollsNotValid();
  let isError = false;

  allListRollsNotValid.forEach((listRollsConfig) => {
    let stringListRools: any = listRollsConfig.join();
    if (stringListNameModel.includes(stringListRools)) isError = true;
  });
  return isError;
};
