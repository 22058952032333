import React, { useEffect, useRef, useState } from "react";
import { getModalInfo } from "../../../redux/selectors/ui.selector";
import s from "./PillowMoveHint.module.scss";

import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { Close } from "../../../assets/svgCompopnent/Close";
import { TutorialBtn } from "../../../assets/svgCompopnent/TutorialBtn";
export default function ModalPillowMoveHint() {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) =>
    getModalInfo(state)("PillowMoveHint")
  );
  const wrapperRef: any = useRef(null);

  function closeFunc() {
    dispatch(openCloseModals({ name: "PillowMoveHint" }));
  }

  useEffect(() => {
    if (closeFunc) {
      const handleClickOutside =
        (wrapperRef: any, closeFunc: any) => (event: any) => {
          if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target)
          ) {
            closeFunc();
          }
        };
      document.addEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );

      return () => {
        document.removeEventListener(
          "mousedown",
          handleClickOutside(wrapperRef, closeFunc)
        );
      };
    }
  }, [wrapperRef]);

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.wrapper__block} ref={wrapperRef}>
            <div
              className={s.close}
              onClick={() =>
                dispatch(openCloseModals({ name: "PillowMoveHint" }))
              }
            >
              <Close />
            </div>
            <div className={s.content}>
              <img
                src={'/images/assets/modals/pillowHint.png'}
                alt=""
                className={s.img}
              />
              <div className={s.title}>
                To add a new pillow, you need to move the last pillow from its
                original position
              </div>
              <div className={s.subtitle}>
                You will need to do this process for each subsequent pillow,
                first moving them to the desired position/place, and then adding
                a new one
              </div>
              <div className={s.btn} onClick={() => closeFunc()}>Got it</div>
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
