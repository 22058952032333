import React, { useEffect, useState } from "react";
import s from "./AccordionItem.module.scss";
import { DistributeComponents } from "../DistributeComponents/DistributeComponents";
import { Input, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  getActiveCollectionId,
  getActiveParamFromIdComponentUI,
  getObjActiveParamsUIFromObjActiveParamsThreekit,
  getValuesFromIdAttribute,
} from "../../redux/selectors/ui.selector";
import {
  filterFabrics,
  FiltersControlsT,
  getActiveFilterControlsValues,
} from "../../utils/filterFabrics";
import { PillowMaterialHint } from "../Hints/PillowMaterialHint/PillowMaterialHint";
import {
  getPillowById,
  getThreekitActivePillow,
} from "../../redux/selectors/threekit.selectors";
import { store } from "../../redux/store";
import { getPillowMaterialInModel } from "../../utils/threekit/attributesFunctions";
import { setActiveParamsUI } from "../../redux/features/ui/uiSlice";
import { Close } from "../../assets/svgCompopnent/Close";
import InfoModal from "../InfoModal/InfoModal";

export const AccordionItem = ({ ...props }) => {
  const {
    id,
    label,
    embeddedView,
    handleShowHideAccordion,
    isActive,
    disabled,
    index,
    filter,
    item_type,
  } = props;
  const valuesOptionsFromThreekit = useAppSelector((state: any) =>
    getValuesFromIdAttribute(embeddedView[0].data.id)(state)
  );
  const [isPopupShown, setIsPopupShown] = useState(false);

  const valueActiveOption = useAppSelector(
    getActiveParamFromIdComponentUI(embeddedView[0].data.id)
  );
  const activePillow = useAppSelector((state) =>
    getThreekitActivePillow(state)
  );

  const [values, setValues] = useState<any[]>([]);
  const [filterObj, setFilterObj] = useState({
    Color: "",
    Material: "",
    Pattern: "",
    Text: "",
    "Pattern or Solid": "",
  });

  const [textFilter, setTextFilter] = useState(true);
  const dispatch = useAppDispatch();

  const actualpopupInfo = label === "Choose Leg Style & Finish";

  useEffect(() => {
    if (window.player.selectionSet) {
      const ids = window.player.selectionSet.ids;

      const state = store.getState();
      const pillow: any = getPillowById(state)(ids[0]);

      if (pillow && activePillow && window.configurator && pillow.materialObg) {
        const getActualPillowMaterial = async () => {
          const material: any = await getPillowMaterialInModel(id);
          const defaultPillowMaterialName = material[0];
          const objPayload = {
            PillowMaterial: defaultPillowMaterialName,
          };
          dispatch(setActiveParamsUI(objPayload));
        };
        getActualPillowMaterial();
      }
    }
  }, [activePillow]);

  const filterChecker =
    filterObj.Color.length !== 0 ||
    filterObj.Material.length !== 0 ||
    filterObj.Pattern.length !== 0 ||
    filterObj["Pattern or Solid"].length !== 0 ||
    filterObj.Text.length !== 0;

  useEffect(() => {
    if (filterChecker) {
      //@ts-ignore
      window.valuesOptionsFromThreekit = valuesOptionsFromThreekit;
      const value = filterFabrics(valuesOptionsFromThreekit, filterObj);
      setValues(value);
    } else if (
      (!!activePillow && id === "accordionPillowsFabrics") ||
      id === "accordionFurnitureFabrics"
    ) {
      setValues(valuesOptionsFromThreekit);
    }
  }, [filterObj]);

  useEffect(() => {
    if (
      values.length === 0 &&
      valuesOptionsFromThreekit.length !== 0 &&
      id.includes("Fabrics") &&
      !filterChecker
    ) {
      setValues(valuesOptionsFromThreekit);
    }
  }, [values]);

  if (
    id === "accordionPillowsFabrics" &&
    values.length === 0 &&
    valuesOptionsFromThreekit.length !== 0 &&
    !filterChecker
  ) {
    setValues(valuesOptionsFromThreekit);
  }

  const hideIfThisPillowMaterial =
    id !== "accordionPillowsFabrics" ||
    (!!activePillow && id === "accordionPillowsFabrics");

  function openPopup(e: any) {
    e.stopPropagation();
    setIsPopupShown(!isPopupShown);
  }

  const [activeFilters, setActiveFilters] = useState<FiltersControlsT>();

  const handleChangeFilter = (filterKey: string, value: string) => {
    const newFilterObj = { ...filterObj, [filterKey]: value };
    const newActiveFilters = getActiveFilterControlsValues(
      newFilterObj,
      valuesOptionsFromThreekit,
      activeFilters,
      filterKey
    );
    setActiveFilters(newActiveFilters);
  };

  const filtersList = !!activeFilters ? Object.values(activeFilters) : filter;

  return (
    <div className={`${s.acordionItem} ${isActive ? s.active : ""}`}>
      <div
        className={`${s.acordionItem_head} ${disabled ? s.disabled : ""}`}
        onClick={() => handleShowHideAccordion(id)}
      >
        <div className={s.acordionItem_title}>
          <p className={s.acordionItemIndex}>
            <span>{index}</span>
          </p>
          <span>{label}</span>
          {actualpopupInfo && (
            <div id={id} className={s.option_infoIconWrapper}>
              <img
                id={id + "img"}
                src="/images/assets/icon-info.svg"
                alt="icon-info"
                onClick={(e) => openPopup(e)}
              />
            </div>
          )}
          {actualpopupInfo && isPopupShown && (
            <InfoModal
              infoText={'All legs are 6" in length'}
              isPopupShown={isPopupShown}
              setIsPopupShown={setIsPopupShown}
              infoIconId={id}
            />
          )}
        </div>
        <div className={s.acordionItem_arrow}>
          <img src="/images/assets/svg/UpArrow.svg" alt="" />
        </div>
      </div>
      <div className={s.acordionItem_content} id={id + "content"}>
        {!activePillow && id === "accordionPillowsFabrics" && (
          <PillowMaterialHint />
        )}
        {((!!activePillow && id === "accordionPillowsFabrics") ||
          id === "accordionFurnitureFabrics") &&
          filtersList && (
            <div className={s.filter}>
              <div className={s.filter_header}>
                <div className={s.left}>
                  <p className={s.filter_header_title}>Selected Fabric:</p>
                  <p className={s.filter_header_activeItem}>
                    {valueActiveOption && valueActiveOption.replace("_", " ")}
                  </p>
                </div>
                <div className={s.right}>
                  <div
                    className={s.filter_header_button}
                    onClick={() => {
                      setFilterObj({
                        Color: "",
                        Material: "",
                        Pattern: "",
                        Text: "",
                        "Pattern or Solid": "",
                      });
                      setValues(valuesOptionsFromThreekit);
                      setTextFilter(!textFilter);
                    }}
                  >
                    <p>
                      {textFilter ? (
                        <img
                          src="/images/assets/svg/OpenFilterIcon.svg"
                          alt=""
                        />
                      ) : (
                        // <Close width="12" height="12" fill="#B57345" />
                        <Close />
                      )}
                    </p>
                    <p>Fabric Filters</p>
                  </div>
                </div>
              </div>
              {filtersList.length > 0 && !textFilter ? (
                <div
                  className={s.filter_items}
                  style={
                    filter.length > 1 ? { justifyContent: "space-between" } : {}
                  }
                >
                  {filtersList.map(
                    (
                      item: {
                        name: string;
                        values: { label: string; value: string }[];
                      },
                      index: number
                    ) => (
                      <Select
                        key={`${index}_${item.name}`}
                        className={s.select}
                        style={
                          filter.length <= 2
                            ? { width: "45%" }
                            : { width: "30%" }
                        }
                        defaultValue={item.name}
                        onChange={(value) => {
                          setFilterObj({ ...filterObj, [item.name]: value });
                          handleChangeFilter(item.name, value);
                        }}
                        options={item.values}
                      />
                    )
                  )}
                </div>
              ) : (
                <Input
                  className={s.filter_search}
                  placeholder="Search fabric by name"
                  onChange={(e) => {
                    function searchInData() {
                      if (e.target.value.length === 0) {
                        setFilterObj({
                          Color: "",
                          Material: "",
                          Pattern: "",
                          Text: "",
                          "Pattern or Solid": "",
                        });
                        setValues(valuesOptionsFromThreekit);
                      }
                      setFilterObj({ ...filterObj, Text: e.target.value });
                    }
                    searchInData();
                    // debounce(searchInData, 500);
                  }}
                  addonAfter={
                    <p>
                      <img src="/images/assets/svg/Search.svg" alt="" />
                    </p>
                  }
                />
              )}
            </div>
          )}
        {hideIfThisPillowMaterial && (
          <div className={s.acordionItem_contentInternal}>
            {!!embeddedView &&
              embeddedView.map((setting: any, indx: number) => {
                const hideInSections = setting.data.hideInSections;
                const hideIfNoneSkuInMaterial =
                  setting.data.id === "fabric" ||
                  setting.data.id === "fabric_2" ||
                  setting.data.id === "fabric_3" ||
                  setting.data.id === "PillowMaterial";
                const checkIsHideSection = hideInSections
                  ? !hideInSections.includes(item_type)
                  : true;
                const filterValuesForMaterial =
                  values.length >= 1 && hideIfNoneSkuInMaterial
                    ? values
                    : setting.data.values;
                // ? values.filter((val: any) => val.metadata.sku)
              

                return (
                  <>
                    {checkIsHideSection && (
                      <DistributeComponents
                        {...setting}
                        data={{
                          ...setting.data,
                          values: filterValuesForMaterial,
                        }}
                        key={`${setting.type}-${indx}`}
                      />
                    )}
                  </>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
