export const Point = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="54"
      height="50"
      viewBox="0 0 54 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="54" height="50" rx="25" fill="#B57345" />
      <path
        d="M27 18V32"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 25H34"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
