import { AxiosInstance } from "axios";
import { saveFileTo3kit } from "./savePDFtoThreekit";

export const generatePDF = async (instanceApiFunc: AxiosInstance, pdfData: any): Promise<string | undefined> => {
  try {
    const response = await instanceApiFunc.post(`/pdf`, pdfData, {
      responseType: "blob", // This ensures the response is treated as binary data
    });

    if (!response.data) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log("PDF успішно завантажено.");

    // const url = window.URL.createObjectURL(response.data);

    const linkPdf = await saveFileTo3kit(response.data, "result.pdf");

    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "generated.pdf";
    // document.body.appendChild(a);
    // a.click();
    // a.remove();
    return linkPdf;
  } catch (error) {
    console.error("Помилка під час запиту:", error);
  }
};
