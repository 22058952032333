import React, { ReactElement, useEffect } from "react";
import { getObjActiveParamsUI } from "../../redux/selectors/ui.selector";
import { useAppSelector } from "../../utils/hooks";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { AdditionalHint } from "../Hints/AdditionalHint/AdditionalHint";
import { PillowHint } from "../Hints/PillowHint/PillowHint";
import { SilhouetteHint } from "../Hints/SilhouetteHint/SilhouetteHint";
import s from "./StandartOptionsWrap.module.scss";

export const ComponentHints = ({ ...props }) => {
  const { label, note, id } = props;
  const objActiveParamsUI = useAppSelector((state) =>
    getObjActiveParamsUI(state)
  );

  const StyledLabel = () => {
    const item_type = objActiveParamsUI["item_type"];
    const hidenSectionSteps = ["Ottoman", "Cuddle Ottoman"];
    const checkIsHide =
      isSectionalConfigurator() ||
      (!isSectionalConfigurator() && !hidenSectionSteps.includes(item_type));
    if (!!label && label.includes("Step")) {
      const stepPart = label.split(".")[0] + ".";
      const labelPart = label.split(".")[1];

      return (
        <div className={s.optionsWrapLabel}>
          <span className={s.optionsWrapLabel_accent}>
            {checkIsHide ? stepPart : ""}
          </span>

          {labelPart}
        </div>
      );
    } else {
      return <div className={s.optionsWrapLabel}>{label}</div>;
    }
  };

  return (
    <>
      {!!label && <StyledLabel />}
      {!!note && <div className={s.optionsWrapNote}>{note}</div>}
      {id === "AdditionalItems" && <AdditionalHint />}
      {id === "pillows" && <PillowHint />}
      {id === "style" && isSectionalConfigurator() && <SilhouetteHint />}
    </>
  );
};
