import { createNameModel } from "./../../utils/configSofa/utilsFuncModel";
import {
  checkSideBindNextModel,
  getIndexModel,
} from "../../utils/configSofa/utilsFuncModel";
import { RootState } from "../store";
import { getAllModels, getModels, getPrevModel } from "./model.selector";
import {
  getNameActivePoint,
  getPositionModificationPoint,
} from "./points.selector";

export const getAvailableItem = (state: RootState) => {
  const drawModel = getPrevModel(state);

  const allModels = getAllModels(state);
  const modelsForUI = getModels(state);

  const nameActivePoint = getNameActivePoint(state);
  let side = checkSideBindNextModel(nameActivePoint);

  let positionModificationPoint = getPositionModificationPoint(state);
  let indexPoint = getIndexModel(nameActivePoint, positionModificationPoint);
  const keyNewModel = createNameModel(positionModificationPoint, indexPoint);

  let models = allModels.filter((model: any) => {
    const typeModel = model.metadata.Type;
    const sidesBind = JSON.parse(model.metadata.Positions);
    const prevModelType = returnPrevModelType();
    const isCorner = typeModel.toLowerCase().includes("corner")
    // if (
    //   typeModel.toLowerCase().includes("corner") &&
    //   typeModel === prevModelType
    // ) {
    //   model.showItem = false;
    //   // return true;
    // } else {
    //   model.showItem = true;
    // }

   

    if (!!modelsForUI[keyNewModel]) {
      if (sidesBind.includes(side) && sidesBind.includes("Bottom")) {
        model.showItem = true;
        // return true
      } else {
        model.showItem = false;
      }
      // debugger;
      if (positionModificationPoint === "positive") {
        if (sidesBind.includes(side) && sidesBind.includes("Right")) {
          model.showItem = true;
          // return true;
        } else {
          model.showItem = false;
        }
      }
      if (positionModificationPoint === "negative") {
        if (sidesBind.includes(side) && sidesBind.includes("Left")) {
          model.showItem = true;
          // return true
        } else {
          model.showItem = false;
        }
      }
    } else {
      if (sidesBind.includes(side)) {
        model.showItem = true;
        // return true
      } else {
        model.showItem = false;
      }
    }
    if (isCorner) {
      model.showItem = true;
      // return true
    } 
    if (!drawModel) {
      model.showItem = false;
    } 
    return true;
  });

  function returnPrevModelType() {
    let prevModelName: any = "";
    if (indexPoint === 1) {
      prevModelName = "pos_first_0#";
    } else {
      prevModelName = `pos_${positionModificationPoint}_${indexPoint - 1}#`;
    }

    if (modelsForUI[prevModelName]) {
      //@ts-ignore
      return modelsForUI[prevModelName].type;
    }
    return false;
  }

  return models;
};
