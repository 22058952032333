import {
  boundingBoxT,
  getParametersModelPositionT,
  inputParametersModelPosition,
  objectModelT,
  sideBind,
} from "./../../redux/types/typeModels";
import { getMetadata } from "../threekit/threekitFunc";
import {
  getAngleRelativeIndexPosition,
  getIndexPositionRelativeAngle,
  getNextIndexAngle,
  getPrevIndexAngle,
  getTypeObject,
} from "./turningUtils";
import {
  getPositionModificationPointT,
  positionObjectT,
  positionOptions,
  modelsT,
  startModelsT,
  filteredPayloadStartModelsT,
  getPositionModificationModelsT,
} from "../../redux/types/typeThreekitStateModal";

export const createNameModel = (
  modificationPoint: getPositionModificationPointT,
  positionPoint: number
): positionObjectT<positionOptions> => {
  return `pos_${modificationPoint}_${positionPoint}#`;
};

export const checkSideBindNextModel = (activeNewPoint: string) => {
  return activeNewPoint.includes("positive") ? "Left" : "Right";
};

export const getParametersModelPosition = ({
  sideBind,
  angle,
  id,
  сoordinateSystem,
}: inputParametersModelPosition): getParametersModelPositionT => {
  let boundingBox: boundingBoxT = window.player.scene
    .get({ id: id, evalNode: true })
    .getBoundingBox();

  const xCoordinate = сoordinateSystem.x;
  const zCoordinate = сoordinateSystem.z;

  const xWidth = boundingBox.max.x - boundingBox.min.x;
  const zWidth = boundingBox.max.z - boundingBox.min.z;

  let OMinX = Math.abs(xCoordinate - boundingBox.min.x);
  let OMaxX = Math.abs(boundingBox.max.x - xCoordinate);
  let OMinZ = Math.abs(boundingBox.min.z - zCoordinate);
  let OMaxZ = Math.abs(zCoordinate - boundingBox.max.z);

  // get width models relative bouunding box
  // пошукати - medium

  // if (angle && angle === -90) {
  //   OMinZ = Math.abs(xCoordinate - boundingBox.min.x);
  //   OMaxZ = Math.abs(boundingBox.max.x - xCoordinate);
  //   OMinX = Math.abs(boundingBox.min.z - zCoordinate);
  //   OMaxX = Math.abs(zCoordinate - boundingBox.max.z);
  // } else if (angle && angle === -270) {
  //   OMinZ = Math.abs(xCoordinate - boundingBox.min.x);
  //   OMaxZ = Math.abs(boundingBox.max.x - xCoordinate);
  //   OMinX = Math.abs(boundingBox.min.z - zCoordinate);
  //   OMaxX = Math.abs(zCoordinate - boundingBox.max.z);
  // }

  return {
    boundingBox: boundingBox,
    coordinatesObject: {
      x: xCoordinate,
      z: zCoordinate,
    },
    segmentsModel: {
      OMinX,
      OMaxX,
      OMinZ,
      OMaxZ,
    },
  };
};

export const getSideBindPosition = (assetId: string) => {
  const metadata = getMetadata(assetId);

  const metadataPosition = metadata.find((e: any) => e.name === "Positions");
  const sideBindModel: sideBind[] = JSON.parse(
    metadataPosition["defaultValue"]
  );
  return sideBindModel;
};
export const getTypeThreekitModel = (assetId: string): any => {
  const metadata = getMetadata(assetId);

  const typeThreekitModel = metadata.find((e: any) => e.name === "Type");
  return typeThreekitModel["defaultValue"];
};

export const getSideBindObject = (
  newObject: objectModelT,
  positionModificationPoint: getPositionModificationPointT
): sideBind => {
  if (positionModificationPoint === "positive") {
    if (newObject["sideBind"].includes("Right")) {
      return "Right";
    }
    if (newObject["sideBind"].includes("Bottom")) {
      return "Bottom";
    }
  }

  if (positionModificationPoint === "negative") {
    if (newObject["sideBind"].includes("Bottom")) {
      return "Bottom";
    }
    if (newObject["sideBind"].includes("Left")) {
      return "Left";
    }
  }
  return newObject["sideBind"][0];
};

export const updateAngleModel = (
  allModelsForCorditeConversion: modelsT,
  positionModificationPoint: getPositionModificationPointT
) => {
  Object.keys(allModelsForCorditeConversion).forEach((nameKey: any) => {
    let currentModel = allModelsForCorditeConversion[nameKey];
    let currentModelPosition = currentModel["position"];
    const keyPrevPosition = getKeyPrevModel({
      modification: positionModificationPoint,
      currentIndexModal: currentModelPosition,
    });
    const prevModel = allModelsForCorditeConversion[keyPrevPosition];

    if (prevModel) {
      let newAngle = getAngleCurrentModelFunc({
        prevModel: prevModel,
        positionModificationPoint: positionModificationPoint,
        typeCurrentModel: getTypeObject(currentModel["sideBind"]),
      });
      currentModel["angle"] = newAngle;
      currentModel["transform"]["rotation"] = {
        ...currentModel["transform"]["rotation"],
        y: newAngle,
      };
      allModelsForCorditeConversion = {
        ...allModelsForCorditeConversion,
        [nameKey]: currentModel,
      };
    }
  });
  return allModelsForCorditeConversion;
};

type getKeyPrevModelT = {
  modification: getPositionModificationPointT;
  currentIndexModal: number;
};
export const getKeyPrevModel = ({
  modification,
  currentIndexModal,
}: getKeyPrevModelT) => {
  if (currentIndexModal === 0) {
    return `pos_first_-1#`;
  }
  return currentIndexModal === 1
    ? `pos_first_0#`
    : createNameModel(modification, currentIndexModal - 1);
};
export const getAngleCurrentModelFunc = ({
  prevModel,
  positionModificationPoint,
  typeCurrentModel,
}: any) => {
  const anglePrevObject = prevModel.angle;
  const oldTypeObject = getTypeObject(prevModel["sideBind"]);
  if (typeCurrentModel === "corner") {
    const prevIndex = getIndexPositionRelativeAngle(anglePrevObject);
    if (positionModificationPoint === "negative") {
      let newIndexNumber = getPrevIndexAngle(prevIndex);
      const angleCurrentModel = getAngleRelativeIndexPosition(newIndexNumber);
      return angleCurrentModel;
    }
  }

  if (oldTypeObject === "corner") {
    const prevIndex = getIndexPositionRelativeAngle(anglePrevObject);
    let newIndexNumber = getNextIndexAngle(prevIndex);
    if (positionModificationPoint === "negative") {
      return anglePrevObject;
    }
    if (newIndexNumber === 8 || newIndexNumber === 0) newIndexNumber = 4;
    const angleCurrentModel = getAngleRelativeIndexPosition(newIndexNumber);
    return angleCurrentModel;
  }

  return prevModel.angle;
};
export const getIndexModel = (key: any, modificationPoint: any) => {
  if (!key) return 1;
  return Number(key.split(`${modificationPoint}_`)[1].split("#")[0]);
};

export const getObjFilteredPayloadStartModels = (
  objStartModels: startModelsT
): filteredPayloadStartModelsT => {
  const arrKeysStartModels = Object.keys(objStartModels).sort();
  const objResult = arrKeysStartModels.reduce(
    (accumulator: filteredPayloadStartModelsT, keyModel: any) => {
      const arrKeyModelSplit = keyModel.split("_");
      const indexModel = parseInt(
        arrKeyModelSplit[arrKeyModelSplit.length - 1].replace(/[^\d]/g, "")
      );
      const positionModel =
        arrKeyModelSplit[1] as getPositionModificationModelsT;
      switch (positionModel) {
        case "first":
          return {
            ...accumulator,
            first: {
              ...accumulator["first"],
              [keyModel]: objStartModels[keyModel],
            },
          };
        case "negative":
          return {
            ...accumulator,
            negative: {
              ...accumulator["negative"],
              [keyModel]: objStartModels[keyModel],
            },
          };
        case "positive":
          return {
            ...accumulator,
            positive: {
              ...accumulator["positive"],
              [keyModel]: objStartModels[keyModel],
            },
          };
        default:
          return { ...accumulator };
      }
    },
    {
      first: {},
      negative: {},
      positive: {},
    }
  );

  return objResult;
};

export const checkIsSelectedModel = (
  curentModels: modelsT,
  objStartModels: startModelsT
) => {
  let isSelectedModel = true;

  if (Object.keys(curentModels).length === 0) return !isSelectedModel;

  Object.keys(objStartModels).forEach((keyModel: any) => {
    if (
      !!curentModels[keyModel] &&
      objStartModels[keyModel] !== curentModels[keyModel]["assetId"]
    )
      isSelectedModel = false;
  });

  return isSelectedModel;
};
