import { useEffect, useRef } from "react";
import { getModalInfo } from "../../../redux/selectors/ui.selector";
import s from "./MailSent.module.scss";

import { Close } from "../../../assets/svgCompopnent/Close";
import MailDone from "../../../assets/svgCompopnent/MailDone";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
export default function MailSent() {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) => getModalInfo(state)("MailSent"));
  const wrapperRef: any = useRef(null);

  function closeFunc() {
    dispatch(openCloseModals({ name: "MailSent" }));
  }

  useEffect(() => {
    if (closeFunc) {
      const handleClickOutside = (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
      document.addEventListener("mousedown", handleClickOutside(wrapperRef, closeFunc));

      return () => {
        document.removeEventListener("mousedown", handleClickOutside(wrapperRef, closeFunc));
      };
    }
  }, [wrapperRef]);

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.wrapper__block} ref={wrapperRef}>
            <div className={s.close} onClick={() => dispatch(openCloseModals({ name: "MailSent" }))}>
              <Close />
            </div>
            <div className={s.content}>
              <div className={s.svg}>
                <MailDone />
              </div>
              <div className={s.title}>Your Jonathan Louis Design Lab Configuration Has Been Sent to Your Email</div>
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
