import useWindowSize from "./useWindowSize";

export default function useMobileWidth() {

    const windowSize = useWindowSize();
    
    const isMobileSize = (!!windowSize.width && windowSize.width <= 1024) ? true : false;

    return isMobileSize;

}
