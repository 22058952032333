import { getModels } from "../../redux/selectors/model.selector";
import { store } from "../../redux/store";
import { positionUpdate } from "./positionUpdate";

export const handleHoldStartEvent = (isHolding: boolean) => {
  const storeCopy = JSON.parse(JSON.stringify(store.getState()));
  const allModels = getModels(storeCopy);

  const activePillow: any = storeCopy.ui.activePillow;
  const ids = window.player.selectionSet.ids[0];

  if (isHolding && !!activePillow && ids) {
    // @ts-ignore
    window.customInterval = setInterval(async () => {
      positionUpdate(allModels)
    }, 0);
  }
    // @ts-ignore
  if (!isHolding && window.customInterval) {
    // @ts-ignore
    clearInterval(window.customInterval);
  }
};
