import React, { useEffect, useState } from "react";
//@ts-ignore
import s from "./SilhouetteHint.module.scss";

export const SilhouetteHint = ({ ...props }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={s.hint} onClick={toggle}>
      <img
        src="/images/assets/svg/Bell.svg"
        alt=""
      />
      <span className={s.text}>
        To select arm silhouette, configuration must include section(s) with
        arms
        {/* <span className={s.red}> you need to add a sections </span>
        <span className={`${s.short} ${isOpen && s.active}`}>or ...</span>
        <span className={`${s.full} ${isOpen && s.active}`}>
          or sections that have it for this possibility
        </span> */}
      </span>
    </div>
  );
};
