const planePillowConst = [
  "pillow_move_plane",
  "pillow_move_plane_1",
  "pillow_move_plane_2",
];

const setPlanesToLayoutContainer = (planes: string[], id: any) => {
  window.player.scene.set(
    {
      //@ts-ignore
      from: id,
      name: "Layout Container",
      plug: "LayoutContainer",
      property: "containerMeshes",
    },
    planes
  );
};

export const setAllPlane = async () => {
  const planes: any[] = [];
  let config = window.player.instanceId;
  //@ts-ignore
  const parrentPlayer = window.parrentPlayer.id;
  if (parrentPlayer) config = parrentPlayer;
  try {
    const modelsObj = window.player.scene.getAll({ from: parrentPlayer });
    const models = Object.keys(modelsObj).filter((key) =>
      key.includes("Model")
    );

    if (models.length > 0) {
      models.forEach(async (modelId) => {
        const instanceId = await window.playerT.getAssetInstance({
          from: window.player.scene.findNode({
            from: parrentPlayer,
            name: "Models",
          }),
          id: modelsObj[modelId].id,
          plug: "Null",
          property: "asset",
        });

        const interval = setInterval(async () => {
          const ids = window.player.scene.get([instanceId, "eval", "Proxy"]);
          if (!!ids) {
            clearInterval(interval);
            const proxy = window.player.scene.get([instanceId, "eval", "Proxy"])
              .proxy.id;
            planePillowConst.forEach((planeName) => {
              let plane = window.player.scene.get({
                from: proxy,
                name: planeName,
                evalNode: true,
              });

              if (plane) {
                planes.push(plane.id);
              }
            });

            if (planes.length > 0) {
              setPlanesToLayoutContainer(planes, config);
            }
          }
        });
      });

      if (planes.length > 0) {
        setPlanesToLayoutContainer(planes, config);
      }
    }
  } catch (err) {
    console.log("error: ", err);
  }
};
