export const pillowBasicMoveHint = (pillowsThreekit: any) => {
  let checkErrors = false;
  pillowsThreekit.map((pillow: any) => {
    const params = window.playerT.api.scene.get({
      id: pillow.id,
      evalNode: true,
    });
    const translation = params.node.plugs.Transform[0].translation;
    if (translation.x === 0 && translation.z === 0) {
      checkErrors = true;
    }
  });

  return checkErrors;
};
