import { defaultCoordinates, defaultRotation } from "./../constNewObject";
import {
  getAngleCurrentModel,
  getPrevModel,
} from "../../redux/selectors/model.selector";
import {
  bindDirectionType,
  positionAngles,
  getTypeObjectT,
  typeModel,
  boundingBoxT,
  paramsObject,
  sideBind,
  getParametersModelPositionT,
} from "../../redux/types/typeModels";
import { getNewCoordinate } from "./CoorditaneModels";
import { getPositionModificationPoint } from "../../redux/selectors/points.selector";
import { getPositionModificationPointT } from "../../redux/types/typeThreekitStateModal";
import { store } from "../../redux/store";

export const getTypeObject = (listSideBind: getTypeObjectT): typeModel => {
  if (listSideBind.includes("Bottom")) return "corner";
  return "line";
};

export const getIndexPositionRelativeAngle = (angle: number): number => {
  // get index position relative angle;
  const indexAnglesPrevObject = positionAngles[angle];
  const index = indexAnglesPrevObject.split("pos_")[1];
  return Number(index);
};

export const getAngleRelativeIndexPosition = (index: number): number => {
  // get angle relative index position
  const newIndex: any = `pos_${index}`;
  const angle = positionAngles[newIndex];
  return Number(angle);
};

export const getNextIndexAngle = (index: number): number => {
  // get Next index Angle
  const newIndexNumber = Number(index) + 1;
  return newIndexNumber;
};
export const getPrevIndexAngle = (index: number): number => {
  // get prev Index Angle
  const newIndexNumber = Number(index) - 1;
  return newIndexNumber;
};

export const getBindDirection = (indexButton: number): bindDirectionType => {
  // get Bind Direction relation index Button 'add Model'
  if (indexButton < 0) return "left";
  return "right";
};

export const getModification = (keyModel: string) => {
  if (keyModel && keyModel.includes("positive")) return "positive";
  if (keyModel && keyModel.includes("first")) return "first";
  return "negative";
};

export const getSizeObject = (boundingBox: boundingBoxT): paramsObject => {
  // Get the size of the object
  const height = Math.abs(boundingBox.min.y) + Math.abs(boundingBox.max.y);

  let length = Math.abs(boundingBox.min.x) + Math.abs(boundingBox.max.x);
  let width = Math.abs(boundingBox.min.z) + Math.abs(boundingBox.max.z);

  if (boundingBox.min.x > 0) {
    length = Math.abs(boundingBox.max.x) - Math.abs(boundingBox.min.x);
  }
  if (boundingBox.min.x < 0 && boundingBox.max.x < 0) {
    length = Math.abs(boundingBox.min.x) - Math.abs(boundingBox.max.x);
  }

  if (boundingBox.min.z > 0) {
    width = Math.abs(boundingBox.max.z) + Math.abs(boundingBox.min.z);
  }

  const paramsObject: paramsObject = {
    length: length,
    height: height,
    width: width,
  };
  return paramsObject;
};

type getPositionCurrentObjectT = {
  parametersCurrentModelPosition: getParametersModelPositionT;
  sideBind: sideBind[];
};
export const getPositionCurrentObject =
  ({ parametersCurrentModelPosition, sideBind }: getPositionCurrentObjectT) =>
  (state: any): any => {
    const prevModel = getPrevModel(state);
    const positionModificationPoint = getPositionModificationPoint(state);

    const angleCurrentModel = getAngleCurrentModel(getTypeObject(sideBind))(
      state
    );

    if (!prevModel || angleCurrentModel === null)
      return {
        translation: defaultCoordinates,
        rotation: defaultRotation,
      };

    const typePrevModel = getTypeObject(prevModel["sideBind"]);
    const typeCurrentModel = getTypeObject(sideBind);

    const { newXPosition, newZPosition } = getNewCoordinate({
      angle: angleCurrentModel,
      typeDirection: positionModificationPoint,
      typePrevModel: typePrevModel,
      typeCurrentModel: typeCurrentModel,
      prevModel: prevModel,
      parametersNextModelPosition: parametersCurrentModelPosition,
    });

    return {
      translation: {
        x: newXPosition,
        y: 0,
        z: newZPosition,
      },
      rotation: { x: 0, y: angleCurrentModel, z: 0 },
    };
  };

export const getPositionCurrentObjectParams = (
  prevModel: any,
  positionModificationPoint: getPositionModificationPointT,
  angleCurrentModel: number,
  sideBind: getTypeObjectT,
  parametersCurrentModelPosition: getParametersModelPositionT
) => {
  if (!prevModel || angleCurrentModel === null)
    return {
      translation: defaultCoordinates,
      rotation: defaultRotation,
    };

  const typePrevModel = getTypeObject(prevModel["sideBind"]);
  const typeCurrentModel = getTypeObject(sideBind);
  const { newXPosition, newZPosition } = getNewCoordinate({
    angle: angleCurrentModel,
    typeDirection: positionModificationPoint,
    typePrevModel: typePrevModel,
    typeCurrentModel: typeCurrentModel,
    prevModel: prevModel,
    parametersNextModelPosition: parametersCurrentModelPosition,
  });

  return {
    translation: {
      x: newXPosition,
      y: 0,
      z: newZPosition,
    },
    rotation: { x: 0, y: angleCurrentModel, z: 0 },
  };
};
