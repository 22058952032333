import {
  getPositionModificationPointT,
  newPointsT,
} from "../../redux/types/typeThreekitStateModal";
import {
  boundingBoxT,
  objectModelT,
  sideBind,
  transformType,
} from "./../../redux/types/typeModels";

export type objCoordinateT = {
  positionCurrentObject: transformType;
  boundingBoxCurrentObject: boundingBoxT;
};
type createPointT = {
  object: objectModelT;
  indexButton: number;
  positionModificationPoint: getPositionModificationPointT;
  sideBind: sideBind;
};

type createPositionPointT = {
  object: objectModelT;
  positionModificationPoint: getPositionModificationPointT;
  sideBind: sideBind;
};
export const createPositionPoint = ({
  object,
  positionModificationPoint,
  sideBind,
}: createPositionPointT) => {
  const distancesBackForPoint = 0.48;

  let posintionX = 0;
  let posintionZ = 0;

  const angle = object["angle"];
  if (positionModificationPoint === "positive") {
    let xStartPoint = object["transform"]["translation"].x;
    let zStartPoint = object["transform"]["translation"].z;

    if (sideBind.includes("Bottom")) {
      switch (angle) {
        case 0:
          posintionX =
            xStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"] -
            distancesBackForPoint;
          posintionZ =
            zStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMaxZ"];
          break;
        case -180:
          posintionX =
            xStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"] +
            distancesBackForPoint;
          posintionZ =
            zStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxZ"];
          break;
        case -90:
          posintionX =
            xStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxZ"];
          posintionZ =
            zStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"] -
            distancesBackForPoint;
          break;
        case -270:
          posintionX =
            xStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"];
          posintionZ =
            zStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxZ"] +
            distancesBackForPoint;
          break;
      }
    }

    if (sideBind.includes("Right")) {
      // debugger;
      switch (angle) {
        case 0:
          posintionX =
            xStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"];
            
          posintionZ =
            zStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMinZ"] +
            distancesBackForPoint;

          break;
        case -180:
          posintionX =
            xStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"];
          posintionZ =
            zStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMinZ"] -
            distancesBackForPoint;
          break;
        case -90:
          posintionX =
            xStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMinZ"] -
            distancesBackForPoint;
          posintionZ =
            zStartPoint +
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"];
          break;
        case -270:
          //   posintionX =
          //   xStartPoint -
          //   object["parametersModelPosition"]["segmentsModel"]["OMaxX"] +
          //   distancesBackForPoint;
          // posintionZ =
          //   zStartPoint -
          //   object["parametersModelPosition"]["segmentsModel"]["OMaxZ"];
          posintionX =
            xStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxZ"] +
            distancesBackForPoint;
          posintionZ =
            zStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMinX"];
          break;
      }
    }
  }
  if (positionModificationPoint === "negative") {
    let xStartPoint = object["transform"]["translation"].x;
    let zStartPoint = object["transform"]["translation"].z;

    let lengthModel: number =
      object["parametersModelPosition"]["segmentsModel"]["OMinX"];
    let widthModel: number =
      object["parametersModelPosition"]["segmentsModel"]["OMaxZ"];

    if (sideBind.includes("Bottom")) {
      // debugger;
      switch (angle) {
        case 0:
          posintionX = xStartPoint - lengthModel;
          posintionZ = zStartPoint + widthModel - distancesBackForPoint;
          break;
        case -180:
          posintionX = xStartPoint + lengthModel;
          posintionZ = zStartPoint + widthModel - distancesBackForPoint;
          break;
        case -90:
          posintionX = xStartPoint + lengthModel - distancesBackForPoint;
          posintionZ = zStartPoint - widthModel;
          break;
        case -270:
          posintionX = xStartPoint - lengthModel + distancesBackForPoint;
          posintionZ = zStartPoint + widthModel;
          break;
      }
    }

    if (sideBind.includes("Left")) {
      switch (angle) {
        case 0:
          posintionX =
            xStartPoint -
            object["parametersModelPosition"]["segmentsModel"]["OMaxX"];
          posintionZ = !object.type.toLowerCase().includes("armless")
            ? zStartPoint -
              object["parametersModelPosition"]["segmentsModel"]["OMinZ"] +
              distancesBackForPoint
            : zStartPoint;
          break;
        case -180:
          posintionX = xStartPoint + lengthModel;
          posintionZ = zStartPoint + widthModel - distancesBackForPoint;
          break;
        case -90:
          posintionX = xStartPoint + widthModel - distancesBackForPoint;
          posintionZ = zStartPoint - lengthModel;
          break;
        case -270:
          posintionX = xStartPoint - widthModel + distancesBackForPoint;
          posintionZ = zStartPoint + lengthModel;
          break;
      }
    }
  }

  return { posintionX, posintionZ };
};

export const createPoint = ({
  object,
  indexButton,
  positionModificationPoint,
  sideBind,
}: createPointT) => {
  if (positionModificationPoint === "positive" && sideBind === "Left")
    return {};
  if (positionModificationPoint === "negative" && sideBind === "Right")
  
    return {};

  const nextIndexButton = indexButton + 1;
  let namePoint: any = `point_${positionModificationPoint}_${nextIndexButton}#`;

  const { posintionX, posintionZ } = createPositionPoint({
    object,
    positionModificationPoint,
    sideBind,
  });

  let newPoint = {
    [namePoint]: {
      position: "Right",
      translation: { x: posintionX, z: posintionZ, y: 0.8 },
      pointIndex: nextIndexButton,
    },
  };

  return newPoint;
};

export const createPointsFirstObject = (object: objectModelT): newPointsT => {
  const sidesBind = object["sideBind"];
  let objectPoints: newPointsT = {};

  sidesBind.forEach((sideBind) => {
    if (sideBind === "Left") {
      const positionModificationPoint = `negative`;
      const objectNewPoint = createPoint({
        object,
        indexButton: 0,
        positionModificationPoint,
        sideBind,
      });
      objectPoints = {
        ...objectPoints,
        ...objectNewPoint,
      };
    }
    if (sideBind === "Right") {
      const positionModificationPoint = `positive`;

      const objectNewPoint = createPoint({
        object,
        indexButton: 0,
        positionModificationPoint,
        sideBind,
      });
      // debugger;
      objectPoints = {
        ...objectPoints,
        ...objectNewPoint,
      };
    }
    if (sideBind === "Bottom") {
      const positionModificationPoint = `positive`;
      const objectNewPoint = createPoint({
        object,
        indexButton: 0,
        positionModificationPoint,
        sideBind,
      });
      objectPoints = {
        ...objectPoints,
        ...objectNewPoint,
      };
    }
  });

  return objectPoints;
};
