import { filterFabricUI } from "../filterFabrics";

export const configurationChairs = {
  objTabs: {
    fabrics: ["accordionFurnitureFabrics"],
  },
  accordions: {
    accordionFurnitureFabrics: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics",
        label: "Choose from over 500 fabrics",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
  },
};
