import { Dispatch } from "redux";
import { objSetConfigurationT } from "../../redux/features/threekit/threekit.types";
import { changeActiveSide } from "../../redux/features/ui/uiSlice";
import { isSectionalConfigurator } from "../supportUtils";
import { coordinatesObject } from "./../../redux/types/typeModels";
import { cloneDeep } from "lodash";
export const getMetadata = (assetId: string) => {
  const {
    configurator: { metadata },
  } = window.player.scene.get({ id: assetId });

  return metadata;
};

export const getMetadataForList = (assetId: string) => {
  const models = window.configurator
    .getDisplayAttributes()
    .find((atr: any) => atr.name === "Models");

  if (models) {
    const model = models.values.find((m: any) => m.assetId === assetId);

    if (model) {
      return model.metadata;
    }
  }
};
export const getNameModel = (id: string) => {
  const { name } = window.player.scene.get({ id });
  return name;
};

export const getBoundingBox = (id: string) => {
  let model: any = window.player.scene.get({ id, evalNode: true });
  if (model) {
    return model.getBoundingBox();
  }

  return null;
};

export const setConfiguration = async (attr: string, data: any) => {
  let config = window.configurator;
  //@ts-ignore
  const parrentPlayer = window.parrentPlayer;
  if (
    (parrentPlayer && attr === "Meterial") ||
    (parrentPlayer && attr === "Models")
  )
    config = parrentPlayer.configurator;
  return await config.setConfiguration({ [attr]: data });
};
export const setObjConfiguration = async (objData: objSetConfigurationT) => {
  let config = window.configurator;
  //@ts-ignore
  const parrentPlayer = window.parrentPlayer;
  if (parrentPlayer && objData.setPillows) config = parrentPlayer.configurator;

  return await config.setConfiguration(objData);
};
export const setModelsForSectionalConfigurator = async (
  arrAssetsId: string[]
) => {
  const arrObjectsAssetId = arrAssetsId.reduce(
    (accumulator: any, assetId: string) => {
      return [...accumulator, { assetId }];
    },
    []
  );
  //@ts-ignore
  const parrentPlayer = window.parrentPlayer;
  let config = window.configurator;
  if (parrentPlayer) config = parrentPlayer.configurator;
  return await config.setConfiguration({
    Models: arrObjectsAssetId,
  });
};

export const getTranslaTion = (id: string): coordinatesObject => {
  return window.player.scene.get({
    id,
    plug: "Transform",
    property: "translation",
  });
};

export const getTranslationObject = (
  namePoint: string,
  instanceId: string
): coordinatesObject => {
  return window.player.scene.get({
    from: instanceId,
    name: namePoint,
    plug: "Transform",
    property: "translation",
  });
};

export const getRotationObject = (
  namePoint: string,
  instanceId: string
): coordinatesObject => {
  return window.player.scene.get({
    from: instanceId,
    name: namePoint,
    plug: "Transform",
    property: "rotation",
  });
};

export const getCamQuaternion = (camTrans: any, camTargetTrans: any) => {
  // Calculate camera Quaternion based on distance and direction from camera target, Note: We do this because we cannot directly request the quaternion from a non-active camera
  const camVector = {
    x: camTargetTrans.x - camTrans.x,
    y: camTargetTrans.y - camTrans.y,
    z: camTargetTrans.z - camTrans.z,
  };

  let euler = new window.player.THREE.Euler();
  euler.setFromVector3(camVector, "ZYX");
  let camQuat = new window.player.THREE.Quaternion();
  camQuat = camQuat.setFromEuler(euler);
  return camQuat;
};

export const getAssetIdSceneFromItem = () => {
  const objScene = window.player.scene.get();
  return objScene["plugs"]["Proxy"][0]["asset"]["assetId"];
};

export const setRotation = async (id: string, value: coordinatesObject) => {
  await window.player.scene.set(
    { id, plug: "Transform", property: "rotation" },
    value
  );
};

export const setRotationId = async (id: string, value: coordinatesObject) => {
  await window.player.scene.set(
    { id, plug: "Transform", property: "rotation" },
    value
  );
};

export const setTranslation = async (id: string, value: coordinatesObject) => {
  await window.player.scene.set(
    { id, plug: "Transform", property: "translation" },
    value
  );
};

export const getConfiguratorInstance = async (modelId: string) => {
  // let parentId = window.playerT.instanceId;
  // //@ts-ignore
  // const parrentPlayer = window.parrentPlayer;

  // if (parrentPlayer) {
  //   parentId = parrentPlayer.id;
  // }
  return window.playerT.getConfiguratorInstance({
    from: window.playerT.instanceId,
    id: modelId,
    plug: "Null",
    property: "asset",
  });
};

export const setTranslationId = async (
  id: string,
  value: coordinatesObject
) => {
  await window.player.scene.set(
    { id, plug: "Transform", property: "translation" },
    value
  );
};
export const setTranslationName = async (
  name: string,
  value: coordinatesObject
) => {
  await window.player.scene.set(
    { name: name, plug: "Transform", property: "translation" },
    value
  );
};
export const setModelVisibility = (id: string, value: boolean) => {
  window.player.scene.set(
    { id, plug: "Properties", property: "visible" },
    value
  );
};

export const checkCameraPosition = (
  positionInState: coordinatesObject,
  dispatch: Dispatch
) => {
  const currentPosition = window.player.camera.getPosition();

  if (currentPosition !== positionInState) {
    dispatch(changeActiveSide({ side: "Front" }));
  }
};

export const changeCameraPosition = () => {
  // Инициализируйте все переменные для обратного вызова анимации на верхнем уровне javascript
  let startCamera: any;
  let endCamera: any;
  let swapCamera: any;
  let startTrans: any;
  let startQuaternion: any;
  let startTargetTrans: any;
  let startTargetDist: any;
  let endTrans: any;
  let endQuaternion: any;
  let endCameraBaseNode: any;
  let endTargetDist: any;
  let endTargetTrans: any;
  let maxCameraTime: any = 2000;
  let start: any = undefined;

  // Initialize Configurator to get Cameras
  const config = window.player.configurator.getConfiguration();

  // Pull end camera value from string configuration attribute that holds names of all cameras
  const endCamName = config["Camera"];
  const assetIdScene = getAssetIdSceneFromItem();

  // Get both current and end cameras into storage
  endCamera = window.player.scene.get({ from: assetIdScene, name: endCamName });
  startCamera = window.player.scene.get({
    from: assetIdScene,
    id: window.player.configurator.player.cameraController.activeCamera,
  });

  // **ВАЖНО** Поместите ноль с тем же переводом, что и начальные переводы каждой из ваших камер.
  // Назовите нули <Имя камеры>_Base_Null. Убедитесь, что ваша камера и базовый ноль находятся на одном уровне в графе сцены.
  // Эти камеры и нули не должны *НЕ* быть родителями друг друга
  endCameraBaseNode = window.player.scene.get({
    from: assetIdScene,
    name: endCamName + "_Base_Null",
  });

  // animateCameraTo(endCameraBaseNode);

  // function animateCameraTo(endCameraBaseNode){
  //     // Initialize the camera to perform a swap between two locations
  //     createAndSetSwapCamera(startCamera);

  //     // Initialize information about start camera position/orientation
  //     startTrans = api.camera.getPosition();
  //     startQuaternion = api.camera.getQuaternion();
  //     startTargetTrans = getTargetTranslation(startCamera);
  //     startTargetDist = getDistanceBetween(startTrans, startTargetTrans);

  //     // Initialize information about end camera position/orientation
  //     endTrans = endCameraBaseNode.plugs.Transform[0].translation.valueOf();
  //     endTargetTrans = getTargetTranslation(endCamera);
  //     endQuaternion = getCamQuaternion(endTrans, endTargetTrans);
  //     endTargetDist = getDistanceBetween(endTrans, endTargetTrans);

  //     // Call animation callback function
  //     window.requestAnimationFrame(camStep);
  // }

  // function camStep(timestamp){
  //     // Get Start time for animation
  //     if(start === undefined) start = timestamp;

  //     // Calculate how far along camera movement should be based on desired time
  //     const elapsed = timestamp - start;
  //     const elapsedPercent = elapsed / maxCameraTime;

  //     // Call easing function for smoother, more natural, movement
  //     const animPercent = easeIn(elapsedPercent);

  //     // Init variables for calculation of next camera position
  //     let camTrans = new api.THREE.Vector3();
  //     let targetTrans = new api.THREE.Vector3();
  //     let camQuat = new api.THREE.Quaternion();
  //     let directionVector = new api.THREE.Vector3(0,0,1);

  //     // Linear interpolation between how far the camera is from its original target to distance from final target
  //     targetDist = startTargetDist + ((endTargetDist - startTargetDist) * animPercent);

  //     // Linear interpolation of the camera target from start camera's target to end camera's target
  //     targetTrans.lerpVectors(startTargetTrans, endTargetTrans, animPercent);

  //     // Linear interpolation of camera quaternion between start quaternion and end quaternion **NOTE WE DO NOT USE ROTATION**
  //     api.THREE.Quaternion.slerp(startQuaternion, endQuaternion, camQuat, animPercent);

  //     // Calculate camera position based on distance from target point between start target and end target
  //     directionVector.applyQuaternion(camQuat);
  //     camTrans.copy(targetTrans).addScaledVector(directionVector, targetDist);

  //     if(elapsed < maxCameraTime){
  //         // Set camera position & quaternion
  //         api.camera.setPosition(camTrans);
  //         api.camera.setQuaternion(camQuat);
  //         window.requestAnimationFrame(camStep);
  //     } else {
  //         // Set active camera to final cam, delete temporary swap camera, and reset start variable for future animations
  //         api.setActiveCamera(api.scene.get({from: api.instanceId, name: endCamera.name}).id);
  //         api.scene.deleteNode(api.scene.get({from: api.instanceId, name: 'Swap_Cam'}));
  //         start = undefined;
  //         return;
  //     }
  // }

  // function createAndSetSwapCamera(startCam){
  //     let camCopy = JSON.parse(JSON.stringify(api.scene.get({from: api.instanceId, id: startCam.id})));
  //     delete camCopy.id;
  //     camCopy.name = 'Swap_Cam';
  //     // Disable camera constraints because this could prevent camera movements while using cloned camera
  //     camCopy.plugs.Camera[0].constraintLatitudeMode = 0;
  //     camCopy.plugs.Camera[0].constraintLongitudeMode = 0;
  //     newCamId = api.scene.addNode(camCopy, api.instanceId);
  //     api.setActiveCamera(newCamId);
  // }

  // function getTargetTranslation(cam){
  //     const targetId = api.scene.get({from: api.instanceId, name: cam.name, plug: "Camera", property: "targetNode"});
  //     return targetId ? api.scene.get({from: api.instanceId, id: targetId, plug: "Transform", property: "translation"}) : {x: 0, y: 0, z: 0};
  // }

  // function getDistanceBetween(point1, point2){
  //     return Math.sqrt(Math.pow(point2.x - point1.x,2) + Math.pow(point2.y - point1.y,2) + Math.pow(point2.z - point1.z,2));
  // }

  // function getCamQuaternion(camTrans, camTargetTrans){
  //     // Calculate camera Quaternion based on distance and direction from camera target, Note: We do this because we cannot directly request the quaternion from a non-active camera
  //     const camVector = {x: camTargetTrans.x - camTrans.x, y: camTargetTrans.y - camTrans.y, z: camTargetTrans.z - camTrans.z};

  //     let euler = new api.THREE.Euler();
  //     euler.setFromVector3(camVector, 'ZYX');
  //     let camQuat = new api.THREE.Quaternion();
  //     camQuat = camQuat.setFromEuler(euler);
  //     return camQuat;
  // }

  // Cubic easing function
  // function easeIn(x){
  //     return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  // }
};

type addActualPointT = {
  value: any;
  item: any;
  modelsInState: any;
  newPoint: any;
  addNewModel: any;
};

export const addActualPoint = ({
  value,
  item,
  modelsInState,
  newPoint,
  addNewModel,
}: addActualPointT) => {
  const getActualPoints = ({ direction, newPoint, actualModels }: any) => {
    let maxPositivePosition: number = 0;
    let actualPoints: any;

    Object.keys(actualModels).forEach((key) => {
      const keyParams = key.split("_");
      if (keyParams[1] === direction) {
        maxPositivePosition =
          actualModels[key].position > maxPositivePosition
            ? actualModels[key].position
            : maxPositivePosition;
      }
    });
    const maxPositiveModel =
      maxPositivePosition === 0
        ? `point_${direction}_1#`
        : `point_${direction}_${maxPositivePosition + 1}#`;
    if (newPoint[maxPositiveModel]) {
      actualPoints = { [maxPositiveModel]: newPoint[maxPositiveModel] };
    }
    return actualPoints;
  };
  addNewModel(value);
  const position = JSON.parse(item.metadata.Positions);
  const type = item.metadata.Type;
  const actualModels: any = {};

  Object.keys(modelsInState).forEach((key) => {
    const sideBind: any = modelsInState[key].sideBind;
    const copyModel = modelsInState[key];
    const actualPosition: any = [];
    position.find((item: any) => {
      if (sideBind.includes(item)) {
        actualPosition.push(item);
      }
    });
    if (actualPosition.length >= 1) {
      copyModel.actualPosition = actualPosition;
      actualModels[key] = modelsInState[key];
    }
  });
  let actualPoints: any;
  if (
    (position.includes("Left") && position.includes("Right")) ||
    type === "Corner"
  ) {
    actualPoints = newPoint;
  } else if (position.includes("Left") && position.length === 1) {
    actualPoints = getActualPoints({
      direction: "positive",
      newPoint,
      actualModels,
    });
  } else if (position.includes("Right") && position.length === 1) {
    actualPoints = getActualPoints({
      direction: "negative",
      newPoint,
      actualModels,
    });
  }
  // Object.keys(newPoint).forEach((key: any) => {
  //   setModelVisibility(key, false);
  // });
  Object.keys(actualPoints).forEach((key) => {
    setModelVisibility(key, true);
  });
};

export const getIndexSelectedPillow = () => {
  const idSelectedObj = window.player.selectionSet.ids;
  if (idSelectedObj.length === 0) return null;
  const node = window.playerT.api.scene.get({
    id: idSelectedObj[0],
    evalNode: true,
  });
  const nodeNameSplitArr = node["name"].split(" ");

  if (isSectionalConfigurator()) {
    return nodeNameSplitArr.findIndex(
      (e: any) => e === nodeNameSplitArr[nodeNameSplitArr.length - 1]
    );
  }
  return Number(nodeNameSplitArr[nodeNameSplitArr.length - 1]);
};

export const sortValueRelativeMetadaThreekit = (valuesThreekit: any[]) => {
  let copyValues = cloneDeep(valuesThreekit);
  const checkSortMetadata =
    copyValues[0].hasOwnProperty("metadata") &&
    copyValues[0]["metadata"].hasOwnProperty("sortUI");
  if (!checkSortMetadata) return valuesThreekit;

  copyValues = copyValues.sort((valueA: any, valueB: any) => {
    const numSortValueA = valueA["metadata"]["sortUI"];
    const numSortValueB = valueB["metadata"]["sortUI"];
    return numSortValueA < numSortValueB
      ? -1
      : numSortValueA > numSortValueB
      ? 1
      : 0;
  });

  return copyValues;
};
