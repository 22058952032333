export const isSameWordsInStrings = (stringA: string, stringB: string) => {
  const reg = /[^\[\]\s]+|(\[\[[\s\S]*?\]\])/g;
  const newStringA = stringA.match(reg) || [];
  const newStringB = stringB.match(reg) || [];

  return newStringA.some(function (newStringA) {
    return newStringB.some(function (newStringB) {
      return newStringB == newStringA;
    });
  });
};

export const isSectionalConfigurator = () => {
  const location = window.location.pathname.split("/");
  const isSectional = location[1] === "sectional";
  return isSectional;
};

export const setCameraPLayerInCenter = () => {
  if (isSectionalConfigurator()) {
    window.player.camera.frameBoundingSphere();
  }
};

export const ucFirst = (str: string) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
};

export const getNameForArmNotFirstWord = (name: string) => {
  return name.split(" ").slice(1).join(" ");
};

export const toUpperWord = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(" ");
};

export const refactoringModelLabel = (label: string) => {
  const arrStringLAF = ["laf", "lsf"];
  const arrStringRAF = ["raf", "rsf"];
  const stringLAF = "Left Arm Facing";
  const stringRAF = "Right Arm Facing";
  const nameLoverCase = label.trim().toLocaleLowerCase();
  let resultLabel = "";

  const regExpLAF = new RegExp(arrStringLAF.join("|"));
  const regExpRAF = new RegExp(arrStringRAF.join("|"));

  if (regExpLAF.test(nameLoverCase)) {
    resultLabel = nameLoverCase.replace(regExpLAF, stringLAF);
  } else if (regExpRAF.test(nameLoverCase)) {
    resultLabel = nameLoverCase.replace(regExpRAF, stringRAF);
  } else {
    resultLabel = nameLoverCase;
  }

  return toUpperWord(resultLabel);
};

// 12x17 Kidney Pillow Low --> 12”x17” Kidney Pillow
// 15in Crescent Pillow Low --> 15” Crescent Pillow
export const refactoringPillowsLabel = (label: string) => {
  const numArr = label.match(/\d+/g);
  let strInches = label.trim().toLocaleLowerCase();
  numArr?.forEach((num: string) => {
    strInches = strInches.replace(num, `${num}"`);
  });

  const labelLoverCase = strInches.replace("in", "").replace(" low", "");
  const resultLabel = toUpperWord(labelLoverCase).replace('"x', '"×');

  return resultLabel;
};

export function getActualClassnameForArBtn() {
  let className = "";

  //@ts-ignore
  if (window.chrome && !window.opr) {
    // браузер Chrome
    className = "customARClass";
    //@ts-ignore
  } else if (window.chrome && window.opr) {
    // браузер Opera
    className = "customARClass";
  } else if (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1) {
    // браузер Safari
    className = "customARClass sizesAr";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    // браузер Firefox
    className = "customARClass";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    //@ts-ignore
    !!document.documentMode === true
  ) {
    // браузер Internet Explorer
    className = "customARClass";
  } else {
    // другой браузер
    className = "customARClass";
  }

  return className;
}

export function formatDate(date: Date) {
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

export function getActualLinkForSaveConfigurator() {
  // const actualUrl = store.getState().ui.actualUrlToSave;
  // if (!!actualUrl) {
  //   return actualUrl;
  // } else {
  //   return document.location.origin;
  // }
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    console.log("document.referrer: ", document.referrer);
    if (window.location !== window.parent.location) {
      return `${document.referrer}s/design-lab/`;
    }

    return `${document.location.origin}`;
  }
  // if (!!actualUrl) {
  //   return actualUrl;
  // } else {
  //   const url =
  //     window.location != window.parent.location
  //       ? `${document.referrer.slice(0, -1)}/s/design-lab`
  //       : `${document.location.origin}`;
  //   return url;
  // }
}
