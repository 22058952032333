import { cloneDeep, union, uniq } from "lodash";

type prevNotValidNAme = string;
type nextNotValidNAme = string;

type notValidConfig = [prevNotValidNAme, nextNotValidNAme];

export const listRollsNotValid: notValidConfig[] = [
  ["Corner Unit", "Corner Unit"],
  ["Cuddle Corner", "Cuddle Corner"],

  ["LAF Cuddle Chaise", "Corner Unit"],
  ["LAF Chaise", "Corner Unit"],
  ["Corner Unit", "RAF Cuddle Chaise"],
  ["Corner Unit", "RAF Chaise"],
  ["Corner Unit", "Cuddle Corner"],

  ["LAF Cuddle Chaise", "Cuddle Corner"],
  ["LAF Chaise", "Cuddle Corner"],
  ["Cuddle Corner", "RAF Cuddle Chaise"],
  ["Cuddle Corner", "RAF Chaise"],
  ["Cuddle Corner", "Corner Unit"],

  ["LAF Chaise", "RAF Chaise"],
  ["LAF Chaise", "RAF Cuddle Chaise"],
  ["LAF Cuddle Chaise", "RAF Cuddle Chaise"],
  ["LAF Cuddle Chaise", "RAF Chaise"],
];
export const getlistReverseRollsNotValid = (): notValidConfig[] => {
  const listRolls = JSON.parse(JSON.stringify(listRollsNotValid))
  listRollsNotValid.map(
    (listRool: notValidConfig) => listRool.reverse() as notValidConfig
  );

  return listRolls
}
  
export const getAllListRollsNotValid = (): notValidConfig[] => {
  const listReverseRollsNotValid = getlistReverseRollsNotValid();
  const listRols = listRollsNotValid.concat(listReverseRollsNotValid)
  return listRols;
};

export const allListReverseRollsNotValid = () => {
  let allListRollsNotValid = getAllListRollsNotValid();

  const allName = allListRollsNotValid.flat();
  const uniqueName = uniq(allName);
  // debugger
  return uniqueName;
};