import { threekitAttribute } from "../../type/threekitType";

const getValueThreekit = (itemThreekit: threekitAttribute) => itemThreekit['value']
const getValuesThreekit = (itemThreekit: threekitAttribute) => itemThreekit['values']

export const getMaxPillowsCountUI = (valueThreekit: any): number => valueThreekit['metadata']['MaxPillowsCountUI']

export const getMaxPillowCurentSofaRelativeThreekit = () => {
    const defaultMaxPillow = 3
    if (!window.configurator) return defaultMaxPillow
    if (!window.configurator.getDisplayAttributes()) return defaultMaxPillow
    const listAttribute: threekitAttribute[] = window.configurator.getDisplayAttributes();
    const sofaType = listAttribute.find(item => item['name'].includes("Sofa type"));


    if (!sofaType) return defaultMaxPillow;
    let value = getValueThreekit(sofaType)
    if (!value) return defaultMaxPillow;
    let values = getValuesThreekit(sofaType)
    if (values.length < 1) return defaultMaxPillow;
    let assetId = value['assetId'];

    let activePillow = values.find((pillow: any) => pillow['assetId'] === assetId)
    if (!activePillow) return defaultMaxPillow;
    let maxPillow = getMaxPillowsCountUI(activePillow);
    if (maxPillow === undefined || typeof maxPillow !== 'number') return defaultMaxPillow

    return maxPillow

}

