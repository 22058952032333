import React, { useRef, useState } from "react";
import { getModalInfo } from "../../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import s from "./GesturesTutorial.module.scss";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { Close } from "../../../assets/svgCompopnent/Close";

export type infoUserT = {
  email: string[];
  name: string;
};

export default function GesturesTutorial() {
  const dispatch = useAppDispatch();
  const { isOpen, data }: any = useAppSelector((state) =>
    getModalInfo(state)("GesturesTutorial")
  );

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.modalSaveConfig}>
            <div
              className={s.close}
              onClick={() =>
                dispatch(openCloseModals({ name: "GesturesTutorial" }))
              }
            >
              <Close />
            </div>
            <div className={s.title}>How to use gestures</div>
            <div className={s.item}>
              <div className={s.img}>
                <img
                  src="/images/assets/tutorial/gestures_1.png"
                  alt=""
                />
              </div>
              <div className={s.text}>
                <span className={s.red}>One finger</span>
                <br />
                to rotate
              </div>
            </div>
            <div className={s.item}>
              <div className={s.img}>
                <img
                  src="/images/assets/tutorial/gestures_2.png"
                  alt=""
                />
              </div>
              <div className={s.text}>
                <span className={s.red}>Two fingers</span>
                <br />
                to move the design
              </div>
            </div>
            <div className={s.item}>
              <div className={s.img}>
                <img
                  src="/images/assets/tutorial/gestures_3.png"
                  alt=""
                />
              </div>
              <div className={s.text}>
                <span className={s.red}>A pinching motion</span>
                <br />
                to zoom in or out
              </div>
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
