//Images
export const Collections = {
  sofa: {
    id: "sofa" as const,
    label: "Sofas",
    img: "/images/assets/classic-configurator_v2.png",
    aseetId: "0df0b5a4-beb5-477d-88d3-d92c2b22cb89",
    // aseetId: "0fdb3e71-cbb7-4c83-bee7-8fd4eae343a4", // test pillows
  },
  sectional: {
    id: "sectional" as const,
    label: "Sectionals",
    img: "/images/assets/sectional-configurator_v2.png",
    // aseetId: "934664c8-227f-4396-9ee5-00d4c751494a", // test
    aseetId: "6ca0c78b-e742-4206-a9e8-df46b187fd8c", // prod
    // aseetId: "9d92fdae-1b20-414d-a83f-998daf84d00e", // dimentions test
  },
  ottoman: {
    id: "ottoman" as const,
    label: "Ottomans",
    img: "/images/assets/ottomans-configurator-v3.png",
    aseetId: "9b63a7ab-229f-47ce-aa79-6adecc860fbf",
  },
  // pillows: {
  //     id: "pillows" as const,
  //     label: "Pillows",
  //     img: PillowsImg,
  //     aseetId: "pillows",
  // },
  chair: {
    id: "chair" as const,
    label: "Chairs",
    img: "/images/assets/chairs-configurator-v3.png",
    aseetId: "19d2cc21-cb53-4ba7-b6ea-8fd8bfd8c227",
  },
  bed: {
    id: "bed" as const,
    label: "Beds & Headboards",
    img: "/images/assets/bedroom-configurator-v4.png",
    aseetId: [
      "20c9d833-f43e-4076-a4b7-e7918faf325d", // bed
      "31345dd9-3ad3-4f40-b2b3-8d8fd77d5f75", // Headboard
    ],
  },
};

export const collectionsMainMenu = ["sofa", "sectional"];
export const collectionsSubMenu = ["ottoman", "pillows", "chair", "bed"];
