import { boundingBoxT, coordinatesObject, transformType } from '../redux/types/typeModels';
export const defaultCoordinates: coordinatesObject = {
    x: 0, y: 0, z: 0
}
export const defaultMin: coordinatesObject = defaultCoordinates;
export const defaultMax: coordinatesObject = defaultCoordinates;
export const defaultBoundingBox: boundingBoxT = {
    max: defaultMax,
    min: defaultMin,
};

export const defaultTranslation: coordinatesObject = defaultCoordinates;
export const defaultRotation: coordinatesObject = defaultCoordinates;
export const defaultTransform: transformType = {
    translation: defaultTranslation,
    rotation: defaultRotation
};


