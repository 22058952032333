import React from "react";
import s from "./ButtonWithLabel.module.scss";

type ButtonWithLabelT = {
  icon: JSX.Element,
  label: string,
  onClick?: () => void
}

const ButtonWithLabel = ({ icon, label, onClick }: ButtonWithLabelT) => {
  return (
    <div className={s.container} onClick={onClick}>
      <div className={s.icon}>{icon}</div>
      <div className={s.label}>{label}</div>
    </div>
  )
}

export default ButtonWithLabel;