import { filterFabricUI } from "../filterFabrics";

export const configurationOttomans = {
  objTabs: {
    fabrics: [
      "accordionFurnitureFabrics",
      "accordionFurnitureFabrics_2",
      "accordionFurnitureFabrics_3",
    ],
  },
  accordions: {
    accordionFurnitureFabrics: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics",
        label: "Choose from over 500 fabrics",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionFurnitureFabrics_2: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics_2",
        label: "Minsky Medium",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        firstShow: true,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric_3",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionFurnitureFabrics_3: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics_3",
        label: "Minsky Large",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric_2",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
  },
};
