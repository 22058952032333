import React from "react";
import { Minus } from "../../assets/svgCompopnent/Minus";
import { Plus } from "../../assets/svgCompopnent/Plus";
import s from "./Zoom.module.scss";

const Zoom = () => {
  return (
    <div className={s.container}>
      <span className={s.plus} onClick={() => window.player.camera.zoom(2)}>
        <Plus />
      </span>
      <span className={s.minus} onClick={() => window.player.camera.zoom(-2)}>
        <Minus />
      </span>
    </div>
  );
};

export default Zoom;
