import { instanceApiFunc } from "../../api/instanceApi";

export const saveFileTo3kit = async (file: any, fileName: string): Promise<string> => {
  const instanceApi = instanceApiFunc({
    authToken: process.env.REACT_APP_API_TOKEN || "",
    urlApi: process.env.REACT_APP_API_URL || "/api",
  });

  const formData = new FormData();
  formData.append("fileName", fileName);
  formData.append("file", file);

  try {
    const response = await instanceApi.post("/saveFile", formData, {
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    const data = response.data;
    return data.fileUrl;
  } catch (error) {
    //@ts-ignore
    const errorMessage = error.response?.data?.error || "Failed to upload file";
    throw new Error(errorMessage);
  }
};
