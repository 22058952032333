export type KeysQuerySku =
    | "category"
    | "style"
    | "item_type"
    | "depth"
    | "comfort"
    | "placeholder1"
    | "placeholder2"
    | "leg"
    | "bed_size"
    | "height"
    | "treatment"
    | "pillows"
    | "fabric"
    | "chair"
    | "ottoman"
    | "storage";

// Route "/api/priceInfo" request data types START
export type PricesAndSkuRequestDataT = {
    [key in KeysQuerySku]?: string | string[];
}
// Route "/api/priceInfo" request data types END

// Route "/api/priceInfo" response data types START
export type PriceAndSkuResponseObjT = {
    skuFull: string;
    skuShort: string;
    price: number;
}
export type PricesAndSkuResponseDataT = {
    [key: string]: PriceAndSkuResponseObjT
}
export type ResponseStatusT = 'Ok' | 'Error';
export type PricesAndSkuResponseApiT = {
    status: ResponseStatusT;
    data: PricesAndSkuResponseDataT,
    messages: errorsArrT;
    hidePrice: boolean
};
// Route "/api/priceInfo" response data types END

// Errors Types
export type errorMessageT = {
    field: KeysQuerySku;
    value: string;
    message: string;
}
export type errorsArrT = errorMessageT[];
// Errors Types END

export const NAME_COLLECTION_FABRIC_LIST = "fabric_list";
export const NAME_COLLECTION_PRICE_LIST = "sku_price_by_grade";
export const MODELS_COLLECTION = 'item_type'
export const arrKeysForSofaFullSku: KeysQuerySku[] = [
    "category",
    "style",
    "item_type",
    "depth",
    "comfort",
    "placeholder1",
    "placeholder2",
    "leg",
];
// export const arrKeysForSofaShortSku: KeysQuerySku[] = [
//     "category",
//     "style",
//     "item_type",
//     "depth",
//     "comfort",
// ];

export const arrKeysForBedFullSku: KeysQuerySku[] = [
    "category",
    "style",
    "bed_size",
    "item_type",
    "height",
    "treatment",
    "placeholder1",
    "placeholder2",
    "leg",
];
// export const arrKeysForBedShortSku: KeysQuerySku[] = [
//     "category",
//     "style",
//     "bed_size",
//     "item_type",
//     "height",
//     "treatment",
// ];

export const arrKeysForPillowsFullSku: KeysQuerySku[] = ["pillows"];
// export const arrKeysForPillowsShortSku: KeysQuerySku[] = ["pillows"];
export const arrKeysForChairFullSku: KeysQuerySku[] = ["chair"];
export const arrKeysForOttomanFullSku: KeysQuerySku[] = ["ottoman"];
