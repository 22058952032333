import React from 'react'
import s from './AddAnotherEmail.module.scss'
export const AddAnotherEmail = ({ handleAddEmail }: any) => {
    return (
        <div
            onClick={handleAddEmail}
            className={s.addAnotherEmail}
        >
            <img
                src="/images/assets/svg/PlusButton.svg"
                alt=""
            />
            <span>
                Add another email
                <br />
                (Add up to 5 email addresses)
            </span>
        </div>
    )
}
