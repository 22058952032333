import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LoaderCircle } from "../../assets/svgCompopnent/LoaderCircle";
import close from "../../assets/svgCompopnent/close.svg";
import { handleHoldStartEvent } from "../../function/pointToPointCheck/handleHoldStartEvent";
import { changeThreekitConfiguration } from "../../redux/actions/player.action";
import { collectionsKeysT } from "../../redux/features/ui/ui.types";
import { changeLoadInit3kitState, openCloseModals, setActiveTab } from "../../redux/features/ui/uiSlice";
import {
  getActiveCollectionObj,
  getLoadInit3kit,
  getLoadPlayer,
  getModelsGroupIdByCollectionId,
} from "../../redux/selectors/ui.selector";
import { THREEKIT_PARAMS } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { init3kit } from "../../utils/init3kit";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { checkIsLoadingPlayer } from "../../utils/threekit/checkIsLoadingPlayer";
import load3kit from "../../utils/threekit/loadThreekit";
import s from "./Player.module.scss";

export type CustomizedState = {
  collectionId: collectionsKeysT;
  nameThreekitArtibuteModel?: string;
  type?: string;
};

export const Player = () => {
  const playerEl: any = React.createRef();
  const dispatch = useAppDispatch();

  const { pathname, search } = useLocation();
  const locationState = useLocation().state as CustomizedState;
  const collectionId = !!locationState
    ? (locationState.collectionId as collectionsKeysT)
    : (pathname.split("/")[1] as collectionsKeysT);
  const typeBedConfigurator = !!locationState ? locationState.type : null;
  const isParentUrl = window.location != window.parent.location;
  // let search = isParentUrl
  //   ? window.parent.location.search
  //   : window.location.search;
  // let searchParams = new URLSearchParams(search);
  // let shortId = searchParams.get("shortId");
  const searchParams = new URLSearchParams(search);
  let localShortId = searchParams.get("shortId");
  let originShortId = searchParams.get("originUrl");
  let enableAR = searchParams.get("enableAR");
  let tkcsid = searchParams.get("tkcsid");
  let shortId = localShortId;
  if (!!tkcsid) shortId = tkcsid;
  // let shortId = originShortId ? originShortId : localShortId
  const isLocationModelName = !!locationState && !!locationState.nameThreekitArtibuteModel;

  const activeCollectionObj = useAppSelector((state) => getActiveCollectionObj(state)(collectionId));

  const isLoadPlayer = useAppSelector((state) => getLoadPlayer(state));
  const isLoadInit3kit = useAppSelector((state) => getLoadInit3kit(state));
  const getModelsGroupId = useAppSelector((state) => getModelsGroupIdByCollectionId(state)(collectionId));

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (!document.fullscreenElement) {
      const closeButton = document.getElementById("closeFull");
      // closeButton!.style.display = "none";
      closeButton!.style.cssText =
        "display: none !important; position: absolute; right: 50px; top: 50px; z-index: 999; cursor: pointer";
      window.player.scene.set(
        {
          name: "246731",
          plug: "Environment",
          property: "backgroundColorOpacity",
        },
        0
      );
    }
  }

  useEffect(() => {
    if (isLocationModelName) {
      dispatch(openCloseModals({ name: "SectionalTutorial" }));
      checkIsLoadingPlayer(() => {
        if (!isSectionalConfigurator()) {
          if (collectionId === "bed") {
            if (locationState.type === "headboard") {
              switch (locationState.nameThreekitArtibuteModel) {
                case "Eastern King":
                  dispatch(
                    changeThreekitConfiguration({
                      height: "50” classic",
                    })
                  );
                  break;
                case "California King":
                  dispatch(
                    changeThreekitConfiguration({
                      height: "38” modern",
                    })
                  );

                  break;
                case "Full":
                  dispatch(
                    changeThreekitConfiguration({
                      height: "70” luxe",
                    })
                  );
                  break;
                default:
                  break;
              }
            }

            dispatch(
              changeThreekitConfiguration({
                [getModelsGroupId]: locationState.nameThreekitArtibuteModel,
              })
            );
          }
        } else {
          const objModels = locationState.nameThreekitArtibuteModel as any;
          // dispatch(setStarModelObj(objModels));
        }
      });
    }
  }, [pathname]);

  function getPlayerAndInterfaceHeight(vh: any) {
    if (document.documentElement.clientWidth <= 1024) {
      switch (window.screen.orientation.type) {
        case "landscape-primary":
        case "landscape-secondary":
          document.documentElement.style.setProperty("--interfaceHeight", `44vw`);
          document.documentElement.style.setProperty("--playerHeight", `56vw`);
          break;
        case "portrait-secondary":
        case "portrait-primary":
          document.documentElement.style.setProperty("--interfaceHeight", `calc(var(--vh, 1vh) * 44)`);
          document.documentElement.style.setProperty("--playerHeight", `calc(var(--vh, 1vh) * 56)`);
          break;
        default:
      }
    }
  }

  function getTrueVh() {
    setTimeout(() => {
      let vh = window.innerHeight * 0.01;
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      // getPlayerAndInterfaceHeight(vh)

      // // We listen to the resize event

      window.addEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        // getPlayerAndInterfaceHeight(vh)
      });
    }, 0);
  }

  useEffect(() => {
    const player = document.getElementById("player");
    if (player && pathname.includes("config")) {
      let assetId = !!activeCollectionObj ? activeCollectionObj.aseetId : null;

      if (!Array.isArray(assetId) && player.firstChild) return;

      getTrueVh();

      dispatch(changeLoadInit3kitState(true));

      load3kit(() => {
        const configuratorName = !!activeCollectionObj ? activeCollectionObj["id"] : null;

        if (Array.isArray(assetId)) {
          if (typeBedConfigurator) {
            if (typeBedConfigurator === "headboard") {
              assetId = !!activeCollectionObj && activeCollectionObj.aseetId[1];
              dispatch(setActiveTab("Headboards Only"));
            } else {
              assetId = !!activeCollectionObj && activeCollectionObj.aseetId[0];
              dispatch(setActiveTab("Beds"));
            }
          }
        }

        if (collectionId === "bed" && player.firstChild) {
          if (window.player.assetId === assetId) {
            return;
          }
        }

        if (assetId) {
          init3kit({
            playerEl: player,
            idConfig: shortId,
            THREEKIT_PARAMS: {
              ...THREEKIT_PARAMS,
              assetId: assetId,
            },
            dispatch,
            collection: collectionId,
            configuratorName: configuratorName,
            startModel: isLocationModelName ? locationState.nameThreekitArtibuteModel : "",
          });
        }
      });
    }
  }, [pathname]);

  return (
    <div className={s.playerWrapper} id="playerContainer">
      <div
        id="closeFull"
        className={`${s.closeFull}`}
        style={{
          position: "absolute",
          right: "50px",
          top: "50px",
          zIndex: 999,
          cursor: "pointer",
        }}
        onClick={() => {
          var doc = window.document;

          var cancelFullScreen =
            doc.exitFullscreen ||
            //@ts-ignore
            doc.mozCancelFullScreen ||
            //@ts-ignore
            doc.webkitExitFullscreen ||
            //@ts-ignore
            doc.msExitFullscreen;
          // document.exitFullscreen();
          cancelFullScreen.call(doc);
        }}
      >
        {/* {<Close />} */}
        <img src={close} alt="" />
      </div>

      <div
        className={s.player}
        id="player"
        ref={playerEl}
        onMouseDown={() => handleHoldStartEvent(true)}
        onTouchStart={() => handleHoldStartEvent(true)}
        onMouseUp={() => handleHoldStartEvent(false)}
        onTouchEnd={() => handleHoldStartEvent(false)}
      />

      {isLoadPlayer && !isLoadInit3kit && (
        <div className={`${s.loader} ${s.opacity}`}>
          <LoaderCircle />
        </div>
      )}
      {isLoadInit3kit && (
        <div className={`${s.loader}`}>
          <LoaderCircle />
          <span className={s.loader_text}>One moment please</span>
        </div>
      )}
    </div>
  );
};
