import {
  createNameModel,
  getIndexModel,
  getKeyPrevModel,
} from "../../utils/configSofa/utilsFuncModel";
import { RootState } from "../store";
import { objectNewPointT } from "../types/typeModels";
import {
  getPositionModificationPointT,
  newPointsT,
} from "../types/typeThreekitStateModal";
// import {
//   getPositionModificationPointT,
//   newPointsT,
// } from "../types/typeStateModal";
import {
  getActiveIdModel,
  getModels,
  getSelectedModel,
  getSelectedModelId,
} from "./model.selector";

export const getListNewPoint = (state: RootState) =>
  Object.assign({}, state.threeKit.newPoints);

export const getNameActivePoint = (state: RootState) =>
  state.threeKit.activePoint;

export const getActiveNewPoint = (state: RootState) => {
  const nameActivePoint = getNameActivePoint(state);
  const objPoint = getListNewPoint(state);

  // if (!!objPoint[nameActivePoint])
  return objPoint[nameActivePoint];
  // return null;
};

export const getModificationPoints =
  (modification: getPositionModificationPointT) => (state: RootState) => {
    const points = getListNewPoint(state);
    const keys = Object.keys(points).filter((pointKey) =>
      pointKey.includes(modification)
    );
    return keys;
  };
export const getPrevPoint =
  (currentIndexModal: number, modification: string) => (state: RootState) => {
    const points = getListNewPoint(state);
    let newIndex = currentIndexModal === 0 ? 1 : currentIndexModal;

    if (modification === "first") {
      let keyPrevModelPositive = `point_positive_${newIndex}#`;
      let keyPrevModelNegative = `point_negative_${newIndex}#`;

      return {
        [keyPrevModelPositive]: points[keyPrevModelPositive],
        [keyPrevModelNegative]: points[keyPrevModelNegative],
      };
    }

    let keyPrevModel = `point_${modification}_${newIndex}#`;
    return { [keyPrevModel]: points[keyPrevModel] };
  };

export const getPositionModificationPoint = (
  state: RootState
): getPositionModificationPointT => {
  const nameActivePoint = getNameActivePoint(state);
  if (nameActivePoint.includes("positive")) return "positive";
  return "negative";
};

export const getPointIndexPoint = (state: RootState) => {
  const activeNewPoint = getActiveNewPoint(state);
  if (!activeNewPoint) return null;
  return activeNewPoint.pointIndex;
};
export const getPositionPoint = (state: RootState) => {
  const activeNewPoint = getActiveNewPoint(state);
  return activeNewPoint.position;
};

export const getAllFollowingKeyPoint = (state: RootState) => {
  const pointsInView = getListNewPoint(state);
  const positionModificationPoint = getPositionModificationPoint(state);
  const pointIndexPoint = getPointIndexPoint(state);

  const listKeys = Object.keys(pointsInView).filter((modelKey) =>
    modelKey.includes(positionModificationPoint)
  );

  let listKeyFollowingPoint = listKeys.filter((modelKey) => {
    let model = pointsInView[modelKey];
    if (pointIndexPoint <= model.pointIndex) return true;
    return false;
  });

  return listKeyFollowingPoint;
};

export const getFollowingPointAfterKey =
  (currentIndexModal: number, modification: string) => (state: RootState) => {
    const points = getListNewPoint(state);
    const currentIndexPoint = currentIndexModal + 1;
    const listsKeyPointsModification = Object.keys(points).filter((modelKey) =>
      modelKey.includes(modification)
    );
    let listKeyFollowingKeyModelAfterKeyModel =
      listsKeyPointsModification.filter((key) => {
        let point = points[key];
        if (point["pointIndex"] > currentIndexPoint) return true;
        return false;
      });

    let objFollowingKeyModelAfterModel: any = {};

    listKeyFollowingKeyModelAfterKeyModel.forEach((keyPoint: any) => {
      objFollowingKeyModelAfterModel = {
        ...objFollowingKeyModelAfterModel,
        [keyPoint]: points[keyPoint],
      };
    });

    return objFollowingKeyModelAfterModel;
  };

export const getAllFollowingPoints = (state: RootState) => {
  const allFollowingKeyPoints = getAllFollowingKeyPoint(state);
  const modificationPoint = getPositionModificationPoint(state);

  const pointsInView: newPointsT = getListNewPoint(state);
  let followingPoints: newPointsT = {};

  allFollowingKeyPoints.forEach((key: any) => {
    let indexModel = Number(
      key.split(`${modificationPoint}_`)[1].split("#")[0]
    );
    let nextIndexModel = indexModel + 1;

    const keyNewModel = `point_${modificationPoint}_${nextIndexModel}#`;

    let point: objectNewPointT = JSON.parse(JSON.stringify(pointsInView[key]));
    point["pointIndex"] = nextIndexModel;

    followingPoints = {
      ...followingPoints,
      [keyNewModel]: point,
    };
  });

  return followingPoints;
};

export const getLastPoint = (state: RootState): string[] => {
  let listPoints: string[] = [];
  const positionPoint: any[] = getModificationPoints("positive")(state);
  const negativePoint: any[] = getModificationPoints("negative")(state);

  let negativeLastPoint = negativePoint.pop();
  let positiveLastPoint = positionPoint.pop();

  const negativePointIndex = getIndexModel(negativeLastPoint, "negative");
  const positivePointIndex = getIndexModel(positiveLastPoint, "positive");

  const models = getModels(state);

  let lastNegativeModel: any = getKeyPrevModel({
    modification: "negative",
    currentIndexModal: negativePointIndex,
  });
  let lastPositiveModel: any = getKeyPrevModel({
    modification: "positive",
    currentIndexModal: positivePointIndex,
  });

  let nextLastNegativeModel: any = getKeyPrevModel({
    modification: "negative",
    currentIndexModal: negativePointIndex + 1,
  });
  let nextLastPositiveModel: any = getKeyPrevModel({
    modification: "positive",
    currentIndexModal: positivePointIndex + 1,
  });

  if (lastNegativeModel === lastPositiveModel) {
    // debugger;
    if (!models[nextLastNegativeModel]) {
      listPoints.push(negativeLastPoint);
    }
    // debugger;
    if (!models[nextLastPositiveModel]) {
      listPoints.push(positiveLastPoint);
    }
    // debugger;
    return listPoints;
  }
  if (!models[nextLastNegativeModel]) {
    listPoints.push(negativeLastPoint);
  }
  if (!models[nextLastPositiveModel]) {
    listPoints.push(positiveLastPoint);
  }

  return listPoints;
};
export const getPointsSelectModel = (state: RootState) => {
  let listPoints: string[] = [];
  const activeIdModel = getActiveIdModel(state);
  if (!activeIdModel) return listPoints;
  const pointsList = getListNewPoint(state);

  const models = getModels(state);
  const selectedModelInfo: any = getSelectedModel(state);

  const modification: any = selectedModelInfo["modification"];
  const positionSelectionModel =
    models[selectedModelInfo["selectedKeyModel"]]["position"];

  let afterPointModel: any = `point_${modification}_${positionSelectionModel}#`;
  let beforePointModel: any = `point_${modification}_${
    positionSelectionModel + 1
  }#`;

  if (positionSelectionModel === 0) {
    afterPointModel = `point_${"negative"}_${positionSelectionModel + 1}#`;
    beforePointModel = `point_${"positive"}_${positionSelectionModel + 1}#`;
  }

  if (pointsList[afterPointModel]) listPoints.push(afterPointModel);
  if (pointsList[beforePointModel]) listPoints.push(beforePointModel);

  return listPoints;
};
export const getPointsSelectModelID =
  (idModel: string) => (state: RootState) => {
    let listPoints: any = [];
    const pointsList = getListNewPoint(state);

    const models = getModels(state);
    const selectedModelInfo: any = getSelectedModelId(idModel)(state);

    const modification: any = selectedModelInfo["modification"];
    const selctedModel = models[selectedModelInfo["selectedKeyModel"]];
    if (selctedModel) {
      const positionSelectionModel = selctedModel["position"];

      let afterPointModel: any = `point_${modification}_${positionSelectionModel}#`;
      let beforePointModel: any = `point_${modification}_${
        positionSelectionModel + 1
      }#`;

      if (positionSelectionModel === 0) {
        afterPointModel = `point_${"negative"}_${positionSelectionModel + 1}#`;
        beforePointModel = `point_${"positive"}_${positionSelectionModel + 1}#`;
      }

      if (pointsList[afterPointModel]) listPoints.push(afterPointModel);
      if (pointsList[beforePointModel]) listPoints.push(beforePointModel);
    }

    return listPoints;
  };

export const getPoints = (state: RootState) => state.threeKit.newPoints;

export const getPointsShow = (
  state: RootState
): { showPoint: string[]; listNotVisible: string[] } => {
  const points = getPoints(state);
  if (Object.keys(points).length === 0)
    return { showPoint: [], listNotVisible: [] };

  const lastPoint = getLastPoint(state);
  const pointsSelectModel = getPointsSelectModel(state);

  const showPoint = [...lastPoint, ...pointsSelectModel];

  const listPoints = Object.keys(getListNewPoint(state));

  const listNotVisible = listPoints.filter(
    (point: string) => !showPoint.includes(point)
  );

  return { showPoint, listNotVisible };
};
