import React, { useEffect, useState } from "react";
import s from "./DeleteModel.module.scss";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  getActiveIdModel,
  getModels,
} from "../../redux/selectors/model.selector";
import { deleteModel, deletePillow } from "../../redux/actions/player.action";
import { getActivePillow } from "../../redux/selectors/ui.selector";
import { useNavigate } from "react-router-dom";
import { setInitialState } from "../../redux/features/ui/uiSlice";
import { PAGES } from "../../utils/constants";
export const DeleteModel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activeModel: any = useAppSelector(getActiveIdModel);
  const activePillow = useAppSelector(getActivePillow);
  const allModels = useAppSelector(getModels);

  const handleClick = () => {
    if (activeModel) {
      dispatch(deleteModel({ assetId: activeModel, navigate }));
    }

    if (activePillow) {
      dispatch(deletePillow(activePillow));
    }
  };

  function deleteModelByKey(e: any) {
    e.preventDefault();
    switch (e.keyCode) {
      case 46: // Delete
      case 8: // Backspace
        if (!!activeModel || !!activePillow) {
          handleClick();
        }
        break;
    }
    // if (event.code === "Delete" && !!activeModel) {
    //   handleClick();
    // }
    document.removeEventListener("keydown", deleteModelByKey);
  }
  useEffect(() => {
    if (activeModel || activePillow) {
      document.addEventListener("keydown", deleteModelByKey, { once: true });
    }
    return () => {
      document.removeEventListener("keydown", deleteModelByKey);
    };
  }, [activeModel, activePillow]);

  return (
    <>
      {(!!activeModel || !!activePillow) && (
        <div className={s.wrapper} onClick={handleClick}>
          <img
            className={s.delete_img}
            src="/images/assets/delete.svg"
            alt=""
          />
          <span className={s.text}>
            or <span className={s.del}>del</span>
          </span>
        </div>
      )}
    </>
  );
};

export default DeleteModel;
