export type typeModel = "line" | "corner";

export type sideBind = "Left" | "Right" | "Bottom" | "Top";

export type getTypeObjectT = sideBind[];
//
// export enum turningAngles {
//     none = 0,
//     turningRightAngles = 90,
// }

export enum positionAngles {
  "pos_1" = -90,
  "pos_2" = -180,
  "pos_3" = -270,
  "pos_4" = 0,
  "pos_5" = -90,
  "pos_6" = -180,
  "pos_7" = -270,
}

export type angles = positionAngles;

// export type angles = -270 | -180 | -90 | 0 | 90 | 180 | 270;

export type bindDirectionType = "left" | "right";

//
export type objectParams = "length" | "height" | "width";
export type paramsObject = { [key in objectParams]: number };

export type coordinates = "x" | "y" | "z";
export type coordinatesObject = { [key in coordinates]: number };

export type settingObject = "min" | "max";
export type boundingBoxT = { [key in settingObject]: coordinatesObject };

export type transformObject = "translation" | "rotation";
export type transformType = { [key in transformObject]: coordinatesObject };
//

export type objectModelT = {
  assetId: string;
  isFirst: boolean;
  transform: transformType;
  angle: positionAngles;
  type: string;
  sideBind: getTypeObjectT;
  id: string;
  name: string;
  position: number;
  parametersModelPosition: getParametersModelPositionT;
};

export type objectPointT = {
  id: string;
  position: sideBind;
  translation: coordinatesObject;
  modelIndex: number;
  modelId: string;
};
export type objectNewPointT = {
  position: sideBind;
  translation: coordinatesObject;
  pointIndex: number;
};

export type coordinatePointT = {
  x: number;
  z: number;
};
export type segmentsModelT = {
  OMinX: number;
  OMaxX: number;
  OMinZ: number;
  OMaxZ: number;
};
export type getParametersModelPositionT = {
  boundingBox: boundingBoxT;
  coordinatesObject: coordinatePointT;
  segmentsModel: segmentsModelT;
};

export type inputParametersModelPosition = {
  sideBind: sideBind[];
  angle?: any
  id: string;
  сoordinateSystem: coordinatesObject;
};
