import axios, { AxiosInstance } from "axios";

export type instanceApiT = {
    urlApi: string
    authToken: string
}
export const instanceApiFunc = ({ urlApi, authToken }: instanceApiT) => {

    return axios.create({
        baseURL: urlApi,
        headers: {
            // Authorization: authToken,
        },
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },

    });

}

export const instancePDFFunc = ({ urlApi, authToken }: instanceApiT) => {

    return axios.create({
        baseURL: urlApi,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
            // Authorization: authToken,
        },
        validateStatus: function (status) {
            return status >= 200 && status < 300; // default
        },

    });

}
