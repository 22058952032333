import React, { useEffect, useMemo } from "react";
import s from "./ConfigurationInterfaсe.module.scss";
import { Accordion } from "../Accordion/Accordion";
import { TabsConfigurationInterface } from "../TabsConfigurationInterface/TabsConfigurationInterface";
import { Cart } from "../Cart/Cart";
import { compareStrings } from "../../utils/filterFabrics";

export default function ConfigurationInterface() {
  const tabsConfigurationInterface = useMemo(
    () => <TabsConfigurationInterface />,
    []
  );
  const accordion = useMemo(() => <Accordion />, []);
  const cart = useMemo(() => <Cart />, []);



  return (
    <div className={s.interface}>
      <div className={s.interface_header}>Mix it up. Make it yours.</div>
      {tabsConfigurationInterface}

      <div className={s.interface_scroll} id="configurationInterface">
        {accordion}
      </div>
      {cart}
    </div>
  );
}
