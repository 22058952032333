import React, { useEffect, useState } from "react";
import {
  changeThreekitConfiguration,
  deleteModel,
  setModel,
} from "../../redux/actions/player.action";
import { getActiveIdModel } from "../../redux/selectors/model.selector";
import { getAvailableItem } from "../../redux/selectors/player.selector";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { StandartOption } from "../StandartOption/StandartOption";
import s from "./StandartOptionsWrapScroll.module.scss";
import {
  getActiveCollectionId,
  getActiveParamFromIdComponentUI,
  getOptionsArrForArmSelector,
  getValuesFromIdAttribute,
} from "../../redux/selectors/ui.selector";
import { isSectionalConfigurator, refactoringModelLabel } from "../../utils/supportUtils";
import { getThreekitModels } from "../../redux/selectors/threekit.selectors";

export const StandartOptionsWrapScroll = ({ ...props }) => {
  const { id, label, typeOptions, values, additionalProperties } = props;
  const dispatch = useAppDispatch();
  const activeCollectionId = useAppSelector((state) =>
    getActiveCollectionId(state)
  );
  const valuesOptionsFromThreekit = useAppSelector((state) =>
    getValuesFromIdAttribute(id)(state)
  );
  const valueActiveOption = useAppSelector(getActiveParamFromIdComponentUI(id));

  const changeOptional = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    if (isSectionalConfigurator()) {
      addNewModel(value);
    } else {
      dispatch(changeThreekitConfiguration({ [id]: name }));
    }
  };

  let optionsArrForArmSelector = useAppSelector((state) =>
    getOptionsArrForArmSelector(id)(state)
  );

  const optionsArr =
    values.length > 0
      ? values
      : id !== "style" || activeCollectionId === "bed"
        ? valuesOptionsFromThreekit
        : optionsArrForArmSelector;

  const list = useAppSelector(getAvailableItem);
  const activeModelId = useAppSelector(getActiveIdModel);
  function addNewModel(value: any) {
    dispatch(
      setModel({
        assetId: value,
      })
    );
  }
  let valuesOptions;
  if (id === "AdditionalItems") {
    list.map((item: any, index) => {  
      const label = refactoringModelLabel(item.name);

      valuesOptions.push({
        name: item.name,
        label: label,
        value: item.assetId,
        visible: true,
        enabled: true,
        metadata: item.metadata, 
      });
    });
  } else {
    // debugger;
    valuesOptions = optionsArr;
  }

  return (
    <>
      {valuesOptions.length >= 1 && (
        <div className={s.optionsWrap}>
          {/* {!!label && (
                        <div className={s.optionsWrapLabel}>{label}</div>
                    )} */}
          <div className={s.optionsWrapDesktop}>
            {valuesOptions.map((option: any, index: number) => {
              return (
                <StandartOption
                  id={`${option.name.trim()}_${index}`}
                  key={`${option.name.trim()}_${index}`}
                  parentId={id}
                  activeValue={valueActiveOption}
                  {...option}
                  // buttonText={
                  //     !!additionalProperties &&
                  //     !!additionalProperties["buttonText"]
                  //         ? additionalProperties["buttonText"]
                  //         : "select"
                  // }
                  changeOptional={(info: any) => changeOptional(info)}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
