interface SofaPart {
    name: string;
    type: string;
    angle: number;
    width: number;
    height: number;
    depth: number;
}

export function calculateSofaDimensions(parts: SofaPart[]): { width: number, height: number, depth: number } {
    let currentX = 0;
    let currentY = 0;
    let minX = 0;
    let maxX = 0;
    let minY = 0;
    let maxY = 0;
    let maxHeight = 0;
    let totalDepth = 0;

    parts.forEach(part => {
        let width = part.width;
        let depth = part.depth;

        if (part.angle === -90 || part.angle === -270) {
            [width, depth] = [depth, width];
        }

        if (part.angle === 0) {
            currentX += width;
            if (part.type === "corner") {
                totalDepth += depth;
            }
        } else if (part.angle === -90) {
            currentY += depth;
            if (part.type === "corner") {
                totalDepth += width;
            } else {
                totalDepth += depth;
            }
        } else if (part.angle === -180) {
            currentX -= width;
            if (part.type === "corner") {
                totalDepth += depth;
            }
        } else if (part.angle === -270) {
            currentY -= depth;
            if (part.type === "corner") {
                currentX += width;
                totalDepth += width;
            } else {
                totalDepth += depth;
            }
        }

        maxX = Math.max(maxX, currentX);
        maxY = Math.max(maxY, currentY);
        minX = Math.min(minX, currentX);
        minY = Math.min(minY, currentY);
        maxHeight = Math.max(maxHeight, part.height);
    });

    return {
        width: Math.abs(maxX - minX),
        height: maxHeight,
        depth: totalDepth === 0 ? parts[0].depth : totalDepth
    };
}