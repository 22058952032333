import React, { Children } from "react";
import s from "./ModalsWrapper.module.scss";

export default function ModalsWrapper(props: any) {
  const { children, bg = "rgba(70, 121, 123, 0.7)" } = props;

  return (
    <div className={`${s.modalsWrapper}`} style={{ background: bg }}>
      {children}
    </div>
  );
}
