import React from "react";
import { getActiveTab } from "../../../redux/selectors/ui.selector";
import { useAppSelector } from "../../../utils/hooks";
import s from "./AllTimePillowHint.module.scss";

export const AllTimePillowHint = () => {
  const activeTab = useAppSelector((state) => getActiveTab(state));

  const pillowsHint = activeTab === "pillows";

  return (
    <>
      {pillowsHint && (
        <span className={s.pillowsHint}>
          Create Your Seating First, Then Choose Your Pillows
        </span>
      )}
    </>
  );
};
