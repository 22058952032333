import { LoaderCircle } from "../../assets/svgCompopnent/LoaderCircle";
import s from "./AccordionLoader.module.scss";

export const AccordionLoader = () => {
  return (
    <div className={s.loader}>
      <LoaderCircle />
      <span className={s.loader_text}>Worth the wait.</span>
      <span className={s.loader_text}>Good things take time.</span>
    </div>
  );
};
