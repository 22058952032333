import React, { useEffect, useRef, useState } from "react";
import { getModalInfo } from "../../../redux/selectors/ui.selector";
import s from "./SectionalTutorial.module.scss";

import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { Close } from "../../../assets/svgCompopnent/Close";
import { TutorialBtn } from "../../../assets/svgCompopnent/TutorialBtn";
export type infoUserT = {
  email: string[];
  name: string;
};

type stepsT = {
  text: any;
};
type stepItemsT = {
  id: string;
  image: any;
  title: string;
  stepId: string;
};

const stepContent: any = {
  SectionalTutorial_1: [
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>1</div>
            <span>Click on the “+” sign to add an additional section.</span>
          </div>
        );
      },
    },
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>2</div>
            <span>
              A menu will appear on the right, which contains a list of segments
              that can be added to that particular point.
              <span className={s.gray}>
                {" "}
                Note: the greyed out options can not be added at this particular
                spot.
              </span>
            </span>
          </div>
        );
      },
    },
  ],
  SectionalTutorial_2: [
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>3</div>
            <span>
              From the list of options, click on the one that you want to add to
              the sofa.
              <span className={s.red}>
                {" "}
                Once selected, it will be displayed on the 3D model of the
                sectional sofa, showing you exactly how it will look once it's
                added.
              </span>
            </span>
          </div>
        );
      },
    },
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>4</div>
            <span>
              Continue building your sectional by clicking on “+” signs to add
              sections.
            </span>
          </div>
        );
      },
    },
  ],
  DeleteTutorial: [
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <span>
              Click on it and a trash can symbol will appear. Click on that to
              remove the section.
              <span className={s.red}> (or you can use the Delete key)</span>
            </span>
          </div>
        );
      },
    },
  ],
  PillowTutorial_1: [
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>1</div>
            <span>
              Click on the tab “Pillows” next to “Create” at the top of the menu
              on the right.
            </span>
          </div>
        );
      },
    },
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>2</div>
            <span>
              <span className={s.red}>Select pillow of your choice</span> and
              it’ll appear on your sofa/sectional/creation.*
              <br />
              <br />
              <span className={s.hint}>
                *A maximum of three pillows will appear per section. For
                example, if you create a two-piece sectional, the max number of
                pillows is six.
              </span>
            </span>
          </div>
        );
      },
    },
  ],
  PillowTutorial_2: [
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>3</div>
            <span>You can move the location of the pillow by dragging it.</span>
          </div>
        );
      },
    },
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>4</div>
            <span>
              To delete: Click on it and select the trash can to delete.*
              <br />
              <br />
              <span className={s.gray}>
                *For sectionals: If a segment of the sectional is deleted, the
                pillows on that segment will be relocated to another section.
              </span>
            </span>
          </div>
        );
      },
    },
  ],
  PillowTutorial_3: [
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>1</div>
            <span>
              To change fabrics on the pillow,{" "}
              <span className={s.red}>
                start by click on the pillow of your choice.
              </span>
            </span>
          </div>
        );
      },
    },
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>2</div>
            <span>
              <span className={s.red}>Choose fabrics</span> from the list of
              available fabrics.
            </span>
          </div>
        );
      },
    },
    {
      text: () => {
        return (
          <div className={s.step_item}>
            <div className={s.step}>3</div>
            <span>
              <span className={s.red}>Mix it up</span> by selecting different
              fabrics for each pillow. Or make it a matching set by selecting
              the same fabric for all them.
            </span>
          </div>
        );
      },
    },
  ],
};
const stepItems: stepItemsT[] = [
  {
    id: "stepItems1",
    image:
      "/images/assets/tutorial/sectionalTutorial-1.png",
    title:
      "Create a sectional tailored to your needs and style in a few easy steps:",
    stepId: "SectionalTutorial_1",
  },
  {
    id: "stepItems2",
    image:
      "/images/assets/tutorial/sectionalTutorial-2.png",
    title:
      "Create a sectional tailored to your needs and style in a few easy steps:",
    stepId: "SectionalTutorial_2",
  },
  {
    id: "stepItems3",
    image:
      "/images/assets/tutorial/sectionalTutorial-3.png",
    title: "Want to delete a section?",
    stepId: "DeleteTutorial",
  },
  {
    id: "stepItems4",
    image:
      "/images/assets/tutorial/sectionalTutorial-4.png",
    title: "Dress it up with pillows!",
    stepId: "PillowTutorial_1",
  },
  {
    id: "stepItems5",
    image:
      "/images/assets/tutorial/sectionalTutorial-5.png",
    title: "Dress it up with pillows!",
    stepId: "PillowTutorial_2",
  },
  {
    id: "stepItems6",
    image:
      "/images/assets/tutorial/sectionalTutorial-6.png",
    title: "Mix-or-match: Pillow Fabrics",
    stepId: "PillowTutorial_3",
  },
];

export default function ModalSectionalTutorial() {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) =>
    getModalInfo(state)("SectionalTutorial")
  );
  const wrapperRef: any = useRef(null);
  const [actualStep, setActualStep] = useState(1);
  const [intervalTutorial, setIntervalTutorial] = useState();

  const updateIntervaleFunc = () => {
    const intervalStep: any = setInterval(() => {
      setActualStep((actualStep) => {
        let counter = actualStep + 1;
        if (counter > stepItems.length) {
          counter = 1;
        }
        return counter;
      });
    }, 11000);
    setIntervalTutorial(intervalStep);
  };
  function closeFunc() {
    dispatch(openCloseModals({ name: "SectionalTutorial" }));
  }

  useEffect(() => {
    setActualStep(1);
    if (isOpen) {
      updateIntervaleFunc();
    }
    return () => clearInterval(intervalTutorial);
  }, [isOpen]);

  useEffect(() => {
    if (closeFunc) {
      const handleClickOutside =
        (wrapperRef: any, closeFunc: any) => (event: any) => {
          if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target)
          ) {
            closeFunc();
          }
        };
      document.addEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );

      return () => {
        document.removeEventListener(
          "mousedown",
          handleClickOutside(wrapperRef, closeFunc)
        );
      };
    }
  }, [wrapperRef]);

  function nextStep(id: number) {
    setActualStep(id);
    clearInterval(intervalTutorial);
    updateIntervaleFunc();
  }

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.wrapper__block} ref={wrapperRef}>
            <div
              className={s.close}
              onClick={() =>
                dispatch(openCloseModals({ name: "SectionalTutorial" }))
              }
            >
              <Close />
            </div>

            {stepItems &&
              stepItems.map((item, index) => {
                if (item.id === `stepItems${actualStep}`) {
                  return (
                    <>
                      <div className={s.wrapper} key={item.id}>
                        <div className={s.info__wrapper}>
                          <div className={s.title}>{item.title}</div>

                          {stepContent[item.stepId] &&
                            stepContent[item.stepId].map((content: any) => {
                              return content.text();
                            })}
                        </div>
                        <div className={s.content_wrapper}>
                          <div className={s.image}>
                            <img src={item.image} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className={s.dots__wrapper}>
                        <div
                          className={`${s.btn_tutorial} ${s.btn_prev} ${
                            index === 0 && s.hidden
                          }`}
                          onClick={() => nextStep(index)}
                        >
                          <TutorialBtn />
                          <span>Previous</span>
                        </div>
                        <div className={s.dots}>
                          {stepItems.map((step, index) => {
                            return (
                              <div
                                className={`${s.dot} ${
                                  step.id === `stepItems${actualStep}`
                                    ? s.active
                                    : ""
                                }`}
                                key={`${step.id}-dot-${index}`}
                              ></div>
                            );
                          })}
                        </div>

                        <div
                          className={`${s.btn_tutorial} ${s.btn_next} ${
                            index + 1 === stepItems.length && s.hidden
                          }`}
                          onClick={() => nextStep(index + 2)}
                        >
                          <span>Next</span>
                          <TutorialBtn />
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
