import React, { useEffect, useRef } from "react";
import { Close } from "../../assets/svgCompopnent/Close";
import s from "./InfoModal.module.scss";

const InfoModal = ({ ...props }) => {
  const { infoText, isPopupShown, setIsPopupShown, infoIconId } = props;
  const modalEl = useRef<HTMLDivElement>(null);
  const infoIconRef = document.getElementById(infoIconId);
  const configurationInterfaceRef = document.getElementById(
    "configurationInterface"
  );
  const accordeonContentRef = document.getElementById(
    "accordionSeatTypeCushionsAppearanceFirmnesscontent"
  );

  const iconPosition = infoIconRef && infoIconRef.getBoundingClientRect();

  const onClickOutOfModal = (e: any) => {
    if (
      modalEl.current &&
      !modalEl.current.contains(e.target) &&
      e.target.id !== `${infoIconId}img`
    ) {
      setIsPopupShown(false);
    }
  };

  useEffect(() => {
    configurationInterfaceRef?.addEventListener("scroll", () =>
      setIsPopupShown(false)
    );
    accordeonContentRef?.addEventListener("scroll", () =>
      setIsPopupShown(false)
    );
    window.addEventListener("resize", () => setIsPopupShown(false));
    document.addEventListener("click", onClickOutOfModal);
    return () => {
      configurationInterfaceRef?.removeEventListener("scroll", () =>
        setIsPopupShown(false)
      );
      accordeonContentRef?.removeEventListener("scroll", () =>
        setIsPopupShown(false)
      );
      window.removeEventListener("resize", () => setIsPopupShown(false));
      document.removeEventListener("click", onClickOutOfModal);
    };
  }, []);

  function closeModal(e: any) {
    e.stopPropagation();
    setIsPopupShown(false);
  }

  return (
    <div
      className={s.infoPopup}
      style={{
        top: `${iconPosition && iconPosition.top}px`,
        left: `${iconPosition && iconPosition.left}px`,
      }}
      id="infoModalPopup"
      ref={modalEl}
    >
      <span className={s.text}>{infoText}</span>
      <span className={s.close} onClick={(e) => closeModal(e)}>
        <Close />
      </span>
    </div>
  );
};

export default InfoModal;
