import { objSetConfigurationT } from "../../redux/features/threekit/threekit.types";
import { getThreekitModels } from "../../redux/selectors/threekit.selectors";
import { isSectionalConfigurator } from "../supportUtils";
import { getConfiguratorInstance, setConfiguration, setObjConfiguration } from "./threekitFunc";

export const getDisplayAttributes = () => {
  return window.configurator.getDisplayAttributes();
};

export const setAttributes = (name: string, data: any) => {
  window.configurator.setConfiguration({ [name]: data });
};

export const setUpdateAttribute = async (
  nameThreekitAttribute: string,
  objAssetIdForValue: any,
  valueParamUI?: string
) => {
  if (!!objAssetIdForValue && !!objAssetIdForValue.assetId) {
    return await setConfiguration(nameThreekitAttribute, objAssetIdForValue);
  }
  return await setConfiguration(nameThreekitAttribute, valueParamUI);
};

export const setUpdateAttributes = async (objAtributes: objSetConfigurationT) => {
  return await setObjConfiguration(objAtributes);
};

export const getAttribute = (name: string) => {
  if (window.configurator) {
    const findAttr = window.configurator.getDisplayAttributes().find((attr: { name: string }) => attr.name === name);

    if (findAttr) {
      return findAttr.value;
    } else {
      return false;
    }
  }

  return false;
};

export const getAttributeData = (name: string) => {
  if (window.configurator) {
    const findAttr = window.configurator.getDisplayAttributes().find((attr: { name: string }) => attr.name === name);

    if (findAttr) {
      return findAttr;
    } else {
      return false;
    }
  }

  return false;
};

export const getArrValuesAttributeThreekit = (nameAttribute: string) => {
  const objAttribute = getAttributeData(nameAttribute);

  if (!objAttribute) return false;

  return objAttribute["values"];
};

export const getObjValueAttributeThreekit = (nameAttribute: string, nameValueAttribute: string) => {
  const arrValuesAttributeThreekit = getArrValuesAttributeThreekit(nameAttribute);

  if (!arrValuesAttributeThreekit || arrValuesAttributeThreekit.length === 0) return false;

  const objValueSofaType = arrValuesAttributeThreekit.find((value: any) => value["name"] === nameValueAttribute);

  return objValueSofaType;
};

export const getListMaterialModels = async (state: any) => {
  let materials: any[] = [];

  const listModels = getThreekitModels(state);

  const listsIdModels = listModels.map((model: any) => model["id"]);

  let allPush: any = [];

  listsIdModels.forEach((modelId: string) => {
    //@ts-ignore
    allPush.push(getConfiguratorInstance(modelId));
  });
  await Promise.all(allPush).then((resArray: any[]) => {
    resArray.forEach((configModel: any) => {
      const pillowMaterials = configModel
        .getDisplayAttributes()
        .filter((attr: { name: string }) => !attr.name.includes("Sofa material"));

      materials = [...materials, ...pillowMaterials];
    });
  });
  return materials;
};

export const getObjectUniqueMaterialModels = async (state: any) => {
  const materilaModels = await getListMaterialModels(state);
  let ojectMaterials: any = {};
  const sceneAttributes = getDisplayAttributes();
  const allAttributes = [...materilaModels, ...sceneAttributes];

  allAttributes.forEach((material) => {
    const nameMaterial = material["name"];
    ojectMaterials = { ...ojectMaterials, [nameMaterial]: material };
  });

  return ojectMaterials;
};

export const getAllPillowsMaterials = async (state: any) => {
  const list = await getListMaterialModels(state);

  let objMaterial = {};

  if (list.length < 1) return objMaterial;

  list.forEach((item) => {
    objMaterial = {
      ...objMaterial,
      [item["name"]]: item["values"],
    };
  });

  return objMaterial;
};

export const upadateDefaultValueCurrentPillowMaterial = (materilaModels: any, allPillows: any) => {
  if (Object.keys(materilaModels).length < 1) return {};
  let updatedPilows = {};
  Object.keys(materilaModels).forEach((key) => {
    if (allPillows[key]) {
      updatedPilows = {
        ...updatedPilows,
        [key]: allPillows[key],
      };
    } else {
      updatedPilows = {
        ...updatedPilows,
        [key]: materilaModels[key]["defaultValue"]["assetId"],
      };
    }
  });

  return updatedPilows;
};

export const updateMaterialInModel =
  (listModels: any) =>
  (objParams: { [key: string]: any }): any => {
    let config = window.configurator;
    //@ts-ignore
    const parrentPlayer = window.parrentPlayer;
    if (parrentPlayer) config = parrentPlayer.configurator;

    const listsIdModels = listModels.map((model: any) => model["id"]);
    window.configurator.setConfiguration(objParams);

    let allPush: any = [];
    listsIdModels.forEach((modelId: string) => {
      //@ts-ignore
      // allPush.push(
      //   window.playerT.getConfiguratorInstance({
      //     from: parrentPlayer.id,
      //     id: modelId,
      //     plug: "Null",
      //     property: "asset",
      //   })
      // );
      allPush.push(getConfiguratorInstance(modelId));
    });

    return Promise.all(allPush).then((resArray: any[]) => {
      let allPromisesSetConfiguration: any = [];
      resArray.forEach((configModel: any) => {
        if (configModel) {
          allPromisesSetConfiguration.push(configModel.setConfiguration(objParams));
        }
      });
      return Promise.all(allPromisesSetConfiguration);
    });
  };

export const getPillowMaterialInModel = (ModelId: any) => {
  // const pilowIds = window.player.scene.find({
  //   from: window.playerT.api.instanceId,
  //   hierarchical: true,
  //   name: `Pillow 1`,
  // });
  // console.log("pilowIds: ", pilowIds);
  // console.log("ModelId: ", ModelId);

  let modelInstance = getConfiguratorInstance(ModelId);
  return Promise.all([modelInstance]).then((configModel: any) => {
    if (configModel && isSectionalConfigurator()) {
      //@ts-ignore
      const configurator = configModel[0];
      const MaterialAttributes = configurator.attributes.find((item: any) => item.name === "Pillow material");
      const defaultAssetId = MaterialAttributes.defaultValue.assetId;
      const materialId = MaterialAttributes.id;
      const material = configurator.attributeState[materialId];
      const PillowMaterial = material.values.find((item: any) => item.assetId === defaultAssetId);

      const PillowMaterialName = PillowMaterial.name;

      return Promise.all([PillowMaterialName, material, MaterialAttributes]);
    } else {
      const pillowMatAttr = window.playerT.configurator
        .getDisplayAttributes()
        .find((item: any) => item.name === "Pillow material");
      const defaultAssetId = pillowMatAttr.defaultValue.assetId;

      const PillowMaterial = pillowMatAttr.values.find((item: any) => item.assetId === defaultAssetId);

      const PillowMaterialName = PillowMaterial.name;
      return Promise.all([PillowMaterialName, pillowMatAttr, pillowMatAttr]);
    }
  });
};

export const updateMaterialPillow = (listPillows: any) => {
  let allPush: any = [];
  let allMaterial: any = [];
  listPillows.forEach((item: any, index: number) => {
    allPush.push(getConfiguratorInstance(item.id));
    allMaterial.push(item.materialObg);
  });

  return Promise.all(allPush).then((resArray: any[]) => {
    let allPromisesSetConfiguration: any = [];
    resArray.forEach((config: any, index: number) => {
      if (config) {
        allPromisesSetConfiguration.push(config.setConfiguration(allMaterial[index]));
      }
    });
    return Promise.all(allPromisesSetConfiguration);
  });
};

export const updatePillowMaterial = (nameMaterial: string, assetId: string) => (listModels: any) => {
  updateMaterialInModel(listModels)({ [nameMaterial]: { assetId } });
};

export const changeArttributeForUpdateScene = () => {
  const supportAnributeName = "supportAttributeForSceneUpdateUI";
  const currentValue = getAttribute(supportAnributeName);
  setAttributes(supportAnributeName, !currentValue);
};
