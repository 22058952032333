import { ErrorMessage, Field, FieldArray } from 'formik';
import React, { useRef } from 'react'
import { AddAnotherEmail } from '../AddAnotherEmail/AddAnotherEmail';
import { SendButton } from '../SendButton/SendButton';
import s from './FieldArrayEmails.module.scss'
type FieldArrayEmailsProps = {
    sendText: string;
    name: string;
    maxFields?: number;
};

export const FieldArrayEmails = ({
    sendText,
    name,
    maxFields = 5,
}: FieldArrayEmailsProps) => {

    const handleAddEmail = (pushHelper: any) => {
        pushHelper('');
    };
    return (
        <FieldArray name={name}>
            {({ form, push }: any) => {
                const values = form.values
                return (
                    <div className={s.email}>
                        <div className={s.email_list} >
                            {form.values[name].map((_: any, index: any) => (
                                <React.Fragment key={index}>
                                    <div className={s.inputWrap}>
                                        <Field
                                            name={`${name}.${index}`}
                                            type="email"
                                            placeholder="example@gmail.com"
                                        />
                                        <ErrorMessage
                                            name={`${name}.${index}`}
                                            component="div"
                                            className={s.formErrors}
                                        />
                                        {values.email.length === 1 && <SendButton sendText={sendText} />}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className={s.email_buttons}>
                            {values.email.length < 5 && <AddAnotherEmail handleAddEmail={() => handleAddEmail(push)} />}
                            {values.email.length > 1 && <SendButton className={` ${s.multiple}`} sendText={sendText} />}
                        </div>
                    </div>
                )
            }}
        </FieldArray>
    )
}
