import { ErrorMessage, Field, Form } from "formik";

import { FieldArrayEmails } from "./FieldArrayEmails/FieldArrayEmails";

import { getIsInStore } from "../../../redux/selectors/ui.selector";
import { useAppSelector } from "../../../utils/hooks";
import s from "./ConfigForm.module.scss";
import { ErrorMessageEmailModal } from "./ErrorMessageEmailModal/ErrorMessageEmailModal";

export const ConfigForm = ({ sendText, errors }: any) => {
  const inStore = useAppSelector(getIsInStore);
  return (
    <Form>
      <div className={s.fildsLine}>
        <div className={s.inputWrap}>
          <label>Customer name</label>
          <div className={s.fieldCollectionName}>
            <Field type="text" name="name" placeholder="Enter your name" />
            <ErrorMessage name="name" />
          </div>
        </div>
        {inStore && (
          <div className={s.inputWrap}>
            <label>Sales associate</label>
            <div className={s.fieldCollectionName}>
              <Field type="text" name="sales_associate" placeholder="Enter sales associate" />
              <ErrorMessage name="sales_associate" />
            </div>
          </div>
        )}
        <div className={s.inputWrap}>
          <label>Email {<ErrorMessageEmailModal errors={errors} />}</label>

          <FieldArrayEmails sendText={sendText} name="email" />
        </div>
      </div>
    </Form>
  );
};
