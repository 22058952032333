import { TYPE_REDUCER } from "../../utils/constants";
import { ThreekitState } from "../features/threekit/threekit.types";
import {
  modelSectionalPresetT,
  objectActiveParamsT,
} from "../features/ui/ui.types";
import { coordinatesObject } from "../types/typeModels";
import {
  modelsT,
  newPointsT,
  startModelsT,
} from "../types/typeThreekitStateModal";
import {
  addActiveModelPayload,
  addActivePointPayload,
  addAllModelsPayload,
} from "./player.types";

type setStarModelT = {
  assetId: string;
};

// export const setStarModel = (payload: setStarModelT) => ({
//   type: TYPE_REDUCER.SET_START_MODEL,
//   payload,
// });

export const setStarModelObj = (payload: startModelsT) => ({
  type: TYPE_REDUCER.SET_START_MODEL_OBJ,
  payload,
});

export const setModel = (payload: any) => ({
  type: TYPE_REDUCER.SET_MODEL,
  payload,
});

export const setObjectModel = (payload: modelsT) => ({
  type: TYPE_REDUCER.SET_LIST_MODEL_IN_REDAX,
  payload,
});
export const setUpdatePointsAndModels = (payload: {
  points: newPointsT;
  models: modelsT;
}) => ({
  type: TYPE_REDUCER.SET_UPDATE_POINTS_OR_MODELS,
  payload,
});
export const setDeletePointsAndModels = (payload: {
  points: newPointsT;
  models: modelsT;
  differenceModels: any;
  differencePoints: any;
}) => ({
  type: TYPE_REDUCER.SET_DELETE_POINTS_OR_MODELS,
  payload,
});

export const setActiveModelInState = (payload: addActiveModelPayload) => ({
  type: TYPE_REDUCER.SET_ACTIVE_MODEL_IN_STATE,
  payload,
});

export const addActivePoint = (payload: addActivePointPayload) => ({
  type: TYPE_REDUCER.ADD_ACTIVE_POINT,
  payload,
});

export const setUserModel = (payload: addActiveModelPayload) => ({
  type: TYPE_REDUCER.SET_USER_MODEL,
  payload,
});

export const addActiveModel = (payload: addActiveModelPayload) => ({
  type: TYPE_REDUCER.ADD_ACTIVE_MODEL,
  payload,
});

export const addAllModels = (payload: addAllModelsPayload) => ({
  type: TYPE_REDUCER.ADD_ALL_MODELS,
  payload,
});

export const setNewThreekitState = (payload: ThreekitState) => ({
  type: TYPE_REDUCER.ADD_NEW_THREEKIT_STATE,
  payload,
});

export const deleteModel = (payload: any) => ({
  type: TYPE_REDUCER.DELETE_MODEL,
  payload,
});

export const deleteAllModels = () => ({
  type: TYPE_REDUCER.DELETE_ALL_MODELS,
});

export const setInitThreekitStaet = () => ({
  type: TYPE_REDUCER.SET_INIT_STATE,
});

export const completeBuild = (payload: { status: boolean }) => ({
  type: TYPE_REDUCER.CHANGE_BULDING_STATUS,
  payload,
});

export const setActivePoint = (payload: newPointsT) => ({
  type: TYPE_REDUCER.SET_NEW_POINTS,
  payload,
});

export const setAllMaterialsForPillows = (payload: any) => ({
  type: TYPE_REDUCER.SET_ALL_MATERIALS,
  payload,
});

export const changeMaterialPillow = (payload: {
  material: string;
  id: string;
}) => ({
  type: TYPE_REDUCER.CHANGE_MATERIAL_PILLOW,
  payload,
});

// export const setModelForSingleConfig = (payload: any) => ({
//   type: TYPE_REDUCER.SET_MODEL_FOR_SINGLE_CONFIG,
//   payload
// })

export const changeThreekitConfiguration = (payload: objectActiveParamsT) => ({
  type: TYPE_REDUCER.CHANGE_THREEKIT_CONFIGURATION,
  payload,
});

export const setPresetModelsAndParams = (payload: modelSectionalPresetT) => ({
  type: TYPE_REDUCER.SET_PRESET_MODELS_AND_PARAMS,
  payload,
});

export const deletePillow = (payload: string) => ({
  type: TYPE_REDUCER.DELETE_PILLOW,
  payload,
});


export const deleteAllPillows = () => ({
  type: TYPE_REDUCER.DELETE_ALL_PILLOWS,
});

export const updateModelsPrice = () => ({
  type: TYPE_REDUCER.UPDATE_MODELS_PRICE,
});

export const updatePillowsPrice = () => ({
  type: TYPE_REDUCER.UPDATE_ALL_PILLOW_PRICE,
});


export const addPillowInState = (payload: {
  id: string;
  transition?: coordinatesObject;
  materialObg?: any;
  name?: string;
  indexPillow?: number;
  price?: number;
  skuMaterial?: string;
  skuFull?: string;
  skuShort?: string;
  modelInScene: boolean;
  assetId: string;
  defaultPillowMaterial: any;
  [key: string]: any;
}) => ({
  type: TYPE_REDUCER.ADD_PILLOW_IN_STATE,
  payload,
});

export const deletePillowInStateById = (payload: {
  id: string;
  pillows: any;
}) => ({
  type: TYPE_REDUCER.DELETE_PILLOW_STATE,
  payload,
});

export const setPillowInfoInState = (payload: {
  pillows: any;
}) => ({
  type: TYPE_REDUCER.SET_PILLOW_INFO_IN_STATE,
  payload,
});

export const addActiveThreeekitPillow = (payload: { id: string }) => ({
  type: TYPE_REDUCER.ADD_ACTIVE_PILLOW,
  payload,
});

export const updateThreeekitPillow = (payload: {
  id: string;
  newAttributes: any;
}) => ({
  type: TYPE_REDUCER.UPDATE_PILLOW_IN_STATE,
  payload,
});

export const deleteAllPillowsInState = () => ({
  type: TYPE_REDUCER.DELETE_ALL_PILLOWS_IN_STATE,
});
