import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import useMobileWidth from "../../../hooks/useMobileWidth";
import { collectionUIT } from "../../../redux/features/ui/ui.types";
import { setActiveColection } from "../../../redux/features/ui/uiSlice";
import { getSubMenuCollectionsArr } from "../../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import s from "./MenuSub.module.scss";

let settingsSlider = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  onSwipe: (slide: string) => {},
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
      },
    },
    {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
        },
      },
  ],
};
const MOB_ITEM_WIDTH = 220;

export default function MenuSub() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobileSize = useMobileWidth();
  const elWrapper = useRef<HTMLDivElement>(null);
  const [elCatalogSubMobWidth, setElCatalogSubMobWidth] = useState({
    width: 0,
    marginSize: 0,
    marginDirection: "right",
  });
  const collectionsMenuSub = useAppSelector((state) =>
    getSubMenuCollectionsArr(state)
  );

  const handleClick = (collection: collectionUIT) => {
    dispatch(setActiveColection(collection.id));
    navigate(collection.id, { state: { collectionId: collection.id } });
  };

  settingsSlider = {
    ...settingsSlider,
    // onSwipe: (directionSwipe: string) => {
    //   setElCatalogSubMobWidth({
    //     ...elCatalogSubMobWidth,
    //     marginDirection: directionSwipe,
    //   });
    // },
  };

//   useEffect(() => {
//     function handleResize() {
//       if (!!elWrapper.current) {
//         let compensator = 0;
//         const wrapperWidth: number = elWrapper.current.clientWidth;
//         const countSlides = Math.floor(wrapperWidth / MOB_ITEM_WIDTH);
//         const division = wrapperWidth % MOB_ITEM_WIDTH;
//         compensator =
//           division > MOB_ITEM_WIDTH / 2 ? MOB_ITEM_WIDTH / 4 : compensator;
//         setElCatalogSubMobWidth({
//           ...elCatalogSubMobWidth,
//           width: countSlides * MOB_ITEM_WIDTH,
//           marginSize: division,
//         });
//         settingsSlider = {
//           ...settingsSlider,
//           slidesToShow: countSlides,
//         };
//       }
//     }
//     window.addEventListener("resize", handleResize);
//     handleResize();
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

  return (
    <div className={s.mobile_section} ref={elWrapper}>
      <div className={s.catalog_sub_background}></div>
      {!isMobileSize ? (
        <div className={s.catalog_sub}>
          {collectionsMenuSub.map(
            (collection: collectionUIT, index: number) => (
              <div
                className={s.catalog_item}
                key={`${collection.id}_${index}`}
                onClick={() => handleClick(collection)}
              >
                <img src={collection.img} alt={collection.label} />
                <span className={s.catalog_item_title}>{collection.label}</span>
              </div>
            )
          )}
        </div>
      ) : (
        <div
          className={s.catalog_sub_mob}
          style={{
            // width: `${elCatalogSubMobWidth.width}px`,
            // margin: `0 ${
            //     elCatalogSubMobWidth.marginDirection === "right" ? elCatalogSubMobWidth.marginSize + 'px' : 0
            // } 0 ${
            //     elCatalogSubMobWidth.marginDirection === "left" ? elCatalogSubMobWidth.marginSize + 'px' : 0
            // }`,
          }}
        >
          <Slider {...settingsSlider}>
            {collectionsMenuSub.map(
              (collection: collectionUIT, index: number) => (
                <div
                  className={s.catalog_item}
                  key={`${collection.id}_${index}`}
                  onClick={() => handleClick(collection)}
                >
                  <img src={collection.img} alt={collection.label} />
                  <span className={s.catalog_item_title}>
                    {collection.label}
                  </span>
                </div>
              )
            )}
          </Slider>
        </div>
      )}
    </div>
  );
}
