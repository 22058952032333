export const Close = ({ ...props }) => {
  return (
    <svg viewBox="0 0 21 21" stroke="#101820" xmlns="http://www.w3.org/2000/svg">
      <path
        {...props}
        d="M1 1L20 20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 1L1 20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
