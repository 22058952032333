import { Dispatch } from "redux";
import {
  addActivePoint,
  addActiveModel,
  addActiveThreeekitPillow,
} from "../../redux/actions/player.action";
import {
  changeActiveSide,
  changeActiveStep,
  setActiveCategory,
  setActivePillow,
  setActiveTab,
} from "../../redux/features/ui/uiSlice";
import { store } from "../../redux/store";

export const clickTools = (dispatch: Dispatch, collection: string) => {
  return {
    active: true,
    enabled: true,
    key: "clickTools",
    handlers: {
      click: (e: any) => {
        const hits = e.hitNodes;
        window.player.selectionSet.setStyle({
          outlineColor: "#B57345",
          opacity: 1,
        });

        if (!hits.length) {
          window.player.selectionSet.clear();

          dispatch(addActiveModel({ id: "" }));
          dispatch(addActivePoint({ id: "" }));
          dispatch(setActivePillow(""));
          dispatch(addActiveThreeekitPillow({ id: "" }));
          return;
        }

        // let listKeyPoints = Object.keys(window.player.scene.getAll()).filter(item => item.includes('point_'));
        // let listPositive = listKeyPoints.filter(pointKey => pointKey.includes('positive'))
        // let listNegative = listKeyPoints.filter(pointKey => pointKey.includes('negative'))
        // // debugger
        const hierarchy = [...hits[0].hierarchy];
        hierarchy.reverse();

        let isCheckPoint = false;

        for (let node of hierarchy) {
          if (node.name.includes("Pillow")) {
            if (
              window.player.selectionSet.ids.length > 0 &&
              window.player.selectionSet.ids[0].includes("Model_")
            ) {
              window.player.selectionSet.clear();
            }
            dispatch(addActiveModel({ id: "" }));

            setTimeout(() => {
              window.player.selectionSet.setStyle({
                outlineColor: "#B57345",
                opacity: 1,
              });
            }, 10);
            dispatch(setActiveTab("pillows"));
            const activePillow = node.name.replace(/_/g, " ");
            dispatch(setActivePillow(activePillow));

            dispatch(addActiveThreeekitPillow({ id: node.name }));

            // window.player.selectionSet.ids отримуэмо айды подушки
            // window.playerT.api.scene.get({ id: 'f077d2dd-7fe8-4614-92b1-6430b513cb56', evalNode: true }) оттимуємо позицію подушки
            // window.playerT.configurator.appliedConfiguration дізнатися значення атрибутів

            // Отримуємо айді подушки
            // window.player.selectionSet.ids
            // Отримуємо ноду подушки по її ассет айді
            // window.playerT.api.scene.get({ from: 'a0210bf9-6f05-498f-b794-c25b99400da6' })
            // з обекта ноди для подушки беремо parent
            // parent - це і є айді нашого Layout Container на якому ми можемо торимати розміщенння наших подушок

            break;
          }

          if (node.name.includes("Model_")) {
            window.player.selectionSet.set(node.nodeId);
            dispatch(setActiveTab("create"));
            dispatch(setActivePillow(""));
            dispatch(addActiveThreeekitPillow({ id: "" }));
            dispatch(setActiveCategory(""));
            setTimeout(() => {
              dispatch(setActiveCategory("accordionFurnitureFabrics"));
            }, 100);

            dispatch(addActiveModel({ id: node.nodeId }));

            break;
          }

          if (node.name.includes("Base")) {
            window.player.selectionSet.set(node.nodeId);
            const minskyCategory: any = {
              Base: "accordionFurnitureFabrics",
              Base_2: "accordionFurnitureFabrics_2",
              Base_1: "accordionFurnitureFabrics_3",
            };
            dispatch(setActiveCategory(minskyCategory[node.name]));
            break;
          } else {
            dispatch(addActiveModel({ id: "" }));
          }
        }
      },
    },
  };
};
