import { TYPE_REDUCER } from "../../../utils/constants";
import { isSectionalConfigurator } from "../../../utils/supportUtils";
import { updateMaterialPillow } from "../../../utils/threekit/attributesFunctions";
import { store } from "../../store";
import { changeLoadPlayerState } from "../ui/uiSlice";
import { ThreekitState } from "./threekit.types";

const initialState: ThreekitState = {
  models: {},
  newPoints: {},
  activePoint: "",
  activeUserModel: "",
  activeModel: "",
  allModels: [],
  materials: {
    sofaMaterials: {
      activeMaterial: {
        name: "",
        assetId: "",
      },
      all: [],
    },
    pillowMaterials: {},
  },
  idConfiguration: "",
  buldingStatus: false,
  cameraPosition: { x: 0, y: 0, z: 0 },
  activePillow: "",
  pillows: [],
};

const Settings = (state = initialState, action: any): ThreekitState => {
  switch (action.type) {
    case TYPE_REDUCER.ADD_NEW_THREEKIT_STATE: {
      return { ...state, ...action.payload };
    }

    case TYPE_REDUCER.SET_LIST_MODEL_IN_REDAX: {
      const object = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          ...object,
        },

        activeModel: "",
        activePoint: "",
      };
    }

    case TYPE_REDUCER.SET_PRESET_MODELS_AND_PARAMS: {
      const { models, activeParamsThreekit } = action.payload;
      return {
        ...state,
        models: {
          ...state.models,
          ...models,
        },

        activeModel: "",
        activePoint: "",
      };
    }

    case TYPE_REDUCER.SET_NEW_POINTS: {
      const points = action.payload;
      return {
        ...state,
        newPoints: {
          ...state.newPoints,
          ...points,
        },

        activeModel: "",
        activePoint: "",
      };
    }

    case TYPE_REDUCER.SET_DELETE_POINTS_OR_MODELS: {
      const { points, models, differenceModels, differencePoints } =
        action.payload;

      const oldNewPoints = state.newPoints;
      const oldModels = state.models;

      let newPoints: any = {
        ...oldNewPoints,
        ...points,
      };
      let newModels: any = {
        ...oldModels,
        ...models,
      };
      if (differencePoints.length > 0) {
        delete newPoints[differencePoints[0]];
      }
      if (differenceModels.length > 0) {
        delete newModels[differenceModels[0]];
      }

      return {
        ...state,
        models: newModels,
        newPoints: newPoints,

        activeModel: "",
        activePoint: "",
      };
    }
    case TYPE_REDUCER.SET_UPDATE_POINTS_OR_MODELS: {
      const { points, models } = action.payload;
      return {
        ...state,
        newPoints: {
          ...state.newPoints,
          ...points,
        },
        models: {
          ...state.models,
          ...models,
        },
        activePoint: "",
        activeModel: "",
      };
    }

    case TYPE_REDUCER.SET_ACTIVE_MODEL_IN_STATE: {
      const { id } = action.payload;
      // debugger;
      return {
        ...state,
        activeModel: id,
      };
    }

    case TYPE_REDUCER.ADD_ACTIVE_POINT: {
      const { id } = action.payload;
      return {
        ...state,
        activePoint: id,
      };
    }
    case TYPE_REDUCER.SET_USER_MODEL: {
      const { id } = action.payload;
      return {
        ...state,
        activeUserModel: id,
      };
    }

    case TYPE_REDUCER.ADD_ALL_MODELS: {
      const { models } = action.payload;
      return {
        ...state,
        allModels: models,
      };
    }

    case TYPE_REDUCER.ADD_PILLOW_IN_STATE: {
      return {
        ...state,
        pillows: [...state.pillows, action.payload],
      };
    }

    case TYPE_REDUCER.UPDATE_PILLOW_IN_STATE: {
      const { id, newAttributes } = action.payload;
      let newArray: any = [];
      state.pillows.map((item: any) => {
        if (item.id === id) {
          newArray.push({
            ...item,
            ...newAttributes,
          });
        } else {
          newArray.push(item);
        }
      });

      newArray.sort((prev: any) => prev.indexPillow);
      return {
        ...state,
        pillows: newArray,
      };
    }

    case TYPE_REDUCER.SET_PILLOW_INFO_IN_STATE: {
      const { pillows } = action.payload;
      
      return {
        ...state,
        pillows,
      };
    }

    case TYPE_REDUCER.DELETE_ALL_PILLOWS_IN_STATE: {
      return {
        ...state,
        pillows: [],
      };
    }

    case TYPE_REDUCER.CHANGE_MATERIAL_PILLOW: {
      const { material, id } = action.payload;

      const findPillow = state.pillows.find((pillow) => pillow.id === id);
      const clearOldPillow = state.pillows.filter((pillow) => pillow.id != id);

      if (findPillow) {
        return {
          ...state,
          pillows: [...clearOldPillow, { ...findPillow, material }],
        };
      }

      return state;
    }

    case TYPE_REDUCER.ADD_ACTIVE_PILLOW: {
      const { id } = action.payload;

      return {
        ...state,
        activePillow: id,
      };
    }

    case TYPE_REDUCER.CHANGE_BULDING_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        buldingStatus: status,
      };
    }

    case TYPE_REDUCER.DELETE_ALL_MODELS: {
      return {
        ...state,
        models: {},
        newPoints: {},
      };
    }
    case TYPE_REDUCER.SET_INIT_STATE: {
      return { ...state, models: {}, newPoints: {} };
    }

    default:
      return state;
  }
};

export default Settings;
