import { getAttribute } from "../../utils/threekit/attributesFunctions";
import { modelSectionalT } from "../features/ui/ui.types";
import { RootState } from "../store";
import { getAllThreekitAtribute } from "./ui.selector";

export const getThreekitModels = (state: RootState) =>
  Object.values(state.threeKit.models);

export const getThreekitObjMaterials = (state: RootState) => {
  let allThreekitAtributes = state.ui.allThreekitAtributes;
  let objectActiveParams = state.ui.configUI.objectActiveParams;

  let objectDependensThreekitRelativeUI =
    state.ui.configUI.objectDependensThreekitRelativeUI;

  let showPillowMaterials = state.threeKit.materials.pillowMaterials;

  let objList = {};

  Object.keys(allThreekitAtributes).map((item) => {
    let value;
    let isAsset = allThreekitAtributes[item].type === "Asset";
    let nameCategory = allThreekitAtributes[item].name;
    let currentNameThrekitCategory =
      objectDependensThreekitRelativeUI[nameCategory];
    const attributeType = allThreekitAtributes[item].type;

    currentNameThrekitCategory &&
      currentNameThrekitCategory.map((category: any) => {
        const activeValue = objectActiveParams[category];
        if (activeValue) {
          switch (attributeType) {
            case "Asset":
              allThreekitAtributes[item].values.map((valueItem: any) => {
                if (valueItem.name === activeValue) {
                  value = { assetId: valueItem.assetId };
                }
              });
              break;
            case "String":
            case "Boolean":
              value = activeValue;
              break;
            case "Array":
              const arrAttributeValuesCurrent = getAttribute(nameCategory);
              value = !arrAttributeValuesCurrent
                ? []
                : [...arrAttributeValuesCurrent];
              break;
            default:
              value = null;
          }
          // allThreekitAtributes[item].values.map((valueItem: any) => {
          //   const itemValue = isAsset ? valueItem.name : valueItem.value
          //   if (itemValue === activeValue) {
          //     value = isAsset ? { assetId: valueItem.assetId } : itemValue;
          //   }
          // });
        }
      });

    objList = {
      ...objList,
      [item]: value,
    };
  });

  // Object.keys(showPillowMaterials).map((item) => {
  //   let assetId = showPillowMaterials[item];
  //   objList = {
  //     ...objList,
  //     [item]: { assetId },
  //   };
  // });

  let objSeting = objList;
  // debugger;
  return objSeting;
};

export const getThreekitDefaultValues = (allThreekitAtributes: any) => {
  const defautValuesThreekit = Object.keys(allThreekitAtributes).reduce(
    (accumulator: any, nameAttribute: string) => {
      if (!allThreekitAtributes[nameAttribute].hasOwnProperty("defaultValue"))
        return { ...accumulator };

      const defaultValue = allThreekitAtributes[nameAttribute]["defaultValue"];

      return { ...accumulator, [nameAttribute]: defaultValue };
    },
    {}
  );

  return defautValuesThreekit;
};

export const getObjForUpdateThreekitFromNameParams = (
  allThreekitAtributes: any,
  objNameParams: modelSectionalT
) => {
  if (!!!objNameParams) return null;

  let objList = {};

  Object.keys(allThreekitAtributes).map((item) => {
    let value;
    const isAsset = allThreekitAtributes[item].type === "Asset";
    const nameCategory = allThreekitAtributes[item]["name"];
    const activeValue = objNameParams[nameCategory];
    const values = allThreekitAtributes[item]["values"];

    if (!!!activeValue) return;

    values.map((valueItem: any) => {
      const itemValue = isAsset ? valueItem.name : valueItem.value;
      if (itemValue === activeValue) {
        value = isAsset ? { assetId: valueItem.assetId } : itemValue;
      }
    });

    objList = {
      ...objList,
      [item]: value,
    };
  });

  return objList;
};

export const getShowPillowsMaterial = (state: RootState) =>
  state.threeKit.materials.pillowMaterials;

export const getPillows = (state: RootState) => state.threeKit.pillows;

export const getPillowById = (state: RootState) => (id: string) => {
  const pillows = state.threeKit.pillows;
  const pillow = pillows.find((item: any) => item.id === id);

  return pillow;
};
export const getThreekitActivePillow = (state: RootState) =>
  state.threeKit.activePillow;

export const getPillowsPrice = (state: RootState) => {
  const pillows = state.threeKit.pillows;
  let summPrice = 0;

  if (pillows.length >= 1) {
    pillows.map((item: any) => {
      summPrice += item.price;
    });
  }
  return summPrice;
};
