import { getThreekitModels } from "../../redux/selectors/threekit.selectors";
import { isSectionConfiguratorUI } from "../../redux/selectors/ui.selector";
import { store } from "../../redux/store";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { getDisplayAttributes, setAttributes } from "../../utils/threekit/attributesFunctions"
import { calculateSofaDimensions } from "./calculateSofaDimensions";

export const dimentionsSetup = () => {
    const state = JSON.parse(JSON.stringify(store.getState()));
    const isSectional =
        isSectionalConfigurator() || isSectionConfiguratorUI(state);
    if (isSectional) {
        const updatedAttributes = getDisplayAttributes()
        const listModels = getThreekitModels(state);
        const modelsData: any = []

        const armValues: any = [
            {
                name: "widthTrack",
                assetIds: ["0bdfa97f-a0b0-46b1-b245-c6f003578178", "d11f06ea-7352-47d4-84e4-bd0b2897605b"]
            },
            {
                name: "widthRoll",
                assetIds: ["3c6eb222-a11a-4b03-a539-d6c5f76c3b88", "404aa43f-5ae4-4ad0-b678-288455fbedc3"]
            },
            {
                name: "widthFlare",
                assetIds: ["f223549b-a6bc-41ab-83a5-4567e82d2709", "0350e4ce-fea3-4f20-b4e3-088df09e1f7a"]
            },
        ]
        listModels.map((model: any) => {
            const models = updatedAttributes.find((item: any) => item.name === "Models");
            const modelItem = models.values.find((item: any) => item.assetId === model.assetId);
            const arms = updatedAttributes.find((item: any) => item.name === "Arm selector");
            const depths = updatedAttributes.find((item: any) => item.name === "Seat depth");

            const activeArm = arms.values.find((item: any) => item.assetId === arms.value.assetId)
            const activeDepth = depths.values.find((item: any) => item.value === depths.value)
            const type = modelItem.metadata.Type;


            const widthMetadataKey = armValues.find((item: any) => item.assetIds.includes(activeArm.assetId)).name
            const heightMetadataKey = "height"
            const depthMetadataKey = activeDepth.value === "Classic" ? "depthClassic" : "depthLounge"

            const widthMetadata = type === "Corner" ? modelItem.metadata[depthMetadataKey] : modelItem.metadata[widthMetadataKey];
            const heightMetadata = modelItem.metadata[heightMetadataKey];
            const depthMetadata = modelItem.metadata[depthMetadataKey];
            modelsData.push({
                name: model.name,
                type: type === "Corner" ? "corner" : "basic",
                angle: model.angle,
                width: Number(widthMetadata),
                height: Number(heightMetadata),
                depth: Number(depthMetadata),
            })
        })

        console.log('modelsData: ', modelsData);
        const { width, height, depth } = calculateSofaDimensions(modelsData)
        setAttributes("widthDimentions", `${width}`)
        setAttributes("heightDimentions", `${height}`)
        setAttributes("depthDimentions", `${depth}`)
    }
}