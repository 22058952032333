import { number } from "yup";

let listObjectSideB: { [key: string]: any } = {
  Windows_A: "front_model_",
  Windows_C: "back_model_",
  Windows_B: "right_model_",
  Windows_D: "left_model_",
  Door_A: "door_front_model_",
  Door_C: "door_back_model_1",
  Door_B: "door_right_model_",
  Door_D: "door_left_model_",
};

export type coordinates = "x" | "y" | "z";
export type coordinatesObject = { [key in coordinates]: number };

export type transformObject = "transition" | "rotation";
export type transformType = { [key in transformObject]: coordinatesObject };

export type objectLocationsType = transformType;

export const setTransitionObject = (
  instanceId: string,
  nameMesh: string,
  object: any,
  api: any
): coordinatesObject =>
  api.scene.set(
    {
      from: instanceId,
      name: nameMesh,
      plug: "Transform",
      property: "translation",
    },
    object
  );
export const setRotationObject = (
  instanceId: string,
  nameMesh: string,
  object: any,
  api: any
): coordinatesObject =>
  api.scene.set(
    {
      from: instanceId,
      name: nameMesh,
      plug: "Transform",
      property: "rotation",
    },
    object
  );

const getNameObject = (assetId: any) => {
  let node = window.playerT.api.scene.get({
    from: window.playerT.api.instanceId,
    id: assetId,
  });
  return node["name"];
};
const getInstanceId = async () => {
  return await window.playerT.api.player.getAssetInstance({
    from: window.playerT.api.scene.findNode({ name: "drag and drop object" }),
    name: "add drag and drop object",
    plug: "Null",
    property: "asset",
  });
};
const getInstanceIdSet = async (api: any) => {
  return await api.player.getAssetInstance({
    from: api.scene.findNode({ name: "drag and drop object" }),
    name: "add drag and drop object",
    plug: "Null",
    property: "asset",
  });
};

const getTransitionObject = (
  namePoint: string,
  instanceId: string
): coordinatesObject => {
  return window.playerT.api.scene.get({
    from: instanceId,
    name: namePoint,
    plug: "Transform",
    property: "translation",
  });
};
const getRotationObject = (
  namePoint: string,
  instanceId: string
): coordinatesObject => {
  return window.playerT.api.scene.get({
    from: instanceId,
    name: namePoint,
    plug: "Transform",
    property: "rotation",
  });
};

type ObjectLocationT = {
  [key: string]: {
    [key: string]: objectLocationsType;
  };
};

export const getAllObjectLocations = async (
  listProperty: string[],
  positionKeyDelete: number[],
  listObject: any
) => {
  // async function checkNode() {
  //   //@ts-ignore
  //   const id = playerT.configurator.appliedConfiguration["setPillows"];
  //   id.map((idItem: any) => {
  //     //@ts-ignore
  //     const test = playerT.api.scene.get({
  //       id: idItem,
  //       evalNode: true,
  //     });
  //   });
  // }
  // checkNode();
  // test = window.player.scene.get({ id: 'pillow 1'})
  // let t = window.playerT.api.scene.get({from: '8425e810-e5c6-4e0b-bdf3-5c804a2d1e8a'})

  const instanceIdSelectedPillow: string = window.player.selectionSet.ids[0];
  const instanceIdLayoutContainer = window.playerT.api.scene.get({
    from: instanceIdSelectedPillow,
  })["parent"];
  const maxCountPillows = window.playerT.api.scene.get({
    from: instanceIdLayoutContainer,
  })["children"].length;

  const countObject = listProperty.length;
  let listArray: any[] = [];

  for (let index = 1; index <= countObject; index++) listArray.push(index);

  let objectMeshPoint = {};

  let objEmptyPositions: { [key in number]: number } = {};

  const getPositionReconciliationIndex = (
    positionKeyDelete: number[],
    index: number
  ): any => {
    if (positionKeyDelete.length < 1) return index;

    let positionReconciliationIndex = 0;
    let newIndex = index;
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex === newIndex) {
        if (keyIndex === listProperty.length) {
          objEmptyPositions[keyIndex] = keyIndex;
        } else {
          objEmptyPositions[maxCountPillows - positionReconciliationIndex] =
            newIndex;
        }
      }
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });
    positionKeyDelete.forEach((keyIndex) => {
      if (keyIndex <= newIndex) {
        positionReconciliationIndex = positionKeyDelete.indexOf(keyIndex) + 1;
        newIndex = index + positionReconciliationIndex;
      }
    });

    newIndex = index + positionReconciliationIndex;

    if (Object.keys(objEmptyPositions).includes(String(index))) {
      return objEmptyPositions[index];
    }

    return newIndex;
  };

  let nameMesh = "pillow ";
  listArray.forEach((indexNum: number, index: number) => {
    let pointIndexNumberForUpdateCoordinate = getPositionReconciliationIndex(
      positionKeyDelete,
      indexNum
    );
    let namePointMesh = `${nameMesh}${indexNum}`;
    let pointForUpdateCoordinate = `${nameMesh}${pointIndexNumberForUpdateCoordinate}`;
    objectMeshPoint = {
      ...objectMeshPoint,
      [namePointMesh]: {
        sequenceNumber: indexNum,
        pointForUpdateCoordinate: pointForUpdateCoordinate,
        rotation: getRotationObject(
          pointForUpdateCoordinate,
          instanceIdLayoutContainer
        ),
        transition: getTransitionObject(
          pointForUpdateCoordinate,
          instanceIdLayoutContainer
        ),
      },
    };
  });

  return { objectMeshPoint, instanceIdLayoutContainer };
};

export const getAllObjectLocationDormer = async (
  symbolSide: string,
  positionKeyDelete: number[],
  listObject: any
) => {
  const instanceId: string = await window.playerT.api.player.getAssetInstance({
    from: window.playerT.api.scene.findNode({ name: "Dormer" }),
    name: "add_Dormer",
    plug: "Null",
    property: "asset",
  });

  const getNameMesh = (sequenceNumber: number) =>
    `point_${sequenceNumber}_dormer${symbolSide}`;

  const countObject = listObject.length;

  let listArray: any[] = [];

  for (let index = 0; index <= countObject; index++) listArray.push(index);

  let objectMeshPoint = {};

  let positionReconciliationIndex = 0;

  const getPositionReconciliationIndex = (
    positionReconciliationIndex: number,
    positionKeyDelete: number[],
    index: number
  ) => {
    let nextIndex = index + positionReconciliationIndex;
    if (
      nextIndex >= positionKeyDelete[positionReconciliationIndex] &&
      positionReconciliationIndex <= positionKeyDelete.length - 1
    ) {
      positionReconciliationIndex = positionReconciliationIndex + 1;
    }
    nextIndex = index + positionReconciliationIndex;
    return nextIndex;
  };

  listArray.forEach((_: number, index: number) => {
    let newIndex = index + 1;
    let pointIndexNumberForUpdateCoordinate = getPositionReconciliationIndex(
      positionReconciliationIndex,
      positionKeyDelete,
      newIndex
    );

    let namePointMesh = getNameMesh(newIndex);
    let pointForUpdateCoordinate = getNameMesh(
      pointIndexNumberForUpdateCoordinate
    );
    objectMeshPoint = {
      ...objectMeshPoint,
      [namePointMesh]: {
        sequenceNumber: index,
        pointForUpdateCoordinate: pointForUpdateCoordinate,
        rotation: getRotationObject(pointForUpdateCoordinate, instanceId),
        transition: getTransitionObject(pointForUpdateCoordinate, instanceId),
      },
    };
  });

  return { objectMeshPoint, instanceId };
};

export const setObjectLocation = async (
  objectLocation: ObjectLocationT,
  api: any
) => {
  const instanceId: string = await getInstanceIdSet(api);
  const instanceIdDormer = await api.player.getAssetInstance({
    from: api.scene.findNode({ name: "Dormer" }),
    name: "add_Dormer",
    plug: "Null",
    property: "asset",
  });
  const instanceIdCupola = await api.player.getAssetInstance({
    from: api.scene.findNode({ name: "Cupol" }),
    name: "Cupola_config",
    plug: "Null",
    property: "asset",
  });

  Object.keys(objectLocation).forEach((propertyName) => {
    let instanceScript = instanceId;

    if (propertyName.includes("CupolaUpdate")) {
      instanceScript = instanceIdCupola;
    } else if (propertyName.includes("Dormer_")) {
      instanceScript = instanceIdDormer;
    }

    const property = objectLocation[propertyName];
    Object.keys(property).forEach((namePointInModel) => {
      const rotationObject = property[namePointInModel]["rotation"];
      const transitionObject = property[namePointInModel]["transition"];

      setTransitionObject(
        instanceScript,
        namePointInModel,
        transitionObject,
        api
      );
      setRotationObject(instanceScript, namePointInModel, rotationObject, api);
    });
  });
};
