import React from 'react'
import { SendIcon } from '../../../../assets/svgCompopnent/SendIcon'
import s from './SendButton.module.scss'


export const SendButton = ({ sendText, onClick, className }: any) => {
    return (
        <button
            type="submit"
            className={`${s.btnSend}  ${s[sendText]} ${className}   `}
            onClick={onClick}
        >
            <SendIcon />
            <span>{sendText}</span>
        </button>
    )
}
