export const getImageSrcForStandartOption = (
  parentId: string,

  optionTitle: string,

  activeTab: string,
  pathname: string,
  nameImageForHeadboard?: string | null
) => {
  let imageSrc: any;

  if (activeTab === "Headboards Only" && pathname.split("/").length === 3) {
    optionTitle += `_headboards`;
  }

  try {
    if (nameImageForHeadboard === null) {
      try {
        imageSrc = `/images/assets/CollectionImages/${parentId}/${optionTitle}.png`;
      } catch (err) {
        imageSrc = `/images/assets/CollectionImages/${parentId}/${optionTitle}.svg`;
      }
    } else {
      try {
        imageSrc = `/images/assets/CollectionImages/${parentId}/${nameImageForHeadboard}.png`;
      } catch (err) {
        imageSrc = `/images/assets/CollectionImages/${parentId}/${nameImageForHeadboard}.svg`;
      }
    }
  } catch (err) {
    imageSrc = `/images/assets/CollectionImages/no-image.png`;
  }

  if (parentId === "pillows") {
    const updatedOptionTitle = optionTitle
      .split('"')
      .join("")
      .split("×")
      .join("x");
    try {
      imageSrc = `/images/assets/CollectionImages/${parentId}/${updatedOptionTitle}.png`;
    } catch (err) {
      imageSrc = `/images/assets/CollectionImages/no-image.png`;
    }
  }

  return imageSrc;
};

export const getImageSrcForImgRadio = (
  parentId: string,
  optionTitle: string,
  nameImageForHeadboard?: string | null
) => {
  let imageSrc: any;
  const isFabric = parentId === "fabric_2" || parentId === "fabric_3";
  const domain =
    window.location.origin === "http://localhost:3000"
      ? "/"
      : process.env.REACT_APP_DOMAIN_URL;

  if (parentId === "PillowMaterial" || isFabric) {
    parentId = "fabric";
  }

  try {
    imageSrc = `${window.location.origin}/images/assets/CollectionImages/${parentId}/${optionTitle}.png`;
  } catch (err) {
    imageSrc = `${window.location.origin}/images/assets/CollectionImages/no-image.png`;
  }

  return imageSrc;
};
