import axios from "axios";
import prntr from "prntr";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getParamsForApiPriceInfo } from "../../../api";
import { SavedConfiguration } from "../../../api/api";
import { instancePDFFunc } from "../../../api/instanceApi";
import { Close } from "../../../assets/svgCompopnent/Close";
import { Print } from "../../../assets/svgCompopnent/Print";
import { addActiveModel, addActiveThreeekitPillow } from "../../../redux/actions/player.action";
import { changeLoadPlayerState, openCloseModals, setActivePillow } from "../../../redux/features/ui/uiSlice";
import { getPointsShow } from "../../../redux/selectors/points.selector";
import { getPillows, getPillowsPrice, getThreekitModels } from "../../../redux/selectors/threekit.selectors";
import {
  checkIsHidePrice,
  getActiveCollectionId,
  getAllThreekitAtribute,
  getInfoModelsPrice,
  getIsInStore,
  getModalInfo,
  getModelsPrice,
  getObjActiveParamsUI,
} from "../../../redux/selectors/ui.selector";
import { getChectIsUserRoolGuest } from "../../../redux/selectors/users/selectore";
import { store } from "../../../redux/store";
import { THREEKIT_PARAMS } from "../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { getActualLinkForSaveConfigurator, isSectionalConfigurator } from "../../../utils/supportUtils";
import { getDisplayAttributes, getObjectUniqueMaterialModels } from "../../../utils/threekit/attributesFunctions";
import { setModelVisibility } from "../../../utils/threekit/threekitFunc";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import { configurationParams } from "./configurationParams";
import s from "./PrintInfo.module.scss";

export type infoUserT = {
  email: string[];
  name: string;
};

export async function getSnapshot() {
  window.player.camera.frameBoundingSphere();
  const state = store.getState();
  let pointsShow = await getPointsShow(state);
  window.player.selectionSet.clear();
  store.dispatch(addActiveModel({ id: "" }));
  store.dispatch(setActivePillow(""));
  store.dispatch(addActiveThreeekitPillow({ id: "" }));

  pointsShow["showPoint"].forEach((point) => {
    if (point) {
      setModelVisibility(point, false);
    }
  });
  pointsShow["listNotVisible"].forEach((point) => {
    if (point) {
      setModelVisibility(point, false);
    }
  });
  const base64_small = await window.player.snapshotAsync({
    size: { width: 1000, height: 800 },
  });
  pointsShow["showPoint"].forEach((point) => {
    if (point) {
      setModelVisibility(point, true);
    }
  });
  pointsShow["listNotVisible"].forEach((point) => {
    if (point) {
      setModelVisibility(point, true);
    }
  });

  return base64_small;
}

export default function ModalPrintInfo() {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) => getModalInfo(state)("PrintInfo"));
  const inStore = useAppSelector(getIsInStore);
  const isHidePrice = useAppSelector((state) => checkIsHidePrice(state));
  const { hash, key, pathname, search, state } = useLocation();
  const priceModels = useAppSelector((state) => getModelsPrice(state));
  const pricePillows = useAppSelector((state) => getPillowsPrice(state));
  const pillows = useAppSelector((state) => getPillows(state));
  const modelsUI = useAppSelector((state) => state.ui.models);
  const threeKit = useAppSelector((state) => state.threeKit);
  const chectIsUserRoolGuest = useAppSelector(getChectIsUserRoolGuest);

  const infoModelsPrice = useAppSelector((state) => getInfoModelsPrice(state));
  const objActiveParamsUI = useAppSelector((state) => getObjActiveParamsUI(state));
  const [imgConfig, setImgConfig]: any = useState();
  const [products, setProducts]: any = useState([]);
  let allThreekitAtribute = useAppSelector((state) => getAllThreekitAtribute(state));

  useEffect(() => {
    let checkUndefinedInSku = false;
    Object.keys(infoModelsPrice).forEach((key) => {
      if (infoModelsPrice[key].skuShort.includes("undefined")) {
        checkUndefinedInSku = true;
      }
    });

    if (isOpen && !checkUndefinedInSku) {
      openModalInfo(infoModelsPrice);
    }
  }, [infoModelsPrice]);

  useEffect(() => {
    let checkUndefinedInSku = false;
    Object.keys(infoModelsPrice).forEach((key) => {
      if (infoModelsPrice[key].skuShort.includes("undefined")) {
        checkUndefinedInSku = true;
      }
    });

    if (isOpen && !checkUndefinedInSku) {
      openModalInfo(infoModelsPrice);
    }
  }, [isOpen]);
  // const models = getModels(state);
  const threekitModels = useAppSelector(getThreekitModels);
  const objActiveParams = useAppSelector(getObjActiveParamsUI);
  const activeCollection = useAppSelector(getActiveCollectionId);
  const requestData = getParamsForApiPriceInfo(activeCollection, objActiveParams);

  const getShortId = async () => {
    if (isSectionalConfigurator()) {
      await getObjectUniqueMaterialModels(store.getState()).then((materialModels) => {
        allThreekitAtribute = materialModels;
      });
    }

    let configThreekit = {
      authToken: THREEKIT_PARAMS.authToken,
      orgId: THREEKIT_PARAMS.orgId,
      threekitUrl: THREEKIT_PARAMS.threekitUrl,
      metadata: {
        objectActiveParams: objActiveParamsUI,
        allThreekitAtributes: allThreekitAtribute,
        models: modelsUI,
        pillows: pillows,
        threeKit: threeKit,
        // allError: allError,
        // objectsPosition: objectsPosition,
        // positionObjectSide: await getObjectLocations()
      },
    };

    return Promise.all([SavedConfiguration(configThreekit)])
      .then((threekitConfig: any) => {
        const url = getActualLinkForSaveConfigurator();

        const linkText = `${url}?shortId=${threekitConfig[0]["shortId"]}`;
        // onSaveConfig(uuidConfig, arrayPropsSaveQuoteConfig, modelId, threekitConfig['shortId'])
        // dispatch(setLoadingConf({ nameLoader: 'loaderConfig', valueLoader: false }));

        return Promise.all([linkText]);
      })
      .catch((error) => {});
  };

  async function returnOrderInfo() {
    dispatch(openCloseModals({ name: "PrintInfo", data: { linkText: "linkText" } }));
    dispatch(changeLoadPlayerState(true));
    let shortId = getShortId();

    const allmaterial: any = getDisplayAttributes().find((e: any) => e.name === "Materials");
    let actualFabricItem = allmaterial.values.find((e: any) => e.name === objActiveParams.fabric);
    const actualFabricSku = actualFabricItem.metadata.sku;
    let products: any = [];
    function getPillowsArray() {
      const array: any = [];
      if (pillows.length >= 1) {
        const obgPillows: any = {};
        pillows.map((item: any, index) => {
          const key = `${item.name} ${item.skuMaterial}`;
          if (obgPillows[key]) {
            obgPillows[key] = {
              ...obgPillows[key],
              count: obgPillows[key].count + 1,
            };
          } else {
            obgPillows[key] = {
              sku: item.skuFull,
              material: item.skuMaterial,
              count: 1,
            };
          }
        });
        Object.keys(obgPillows).forEach((key) => {
          array.push({
            sku: obgPillows[key].sku,
            quantity: obgPillows[key].count,
            selectedFabric: obgPillows[key].material,
          });
        });
      }
      return array;
    }

    if (isSectionalConfigurator()) {
      const accessoryProducts = getPillowsArray();
      const allModels: any = {};

      threekitModels.map((item: any, index: number) => {
        if (index === 0 && pillows.length >= 1) {
          allModels[`${item.name}_first_item`] = {
            sku: infoModelsPrice[item.name].skuFull,
            selectedFabric: actualFabricSku,
            quantity: 1,
            accessoryProducts: accessoryProducts,
          };
        } else if (allModels[item.name]) {
          allModels[item.name].quantity = allModels[item.name].quantity + 1;
        } else {
          allModels[item.name] = {
            sku: infoModelsPrice[item.name].skuFull,
            selectedFabric: actualFabricSku,
            quantity: 1,
            accessoryProducts: [],
          };
        }
      });

      Object.keys(allModels).forEach((key) => {
        products.push({
          sku: allModels[key].sku,
          selectedFabric: allModels[key].selectedFabric,
          quantity: allModels[key].quantity,
          accessoryProducts: allModels[key].accessoryProducts,
        });
      });
    } else {
      const key: any = Object.keys(infoModelsPrice);
      const accessoryProducts = getPillowsArray();

      products.push({
        sku: infoModelsPrice[key]?.skuFull,
        selectedFabric: actualFabricSku,
        quantity: 1,
        accessoryProducts: accessoryProducts,
      });
    }

    Promise.all([shortId]).then((values: any) => {
      console.log("values: ", values);
      const short = values[0][0];
      const cartInfo = {
        products: products,

        shortCode: short,
        renderImgUrl: imgConfig,
        isActive: true,
      };
      console.log("cartInfo: ", cartInfo);

      window.parent.postMessage(
        {
          event_id: "returnCartInfo",
          cartInfo: cartInfo,
        },
        "*" //or "www.parentpage.com"
      );
      // dispatch(
      //   updateCartInfo({
      //     isActive: false,
      //   })
      // );
      // dispatch(
      //   updateCartInfo({
      //     products: products,
      //     shortCode: short,
      //     renderImgUrl: imgConfig,
      //     isActive: true,
      //   })
      // );
      // dispatch(changeLoadPlayerState(false));
    });
  }

  async function generatePDF() {
    const configurationInfo = configurationParams(requestData, objActiveParamsUI.activeTab);

    const formData = {
      image: imgConfig,
      products: products,
      price: priceModels + pricePillows,
      isHidePrice,
      requestParamData: configurationInfo,
    };
    const instanceApi = instancePDFFunc({
      authToken: process.env.REACT_APP_API_TOKEN || "",
      urlApi: process.env.REACT_APP_API_URL || "/api",
    });

    const token = "dop_v1_c193ff151ae0bdaf01c3f0d624292023ab557bbebe0c4ea799215cff7fc1f882";

    axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
          // Authorization: authToken,
        },
        validateStatus: function (status) {
          return status >= 200 && status < 300; // default
        },
      })
      .post(`${process.env.REACT_APP_API_URL}/generatePDF`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Credentials": "true",
          // "Access-Control-Max-Age": "1800",
          // "Access-Control-Allow-Headers": "content-type",
          // "Access-Control-Allow-Methods":
          //   "PUT, POST, GET, DELETE, PATCH, OPTIONS",
          "Content-Type": "application/json",
        },
      })
      .then(async (props: any) => {
        const blob = await new Blob([props.data], {
          type: "application/pdf",
        });
        const url = await window.URL.createObjectURL(blob);

        prntr({
          printable: url,
          type: "pdf",
        });
      });
  }

  async function openModalInfo(actualPrice: any) {
    let allProducts: any = {};
    let array: any = [];
    const img = await getSnapshot();
    setImgConfig(img);

    if (isSectionalConfigurator()) {
      threekitModels.map((item: any) => {
        if (allProducts[item.name]) {
          allProducts[item.name].count = allProducts[item.name].count + 1;
        } else {
          const name = item.name;
          const price = actualPrice[item.name].price;

          const sku = actualPrice[item.name].skuFull;
          const count = 1;
          allProducts[item.name] = {
            name,
            price,
            sku,
            count,
            hidePillowMaterial: true,
          };
        }
      });
      Object.keys(allProducts).forEach((key) => {
        array.push(allProducts[key]);
      });
    } else {
      Object.keys(actualPrice).forEach((key) => {
        let name = key;
        if (objActiveParamsUI.activeTab === "Headboards Only") {
          name = objActiveParamsUI.activeTab;
        }

        array.push({
          name: name,
          price: actualPrice[key].price,
          sku: actualPrice[key].skuFull,
          count: 1,
          hidePillowMaterial: true,
        });
      });
    }

    if (pillows.length >= 1) {
      const obgPillows: any = {};

      pillows.map((item: any, index) => {
        if (obgPillows[`${item.name}_${item.material}`]) {
          obgPillows[`${item.name}_${item.material}`] = {
            ...obgPillows[item.name],
            price: obgPillows[item.name].price + item.price,
            count: obgPillows[item.name].count + 1,
          };
        } else {
          obgPillows[`${item.name}_${item.material}`] = {
            name: item.name,
            price: item.price,
            sku: item.skuFull,
            material: ucFirst(item.material.replace("_", " ").replace("_", " ").replace("_", " ").toLowerCase()),
            hidePillowMaterial: false,
            count: 1,
          };
        }
      });
      Object.keys(obgPillows).forEach((key) => {
        array.push({
          name: obgPillows[key].name,
          price: obgPillows[key].price,
          sku: obgPillows[key].sku,
          count: obgPillows[key].count,
          material: obgPillows[key].material,
          hidePillowMaterial: obgPillows[key].hidePillowMaterial,
        });
      });
    }

    setProducts(array);
  }

  function ucFirst(str: any) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  }

  const configurationInfo = configurationParams(requestData, objActiveParamsUI.activeTab);

  return (
    <div>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.modalSaveConfig}>
            <div className={s.close} onClick={() => dispatch(openCloseModals({ name: "PrintInfo" }))}>
              <Close />
            </div>
            <div className={s.logo}>
              <img src="/images/assets/MainLogo.png" alt="" />
            </div>
            <h2 className={s.title}>Mix it up. Make it yours.</h2>
            <div className={s.image__wrapper}>
              <img src={imgConfig} alt="" className={s.image} />
            </div>
            <div className={s.table_scroll}>
              <div className={s.table__block}>
                <div className={`${s.table_info} ${s.origin}`}>
                  <div className={`${s.table_title} ${s.mobile}`} style={{ width: "30%", textAlign: "left" }}>
                    Specs
                  </div>
                  <div className={s.table_title} style={{ width: "30%", textAlign: "left" }}>
                    SKU
                  </div>
                  <div className={s.table_title} style={{ width: "40%", textAlign: "left" }}>
                    Description
                  </div>
                  <div className={s.table_title} style={{ width: "15%", textAlign: "right" }}>
                    Quantity
                  </div>
                  {!isHidePrice && (
                    <div className={s.table_title} style={{ width: "15%", textAlign: "right" }}>
                      Unit price
                    </div>
                  )}
                </div>
                {products.length >= 1 &&
                  products.map((item: any, index: number) => {
                    return (
                      <div className={s.table_item} key={`${item.sku}+${index}`}>
                        <div className={`${s.table_text} ${s.sku}`} style={{ width: "30%", textAlign: "left" }}>
                          {item.sku}
                        </div>
                        <div className={`${s.table_text} ${s.name}`} style={{ width: "40%", textAlign: "left" }}>
                          <span>{item.name}</span>
                          {item.material && <span className={s.table_material}>Fabric: {item.material}</span>}
                        </div>
                        <div className={`${s.table_text} ${s.count}`} style={{ width: "15%", textAlign: "right" }}>
                          x{item.count}
                        </div>
                        {!isHidePrice && (
                          <div className={`${s.table_text} ${s.price}`} style={{ width: "15%", textAlign: "right" }}>
                            ${item.price}
                          </div>
                        )}
                      </div>
                    );
                  })}
                <div className={`${s.table_info} ${s.additional}`}>
                  {configurationInfo &&
                    configurationInfo.map((item: any) => {
                      if (item.value) {
                        return (
                          <div
                            key={`${item.value}-${item.name}`}
                            className={s.table_title}
                            style={{
                              width: `calc(100% / ${configurationInfo.length})`,
                              textAlign: "center",
                            }}
                          >
                            {item.name}
                          </div>
                        );
                      }
                    })}
                </div>
                <div className={`${s.table_item} ${s.additional}`}>
                  {configurationInfo &&
                    configurationInfo.map((item: any) => {
                      if (item.value) {
                        return (
                          <div
                            key={`${item.value}-${item.name}`}
                            className={`${s.table_text} ${s[item.name]}`}
                            style={{
                              width: `calc(100% / ${configurationInfo.length})`,
                              textAlign: "center",
                            }}
                          >
                            {/* <span>{item.name}: </span> */}
                            {item.value}
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <div className={s.info__block}>
              <div className={s.dimensions}>
                {/* <div className={s.dimensions_title}>Dimensions</div>
                <div className={s.dimensions_info}>112”x35”x143”</div> */}
              </div>
              <div className={s.buttons__block}>
                {!isHidePrice && !chectIsUserRoolGuest && (
                  <div className={s.price__block}>
                    <div className={s.price_title}>Total</div>
                    <div className={s.price}>${priceModels + pricePillows}</div>
                  </div>
                )}

                <div className={s.print__block} onClick={() => generatePDF()}>
                  <Print />
                  <span>Print</span>
                </div>

                {!chectIsUserRoolGuest && inStore !== true && (
                  <div className={s.addToCart} onClick={() => returnOrderInfo()}>
                    <img src="/images/assets/svg/Shop.svg" alt="" />
                    <span>Add to Cart</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalsWrapper>
      )}
    </div>
  );
}
