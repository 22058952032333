export const TutorialBtn = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="11"
        transform="rotate(-180 11 11)"
        fill="#B57345"
      />
      <path
        d="M13.5 6L8.5 11L13.5 16"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
