import {
  getParametersModelPositionT,
  objectModelT,
  transformType,
  typeModel,
} from "../../redux/types/typeModels";
import { getPositionModificationPointT } from "../../redux/types/typeThreekitStateModal";

type getStartPointXZT = {
  xStartPosition: number;
  zStartPosition: number;
};
const getStartPointXZ = (transform: transformType): getStartPointXZT => {
  const xStartPosition = transform["translation"]["x"];
  const zStartPosition = transform["translation"]["z"];

  return { xStartPosition, zStartPosition };
};

type getCreateNewPositionObjectT = {
  xPosition: number;
  zPosition: number;
};
type getCreateNewPositionModelT = {
  angle: number;
  xStartPosition: number;
  zStartPosition: number;
  parametersPrevModelPosition: getParametersModelPositionT;
  parametersNextModelPosition: getParametersModelPositionT;
};
const getCreateNewPositionModelRelativePrevLineModel = ({
  angle,
  xStartPosition,
  zStartPosition,
  parametersPrevModelPosition,
  parametersNextModelPosition,
}: getCreateNewPositionModelT): getCreateNewPositionObjectT => {
  let lengthPrevModel: number =
    parametersPrevModelPosition["segmentsModel"]["OMaxX"];
  let lengthNextModel: number =
    parametersNextModelPosition["segmentsModel"]["OMinX"];
  let PrevOMinZ: number = parametersPrevModelPosition["segmentsModel"]["OMinZ"];
  let CurrentOMinZ: number =
    parametersNextModelPosition["segmentsModel"]["OMinZ"];

  let newXPosition: number = xStartPosition;
  let newZPosition: number = zStartPosition;

  let lastBackPoint = zStartPosition;

  switch (angle) {
    case 0:
      lastBackPoint = zStartPosition - PrevOMinZ;
      newZPosition = lastBackPoint + CurrentOMinZ;

      newXPosition = xStartPosition + lengthPrevModel + lengthNextModel;

      break;
    case -180:
      lastBackPoint = newZPosition + PrevOMinZ;
      newZPosition = lastBackPoint - CurrentOMinZ;

      newXPosition = xStartPosition - lengthPrevModel - lengthNextModel;
      break;
    case -90:
      lastBackPoint = xStartPosition + PrevOMinZ;
      newXPosition = lastBackPoint - CurrentOMinZ;

      newZPosition = zStartPosition + lengthPrevModel + lengthNextModel;

      break;
    case -270:
      lastBackPoint = xStartPosition - PrevOMinZ;
      newXPosition = lastBackPoint + CurrentOMinZ;

      newZPosition = zStartPosition - lengthPrevModel - lengthNextModel;
      break;
  }

  return { xPosition: newXPosition, zPosition: newZPosition };
};
const getCreateNewNegativeModelRelativePrevLineModel = ({
  angle,
  xStartPosition,
  zStartPosition,
  parametersPrevModelPosition,
  parametersNextModelPosition,
}: getCreateNewPositionModelT): getCreateNewPositionObjectT => {
  let lengthPrevModel: number =
    parametersPrevModelPosition["segmentsModel"]["OMinX"];
  let lengthNextModel: number =
    parametersNextModelPosition["segmentsModel"]["OMinX"];

  let newXPosition: number = xStartPosition;
  let newZPosition: number = zStartPosition;

  let PrevOMinZ: number = parametersPrevModelPosition["segmentsModel"]["OMinZ"];
  let CurrentOMinZ: number =
    parametersNextModelPosition["segmentsModel"]["OMinZ"];

  let lastBackPoint = zStartPosition;

  switch (angle) {
    case 0:
      lastBackPoint = zStartPosition - PrevOMinZ;
      newZPosition = lastBackPoint + CurrentOMinZ;

      newXPosition = xStartPosition - lengthPrevModel - lengthNextModel;

      break;
    case -180:
      lastBackPoint = zStartPosition + PrevOMinZ;
      newZPosition = lastBackPoint - CurrentOMinZ;

      newXPosition = xStartPosition + lengthPrevModel + lengthNextModel;
      break;
    case -90:
      lastBackPoint = xStartPosition + PrevOMinZ;
      newXPosition = lastBackPoint - CurrentOMinZ;

      newZPosition = zStartPosition - lengthPrevModel - lengthNextModel;
      break;
    case -270:
      lastBackPoint = xStartPosition - PrevOMinZ;
      newXPosition = lastBackPoint + CurrentOMinZ; 
      newZPosition = zStartPosition + lengthPrevModel + parametersNextModelPosition["segmentsModel"]["OMaxX"];;

      break;
  }

  return { xPosition: newXPosition, zPosition: newZPosition };
};
const getCreateNewNegativeModelRelativePrevCornerModel = ({
  angle,
  xStartPosition,
  zStartPosition,
  parametersPrevModelPosition,
  parametersNextModelPosition,
}: getCreateNewPositionModelT): getCreateNewPositionObjectT => {
  let widthPrevModel: number =
    parametersPrevModelPosition["segmentsModel"]["OMaxZ"];
  let lengthNextModel: number =
    parametersNextModelPosition["segmentsModel"]["OMinX"];

  let newXPosition: number = xStartPosition;
  let newZPosition: number = zStartPosition;

  let PrevOMinZ: number = parametersPrevModelPosition["segmentsModel"]["OMinZ"];
  let CurrentOMinZ: number =
    parametersNextModelPosition["segmentsModel"]["OMinZ"];

  let lastBackPoint = zStartPosition;

  switch (angle) {
    case 0:
      lastBackPoint = zStartPosition - PrevOMinZ;
      newZPosition = lastBackPoint + CurrentOMinZ;

      newXPosition = xStartPosition - widthPrevModel - lengthNextModel;
      newXPosition =
        xStartPosition -
        parametersPrevModelPosition["segmentsModel"]["OMinX"] -
        parametersNextModelPosition["segmentsModel"]["OMaxX"];
      break;
    case -180:
      lastBackPoint = zStartPosition + PrevOMinZ;
      newZPosition = lastBackPoint - CurrentOMinZ;

      newXPosition = xStartPosition + widthPrevModel + lengthNextModel;

      break;
    case -90:
      lastBackPoint = xStartPosition + PrevOMinZ;
      newXPosition = lastBackPoint - CurrentOMinZ;

      newZPosition = zStartPosition - widthPrevModel - lengthNextModel;

      break;
    case -270:
      lastBackPoint =
        xStartPosition - parametersPrevModelPosition["segmentsModel"]["OMinZ"];

      newXPosition =
        lastBackPoint + parametersNextModelPosition["segmentsModel"]["OMinZ"];

      newZPosition =
        zStartPosition +
        parametersPrevModelPosition["segmentsModel"]["OMaxX"] +
        parametersNextModelPosition["segmentsModel"]["OMaxX"];
      break;
  }

  return { xPosition: newXPosition, zPosition: newZPosition };
};
const getCreateNewPositionModelRelativePrevCornerModel = ({
  angle,
  xStartPosition,
  zStartPosition,
  parametersPrevModelPosition,
  parametersNextModelPosition,
}: getCreateNewPositionModelT): getCreateNewPositionObjectT => {
  let widthPrevModel: number =
    parametersPrevModelPosition["segmentsModel"]["OMinX"];
  let lengthNextModel: number =
    parametersNextModelPosition["segmentsModel"]["OMaxX"];

  let newXPosition: number = xStartPosition;
  let newZPosition: number = zStartPosition;

  let PrevOMinZ: number = parametersPrevModelPosition["segmentsModel"]["OMaxX"];
  let CurrentOMinZ: number =
    parametersNextModelPosition["segmentsModel"]["OMinZ"];

  let lastBackPoint = zStartPosition;

  // lastBackPoint = zStartPosition - PrevOMinZ
  // newZPosition = lastBackPoint + CurrentOMinZ;
  switch (angle) {
    case 0:
      lastBackPoint = zStartPosition - PrevOMinZ;
      newZPosition = lastBackPoint + CurrentOMinZ;

      newXPosition = xStartPosition + widthPrevModel + lengthNextModel;

      break;
    case -180:
      lastBackPoint = zStartPosition + PrevOMinZ;
      newZPosition = lastBackPoint - CurrentOMinZ;

      newXPosition = xStartPosition - widthPrevModel - lengthNextModel;

      break;
    case -90:
      lastBackPoint = xStartPosition + PrevOMinZ;
      newXPosition = lastBackPoint - CurrentOMinZ;

      newZPosition =
        zStartPosition +
        parametersPrevModelPosition["segmentsModel"]["OMaxZ"] +
        parametersNextModelPosition["segmentsModel"]["OMinX"];

      break;
    case -270:
      lastBackPoint = xStartPosition - PrevOMinZ;
      newXPosition = lastBackPoint + CurrentOMinZ;

      newZPosition = zStartPosition - widthPrevModel - lengthNextModel + 0.06;

      break;
  }

  return { xPosition: newXPosition, zPosition: newZPosition };
};

const getCreateNewNegativeModelRelativePrevCornerORPrevCorner = ({
  angle,
  xStartPosition,
  zStartPosition,
  parametersPrevModelPosition,
  parametersNextModelPosition,
}: getCreateNewPositionModelT): getCreateNewPositionObjectT => {
  let widthPrevModel: number =
    parametersPrevModelPosition["segmentsModel"]["OMinX"];
  let lengthNextModel: number =
    parametersNextModelPosition["segmentsModel"]["OMaxX"];

  let newXPosition: number = xStartPosition;
  let newZPosition: number = zStartPosition;

  let PrevOMinZ: number = parametersPrevModelPosition["segmentsModel"]["OMaxX"];
  let CurrentOMinZ: number =
    parametersNextModelPosition["segmentsModel"]["OMinZ"];

  let lastBackPoint = zStartPosition;

  // lastBackPoint = zStartPosition - PrevOMinZ
  // newZPosition = lastBackPoint + CurrentOMinZ;

  switch (angle) {
    case 0:
      lastBackPoint =
        zStartPosition -
        (parametersNextModelPosition["segmentsModel"]["OMaxZ"] +
          parametersPrevModelPosition["segmentsModel"]["OMaxX"]);
      newZPosition = lastBackPoint;

      newXPosition =
        xStartPosition -
        (parametersNextModelPosition["segmentsModel"]["OMinX"] +
          parametersPrevModelPosition["segmentsModel"]["OMinZ"]) +
        (parametersPrevModelPosition["segmentsModel"]["OMinZ"] +
          parametersPrevModelPosition["segmentsModel"]["OMaxZ"]);

      break;
    case -180:
      lastBackPoint =
        zStartPosition +
        (parametersNextModelPosition["segmentsModel"]["OMaxZ"] +
          parametersPrevModelPosition["segmentsModel"]["OMaxX"]);
      newZPosition = lastBackPoint;

      newXPosition =
        xStartPosition -
        (parametersNextModelPosition["segmentsModel"]["OMinX"] +
          parametersPrevModelPosition["segmentsModel"]["OMinZ"]) +
        (parametersNextModelPosition["segmentsModel"]["OMinX"] +
          parametersNextModelPosition["segmentsModel"]["OMaxX"]);

      break;
    case -90:
      lastBackPoint = xStartPosition;
      newXPosition =
        xStartPosition +
        (parametersNextModelPosition["segmentsModel"]["OMaxZ"] +
          parametersPrevModelPosition["segmentsModel"]["OMinX"]);

      newZPosition =
        zStartPosition +
        (parametersNextModelPosition["segmentsModel"]["OMinX"] +
          parametersPrevModelPosition["segmentsModel"]["OMinZ"]) -
        (parametersNextModelPosition["segmentsModel"]["OMinX"] +
          parametersNextModelPosition["segmentsModel"]["OMaxX"]);

      break;
    case -270:

      // lastBackPoint =
      //   xStartPosition -
      //   parametersNextModelPosition["segmentsModel"]["OMaxX"] +
      //   parametersPrevModelPosition["segmentsModel"]["OMinZ"];
      // newXPosition =
      //   lastBackPoint -
      //   (parametersNextModelPosition["segmentsModel"]["OMinX"] +
      //     parametersNextModelPosition["segmentsModel"]["OMaxX"]);

      newXPosition =
        xStartPosition -
        (parametersPrevModelPosition["segmentsModel"]["OMinX"] +
          parametersNextModelPosition["segmentsModel"]["OMaxZ"]);

      newZPosition =
        parametersPrevModelPosition["segmentsModel"]["OMinZ"] +
        parametersNextModelPosition["segmentsModel"]["OMinX"] -
        (parametersPrevModelPosition["segmentsModel"]["OMinZ"] +
          parametersPrevModelPosition["segmentsModel"]["OMaxZ"]);

      break;
  }

  return { xPosition: newXPosition, zPosition: newZPosition };
};

const getCreateNewNegativeModelRelativePrevCornerORCurentLineModel = ({
  angle,
  xStartPosition,
  zStartPosition,
  parametersPrevModelPosition,
  parametersNextModelPosition,
}: getCreateNewPositionModelT): getCreateNewPositionObjectT => {
  let lengthNextModel: number =
    parametersPrevModelPosition["segmentsModel"]["OMinX"];

  let widthPrevModel: number =
    parametersNextModelPosition["segmentsModel"]["OMaxZ"];

  let newXPosition: number = xStartPosition;
  let newZPosition: number = zStartPosition;
  let lastBackPoint;
  switch (angle) {
    case 0:
      newZPosition = zStartPosition - widthPrevModel - lengthNextModel;

      lastBackPoint =
        xStartPosition + parametersPrevModelPosition["segmentsModel"]["OMinZ"];
      newXPosition =
        lastBackPoint - parametersNextModelPosition["segmentsModel"]["OMaxX"];

      break;
    case -180:
      newZPosition = zStartPosition + widthPrevModel + lengthNextModel;

      // lastBackPoint =
      //   xStartPosition - parametersPrevModelPosition["segmentsModel"]["OMinZ"];
      // newXPosition =
      //   lastBackPoint + parametersNextModelPosition["segmentsModel"]["OMinZ"];

      lastBackPoint =
        xStartPosition - parametersPrevModelPosition["segmentsModel"]["OMinZ"];
      newXPosition =
        lastBackPoint + parametersNextModelPosition["segmentsModel"]["OMinX"];
      break;
    case -90:
      newXPosition = xStartPosition + widthPrevModel + lengthNextModel;

      lastBackPoint =
        zStartPosition + parametersPrevModelPosition["segmentsModel"]["OMaxZ"];
      newZPosition =
        lastBackPoint - parametersNextModelPosition["segmentsModel"]["OMaxX"];
      break;
    case -270:
      newXPosition = xStartPosition - widthPrevModel - lengthNextModel;

      lastBackPoint =
        zStartPosition - parametersPrevModelPosition["segmentsModel"]["OMinZ"];
      newZPosition =
        lastBackPoint + parametersNextModelPosition["segmentsModel"]["OMaxX"];
      break;
  }

  return { xPosition: newXPosition, zPosition: newZPosition };
};

type getNewCoordinateT = {
  angle: number;
  typeDirection: getPositionModificationPointT;
  typePrevModel: typeModel;
  typeCurrentModel: typeModel;
  prevModel: objectModelT;
  parametersNextModelPosition: getParametersModelPositionT;
};

export const getNewCoordinate = ({
  angle,
  typeDirection,
  typePrevModel,
  typeCurrentModel,
  prevModel,
  parametersNextModelPosition,
}: getNewCoordinateT) => {
  let newXPosition = 0;
  let newZPosition = 0;
  const { xStartPosition, zStartPosition } = getStartPointXZ(
    prevModel["transform"]
  );

  let parametersPrevModelPosition = prevModel["parametersModelPosition"];

  if (typeDirection === "positive") {
    if (typePrevModel === "line") {
      let { xPosition, zPosition } =
        getCreateNewPositionModelRelativePrevLineModel({
          angle,
          xStartPosition,
          zStartPosition,
          parametersPrevModelPosition: parametersPrevModelPosition,
          parametersNextModelPosition,
        });

      newXPosition = xPosition;
      newZPosition = zPosition;
    }

    if (typePrevModel === "corner") {
      let { xPosition, zPosition } =
        getCreateNewPositionModelRelativePrevCornerModel({
          angle,
          xStartPosition,
          zStartPosition,
          parametersPrevModelPosition: parametersPrevModelPosition,
          parametersNextModelPosition,
        });
      newXPosition = xPosition;
      newZPosition = zPosition;
    }
  }
  if (typeDirection === "negative") {
    if (typePrevModel === "corner" && typeCurrentModel === "corner") {
      let { xPosition, zPosition } =
        getCreateNewNegativeModelRelativePrevCornerORPrevCorner({
          angle,
          xStartPosition,
          zStartPosition,
          parametersPrevModelPosition: parametersPrevModelPosition,
          parametersNextModelPosition,
        });

      newXPosition = xPosition;
      newZPosition = zPosition;
    } else if (typePrevModel === "line" && typeCurrentModel === "corner") {
      let { xPosition, zPosition } =
        getCreateNewNegativeModelRelativePrevCornerORCurentLineModel({
          angle,
          xStartPosition,
          zStartPosition,
          parametersPrevModelPosition: parametersPrevModelPosition,
          parametersNextModelPosition,
        });

      newXPosition = xPosition;
      newZPosition = zPosition;
    } else if (typePrevModel === "line") {
      let { xPosition, zPosition } =
        getCreateNewNegativeModelRelativePrevLineModel({
          angle,
          xStartPosition,
          zStartPosition,
          parametersPrevModelPosition: parametersPrevModelPosition,
          parametersNextModelPosition,
        });

      newXPosition = xPosition;
      newZPosition = zPosition;
    } else if (typePrevModel === "corner") {
      let { xPosition, zPosition } =
        getCreateNewNegativeModelRelativePrevCornerModel({
          angle,
          xStartPosition,
          zStartPosition,
          parametersPrevModelPosition: parametersPrevModelPosition,
          parametersNextModelPosition,
        });
      newXPosition = xPosition;
      newZPosition = zPosition;
    }
  }
  return { newXPosition, newZPosition };
};
