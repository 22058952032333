import {
  collectionsKeysT,
  componentsUIT,
  objectActiveParamsT,
  objectDependensThreekitRelativeUI,
} from "../../redux/features/ui/ui.types";
import { configurationBedroom } from "./configurationBedroom";
import { configurationChairs } from "./configurationChairs";
import { configurationClassicSofa } from "./configurationClassicSofa";
import { configurationOttomans } from "./configurationOttomans";
import { configurationSectionalSofa } from "./configurationSectionalSofa";

export type configColectionT = {
  componentsUI: componentsUIT;
  objectActiveParams: objectActiveParamsT;
  objectDependensThreekitRelativeUI: objectDependensThreekitRelativeUI;
};
export type configUIT = {
  [key in collectionsKeysT]: configColectionT;
};

export const configUI = {
  sofa: {
    componentsUI: configurationClassicSofa,
    objectActiveParams: {
      activeTab: "create",
    },
    objectDependensThreekitRelativeUI: {
      "Arm selector": ["style", "ArmSelectorWidth"],
      "Seat depth": ["depth"],
      "Legs selector": ["leg"],
      "Legs material": ["leg_material"],
      Materials: ["fabric"],
      "Sofa type": ["item_type"],
      showDimentions: ["showDimentions"],
      activeCamera: ["activeCamera"],
      setPillows: ["pillows"],
      "Pillow material": ["PillowMaterial"],
    },
  },
  sectional: {
    componentsUI: configurationSectionalSofa,
    objectActiveParams: {
      activeTab: "create",
    },
    objectDependensThreekitRelativeUI: {
      "Arm selector": ["style", "ArmSelectorWidth"],
      "Seat depth": ["depth"],
      "Legs selector": ["leg"],
      "Legs material": ["leg_material"],
      Materials: ["fabric"],
      showDimentions: ["showDimentions"],
      activeCamera: ["activeCamera"],
      "Pillow material": ["PillowMaterial"],
      setPillows: ["pillows"],
    },
  },
  ottoman: {
    componentsUI: configurationOttomans,
    objectActiveParams: {
      activeTab: "fabrics",
    },
    objectDependensThreekitRelativeUI: {
      Materials: ["fabric"],
      Materials_2: ["fabric_2"],
      Materials_3: ["fabric_3"],
      "Sofa type": ["item_type"],
      showDimentions: ["showDimentions"],
      activeCamera: ["activeCamera"],
    },
  },
  
  pillows: {},
  chair: {
    componentsUI: configurationChairs,
    objectActiveParams: {
      activeTab: "fabrics",
    },
    objectDependensThreekitRelativeUI: {
      Materials: ["fabric"],
      "Sofa type": ["item_type"],
      showDimentions: ["showDimentions"],
      activeCamera: ["activeCamera"],
    },
  },
  bed: {
    componentsUI: configurationBedroom,
    objectActiveParams: {
      activeTab: "create",
    },
    objectDependensThreekitRelativeUI: {
      "Bed Frame": ["bed_size"],
      Shapes: ["style"],
      Heights: ["height"],
      treatments: ["treatment"],
      "Legs selector": ["leg"],
      "Legs material": ["leg_material"],
      Materials: ["fabric"],
      showDimentions: ["showDimentions"],
      activeCamera: ["activeCamera"],
      // Storage: ["storage"],
      "Open drawer": ["openDrawer"]
    },
  },
};





