import React from "react";
import { useLocation } from "react-router-dom";
import { getActiveParamsById } from "../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import s from "./AddressLink.module.scss";

type AddressLinkT = {
  playerTitle: string;
  onClick: () => void;
};

const AddressLink = ({ playerTitle, onClick }: AddressLinkT) => {
  let activeItemType = useAppSelector((state) =>
    getActiveParamsById(state)("item_type")
  );
  let activeBedSize = useAppSelector((state) =>
    getActiveParamsById(state)("bed_size")
  );
  const { pathname } = useLocation();
  const category = pathname.split("/")[1];

  let actualTitle = "";
  if (activeItemType) {
    actualTitle = activeItemType;
  }
  if (activeBedSize) {
    actualTitle = activeBedSize;
  }
  const collectionCategoryName: any = {
    sofa: {
      start: "Collections / ",
      category: "Sofas /",
      name: actualTitle,
    },
    sectional: {
      start: "Collections / ",
      category: "Sectional",
      name: "",
    },
    ottoman: {
      start: "Collections / ",
      category: "Ottomans /",
      name: actualTitle,
    },
    chair: {
      start: "Collections / ",
      category: "Chairs /",
      name: actualTitle,
    },
    bed: {
      start: "Collections / ",
      category: "Beds & Headboards /",
      name: actualTitle,
    },
  };
  const defailtInfo = {
    start: "Collections / ",
    category: "Categories /",
    name: "Configurator",
  };
  const actualInfo = collectionCategoryName[category]
    ? collectionCategoryName[category]
    : defailtInfo;

  return (
    <span className={s.address}>
      <span onClick={() => (window.location.href = "/")}>
        {actualInfo.start}
      </span>
      {actualInfo.category && (
        <span onClick={() => onClick()}>{actualInfo.category}</span>
      )}
      {actualTitle && <span>{actualTitle}</span>}
    </span>
  );
};

export default AddressLink;
