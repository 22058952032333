import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "antd/dist/antd.css";
import { ConfiguratorApp } from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

root.render(
  <>
    <Provider store={store}>
      <ConfiguratorApp />
    </Provider>
  </>
);
