import React from 'react';

const MailDone = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31" cy="31" r="30" stroke="#004225" stroke-width="2" />
      <path
        d="M41.6666 23L26.9999 37.6667L20.3333 31"
        stroke="#004225"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MailDone;
