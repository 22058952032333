import React, { useEffect, useRef, useState } from "react";
import { getModalInfo } from "../../../redux/selectors/ui.selector";
import s from "./NotValidMesagge.module.scss";

import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { Close } from "../../../assets/svgCompopnent/Close";
import { useSelector } from "react-redux";
import {
  getAllModels,
  getModels,
  isInvalidConfigurations,
} from "../../../redux/selectors/model.selector";

export default function ModalNotValidMesagge() {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) =>
    getModalInfo(state)("NotValidMesagge")
  );
  const [modelsLength, setModelsLength] = useState(0);
  const invalidConfigurations = useSelector(isInvalidConfigurations);
  const models = useAppSelector(getModels);
  const wrapperRef: any = useRef(null);
  useEffect(() => {
    if (modelsLength !== Object.values(models).length) {
      setModelsLength(Object.values(models).length);
    }
  }, [models]);

  useEffect(() => {
    if (invalidConfigurations && !isOpen) {
      dispatch(openCloseModals({ name: "NotValidMesagge" }));
    }
  }, [modelsLength]);

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.wrapper__block} ref={wrapperRef}>
            <div
              className={s.close}
              onClick={() =>
                dispatch(openCloseModals({ name: "NotValidMesagge" }))
              }
            >
              <Close />
            </div>
            <div className={s.container}>
              <div className={s.wrap}>
                <div className={s.icon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <circle cx="12" cy="12" r="12" fill="white" />
                    <path
                      d="M11.005 6.29987C10.991 6.13554 11.0064 5.96958 11.05 5.81268C11.0937 5.65579 11.1647 5.51146 11.2584 5.38898C11.3522 5.2665 11.4666 5.16861 11.5943 5.10161C11.7221 5.03462 11.8603 5 12 5C12.1397 5 12.2779 5.03462 12.4057 5.10161C12.5334 5.16861 12.6478 5.2665 12.7416 5.38898C12.8353 5.51146 12.9063 5.65579 12.95 5.81268C12.9936 5.96958 13.009 6.13554 12.995 6.29987L12.6075 13.8045C12.5909 13.981 12.5198 14.1441 12.4077 14.2626C12.2957 14.381 12.1507 14.4465 12.0004 14.4465C11.8501 14.4465 11.7051 14.381 11.5931 14.2626C11.4811 14.1441 11.41 13.981 11.3933 13.8045L11.005 6.29987ZM11 16.8182C11 16.5047 11.1054 16.2041 11.2929 15.9825C11.4804 15.7609 11.7348 15.6363 12 15.6363C12.2652 15.6363 12.5196 15.7609 12.7071 15.9825C12.8946 16.2041 13 16.5047 13 16.8182C13 17.1316 12.8946 17.4322 12.7071 17.6539C12.5196 17.8755 12.2652 18 12 18C11.7348 18 11.4804 17.8755 11.2929 17.6539C11.1054 17.4322 11 17.1316 11 16.8182Z"
                      fill="#B57345"
                    />
                  </svg>
                </div>
                <div className={s.text}>
                  We do not recommend adding this configuration to your cart.
                </div>
              </div>
            </div>
            <div className={s.textInfo}>
              Hi there! We want you to be completely satisfied with your
              purchase and this configuration may not give you the best possible
              result. For the best possible outcome, consider adding or removing
              specific pieces to fine-tune your configuration.
            </div>
            <div
              onClick={() =>
                dispatch(openCloseModals({ name: "NotValidMesagge" }))
              }
              className={s.btn}
            >
              Return to Configurator
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
