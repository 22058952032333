import { isSectionalConfigurator } from "./../../utils/supportUtils";
import { addActiveThreeekitPillow } from "../../redux/actions/player.action";
import { setActivePillow } from "../../redux/features/ui/uiSlice";
import { store } from "../../redux/store";
import { getCouchPoin } from "./getCouchPoin";
import { modelHitBox } from "./modelHitBox";
import { rayCreation } from "./rayCreation";

export const positionUpdate = (allModels: any) => {
  const storeCopy = JSON.parse(JSON.stringify(store.getState()));
  const pillowId = window.player.selectionSet.ids[0];
  const activePillow: any = storeCopy.ui.activePillow;

  if (pillowId && !!activePillow) {
    const { ray, point } = rayCreation(pillowId);
    const pointPos = point.distanceTo(window.player.camera.getPosition());
    if (isSectionalConfigurator() && allModels) {
      Object.keys(allModels).forEach(async (key: string) => {
        const modelId = allModels[key].id;
        const couchPoin = await getCouchPoin(modelId);
        const hitPos = await modelHitBox(couchPoin, ray, allModels[key].angle);

        const checkIfPillowBlocked =
          !!hitPos &&
          hitPos.distanceTo(window.player.camera.getPosition()) < pointPos - 0.4;
          
          

        if (checkIfPillowBlocked) {
          clearPillowSelecting();
        }
      });
    } else {
      const couchPoin = getCouchPoin();
      const hitPos = modelHitBox(couchPoin, ray);
      const checkIfPillowBlocked =
        !!hitPos &&
        hitPos.distanceTo(window.player.camera.getPosition()) < pointPos;

      if (checkIfPillowBlocked) {
        clearPillowSelecting();
      }
    }
  }
};

function clearPillowSelecting() {
  window.player.selectionSet.clear();
  store.dispatch(setActivePillow(""));
  store.dispatch(addActiveThreeekitPillow({ id: "" }));
}
