import React, { useEffect, useState } from "react";
//@ts-ignore
import s from "./PillowMaterialHint.module.scss";

export const PillowMaterialHint = ({ ...props }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={s.hint} onClick={toggle}>
      <img src="/images/assets/svg/Bell.svg" alt="" />
      <span className={s.text}>
        In order to choose the fabric, you need to select the pillow in the
        configurator
        <span className={s.red}> select the pillow in the configurator </span>
        <span className={`${s.short} ${isOpen && s.active}`}>and ...</span>
        <span className={`${s.full} ${isOpen && s.active}`}>
          and a list of available materials for it will appear
        </span>
      </span>
    </div>
  );
};
