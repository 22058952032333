import { Dispatch } from "redux";
import { addActivePoint } from "../../redux/actions/player.action";
import { changeActiveStep, setActiveCategory, setActiveTab } from "../../redux/features/ui/uiSlice";

let lastAnnotations: any;
let lastParentEl: any;
let annotationElements: any = {};
let opened: any = {};

export function onAnnotationChange(
  annotations: any,
  parentEl: any,
  dispatch: Dispatch,
  isFirst: boolean = false
) {
  lastAnnotations = annotations;
  lastParentEl = parentEl;
  const HTMLPoints = document.getElementsByClassName("point");

  if (HTMLPoints.length > annotations.length) {
    Array.from(HTMLPoints).forEach((HTMLPoint) => {
      const findAnnotation = annotations.find(
        (annotation: any) => annotation.id === HTMLPoint.id
      );

      if (!findAnnotation) {
        HTMLPoint.setAttribute("style", " display: none !important;");
        HTMLPoint.id = "";
        HTMLPoint.className = " ";
      }
    });
  }

  if (!parentEl) {
    return;
  }

  for (var i = 0; i < annotations.length; i++) {
    var annotation = annotations[i];

    var element = annotationElements[annotation.id];
    var appendToBody = !element;

    element = setAnnotation(annotation, element, dispatch);

    if (!appendToBody && !isFirst) {
      continue;
    }

    parentEl.appendChild(element);
    annotationElements[annotation.id] = element;
  }
}

export function setAnnotation(
  annotation: any,
  el = document.createElement("span"),
  dispatch: Dispatch
) {
  el.className = "point";

  el.id = annotation.id;

  el.setAttribute(
    "style",
    "left: " +
      annotation.left +
      "px; top: " +
      annotation.top +
      "px;" +
      " display: " +
      (annotation.visible ? "block;" : "none;") +
      " animation-duration: 0.8s !important; border-radius: 50%;"
  );

  el.onclick = () => {
    opened[annotation.id] = !opened[annotation.id];
    onAnnotationChange(lastAnnotations, lastParentEl, dispatch);
    dispatch(addActivePoint({ id: annotation.text }));
    dispatch(setActiveTab('create'))
    dispatch(setActiveCategory('accordionAdditionalSections'))
    // dispatch(addActivePoint({ id: node.name }));

    el.className = "point click";
    // dispatch(changeActiveStep("build"));
  };

  return el;
}