import React, { useEffect, useState } from "react";
import { Point } from "../../../assets/svgCompopnent/Point";
//@ts-ignore
import s from "./AdditionalHint.module.scss";

export const AdditionalHint = ({ ...props }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={s.hint} onClick={toggle}>
      <img
        src="/images/assets/svg/Bell.svg"
        alt=""
      />
      <span className={s.text}>
        Click{" "}
        <Point />{" "}
        and select a component to add another piece to your build.{" "}
        <span className={`${s.short} ${s.red} ${isOpen && s.active}`}>
          Please note, not all...
        </span>
        <span className={`${s.full} ${s.red} ${isOpen && s.active}`}>
          Please note, not all components are compatible with one another.
          Available components for a specific side are highlighted accordingly.
        </span>
      </span>
    </div>
  );
};
