import { getAvailableItem } from "../../redux/selectors/player.selector";
import { getActiveCollectionId, getOptionsArrForArmSelector, getValuesFromIdAttribute } from "../../redux/selectors/ui.selector";
import { useAppSelector } from "../../utils/hooks";
import { refactoringModelLabel } from "../../utils/supportUtils";

export const getActualValue = (id, values) => {
  let valuesOptions;
  const list = useAppSelector(getAvailableItem);
  const activeCollectionId = useAppSelector(getActiveCollectionId);
  const valuesOptionsFromThreekit = useAppSelector(
    getValuesFromIdAttribute(id)
  );
  let optionsArrForArmSelector = useAppSelector(
    getOptionsArrForArmSelector(id)
  );
  const optionsArr =
    values.length > 0
      ? values
      : id !== "style" || activeCollectionId === "bed"
      ? valuesOptionsFromThreekit
      : optionsArrForArmSelector;

  if (id === "AdditionalItems") {
    valuesOptions = [];
    list.map((item, index) => {
      const label = refactoringModelLabel(item.name);

      valuesOptions.push({
        name: item.name,
        label: label,
        value: item.assetId,
        visible: true,
        metadata: item.metadata,
        enabled: item.showItem,
      });
    });
  } else {
    valuesOptions = optionsArr;
  }

  return valuesOptions;
};
