import React, { useEffect } from "react";
import s from "./MainPage.module.scss";
import MenuMain from "../../components/MainMenu/MenuMain/MenuMain";
import MenuSub from "../../components/MainMenu/MenuSub/MenuSub";
import useMessage from "@rottitime/react-hook-message-event";
import { compareStrings } from "../../utils/filterFabrics";
import filterRules from "../../utils/Constants/filterRules";
export default function MainPage() {
  useEffect(() => {
    // window.addEventListener("message", designLabInitMessage, false);
    // window.addEventListener("message", retailModeToggleMessage, false);
    // function designLabInitMessage(event: any) {

    //   if (
    //     // event.origin === "http://localhost:3000" &&
    //     event.data.event_id === "designLabInit"
    //   ) {
    //     const userType = event.data.retailMode;
    //     const effectiveAccountId = event.data.effectiveAccountId
    //       ? event.data.effectiveAccountId
    //       : undefined;
    //     dispatch(setUserType(userType));
    //     dispatch(setEffectiveAccountId(effectiveAccountId));
    //   }
    // }
    // function retailModeToggleMessage(event: any) {
    //   if (
    //     // event.origin === "http://localhost:3000" &&
    //     event.data.event_id === "retailModeToggle"
    //   ) {
    //     const userType = event.data.retailMode;
    //     const effectiveAccountId = undefined;
    //     dispatch(setUserType(userType));
    //     dispatch(setEffectiveAccountId(effectiveAccountId));
    //   }
    // }

  
  }, []);

  useEffect(() => {
    // const materialsWithSku: any = [];
    // const APImaterials: any = [];

    // threeKitMat.map((item: any) => {
    //   if (!item.tags.includes("no_sku")) {
    //     materialsWithSku.push(item.metadata.sku);
    //   }
    // });

    // fabric_list.map((item: any) => {
    //   APImaterials.push(item.sku);
    // });

    // var b = new Set(APImaterials);
    // var difference = [...materialsWithSku].filter((x) => !b.has(x));

    // const resMat: any = [];
    // threeKitMat.map((item: any) => {
    //   if (!item.tags.includes("no_sku")) {
    //     if (difference.includes(item.metadata.sku)) {
    //       resMat.push({
    //         assetId: item.assetId,
    //         name: item.name,
    //         sku: item.metadata.sku,
    //       });
    //     }
    //   }
    // });

    /*
    результат: [ 1, 2, 3]
*/

    window.sessionStorage.setItem("store", "{}");
    window.sessionStorage.setItem("itemType", "");

    if (window.player) {
      window.player.unload();
    }
  }, []);
  return (
    <div className={s.main}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.logo}>
            <img
              src="/images/assets/MainLogo.png"
              alt=""
            />
          </div>
          <div className={s.title}>
            Furniture designed by you, for you is just a{" "}
            <span>
              {/* <img
                src="/images/assets/svg/TextUnderlineCurve.svg"
                alt=""
              /> */}
              few clicks
            </span>{" "}
            away. <span className={s.title_accent_secondary}>Mix it up. </span>
            <span className={s.title_accent}>Make it yours.</span>
          </div>
        </div>
        <div className={s.catalog__block}>
          <MenuMain />

          <MenuSub />
        </div>
      </div>
    </div>
  );
}
