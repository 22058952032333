import { getDisplayAttributes } from "./attributesFunctions";

export const checkIsLoadingPlayer = (callback: any) => {
    let interval: any = null;
    const callbackIfExist = () => {
        if (!window.configurator) return false;
        if (!getDisplayAttributes()) return false;
        clearInterval(interval);
        callback();
    };

    // callbackIfExist();
    interval = setInterval(() => {
        callbackIfExist();
    }, 100);
};
