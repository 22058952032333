export const LoaderCircle = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 8C28.656 8 24.3869 9.13192 20.6135 11.2842C16.8402 13.4365 13.6927 16.5349 11.4814 20.274C9.27015 24.0131 8.07132 28.2638 8.00308 32.6073C7.93485 36.9508 8.99958 41.2371 11.0923 45.0438C13.1851 48.8505 16.2336 52.0462 19.9375 54.316C23.6414 56.5858 27.8728 57.8512 32.2147 57.9877C36.5566 58.1241 40.8591 57.1268 44.6982 55.0941C48.5373 53.0614 51.7805 50.0635 54.1082 46.3957"
        stroke="#B57345"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
