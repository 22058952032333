import { coordinatesObject } from "../../redux/types/typeModels";

export const getRootId = async () => {
  const player = window.player;
  let rootSceneId;

  const instanceNode = player.scene.get({ id: player.instanceId });

  if (instanceNode.type === "Item") {
    player.enableApi("player");
    rootSceneId = await player.player.getAssetInstance({
      id: player.instanceId,
      plug: "Proxy",
      property: "asset",
    });
  } else rootSceneId = player.instanceId; // it is a direct scene asset
  return player.scene.findNode({ from: rootSceneId, type: "Objects" });
};

export const addNodeModel = (
  assetId: string,
  translation: coordinatesObject,
  rotate: coordinatesObject,
  modelId?: string
) => {
  // let parentId = window.player.scene.find({
  //   from: window.player.instanceId,
  //   name: "Models",
  // });

  let parentId = window.player.scene.get({
    from: window.player.scene.get({ evalNode: true })["Proxy"]["proxy"]["instanceId"],
    name: "Models",
    evalNode: true,
});

  //@ts-ignore
  const parrentPlayer = window.parrentPlayer;
  if (!parentId) {
    parentId = window.player.instanceId;
  }
  // if (parrentPlayer) {
  //   parentId = parrentPlayer.id;
  // }
  const id = window.player.scene.addNode(
    {
      id: modelId ? modelId : `Model_${Math.random() * 100}`,
      type: "Model",
      name: `${"Model"}_${assetId}_` + Math.random(),
      plugs: {
        Null: [
          {
            type: "Model",
            asset: {
              assetId,
            },
          },
        ],
        Transform: [
          {
            type: "Transform",
            active: true,
            translation: translation,

            rotation: rotate,
          },
        ],
      },
    },
    parentId.id
  );

  return id;
};

export const addNewNodePoint = (
  namePoint: string,
  translation: coordinatesObject
): string => {
  let parentId = window.player.instanceId;
  //@ts-ignore
  const parrentPlayer = window.parrentPlayer;
  if (parrentPlayer) {
    parentId = parrentPlayer.id;
  }
  const id = window.player.scene.addNode(
    {
      id: `${namePoint}`,
      type: "Annotation",
      name: namePoint,
      plugs: {
        Transform: [
          {
            type: "Transform",
            active: true,
            translation,
          },
        ],
      },
    },
    parentId
  );
  return id;
};

export const addNewNodePillow = (
  name: string,
  translation: coordinatesObject,
  rotation: coordinatesObject,
  assetId: string
): string => {
  let parentId = window.player.scene.find({
    from: window.player.instanceId,
    name: "Layout Container",
  });

  if (!parentId) {
    parentId = window.player.instanceId;
  }
  //@ts-ignore
  const parrentPlayer = window.parrentPlayer;
  if (parrentPlayer) {
    parentId = window.player.scene.find({
      from: parrentPlayer.id,
      name: "Layout Container",
    });
  }
  const id = window.player.scene.addNode(
    {
      id: name ? name : `Pillows_${Math.random() * 100}`,
      type: "Model",
      name: `${"Pillows"}_${assetId}_` + Math.random(),
      plugs: {
        Null: [
          {
            type: "Model",
            asset: {
              assetId,
            },
          },
        ],
        Transform: [
          {
            type: "Transform",
            active: true,
            translation: translation,
            rotation: rotation,
          },
        ],
        Mixer: [
          {
            type: "Mixer",
          },
        ],
        // Physics: [
        //   {
        //     type: "Collider",
        //     response: 2,
        //     shape: 2,
        //   },
        // ],
      },
    },
    parentId
  );

  return id;
};

export const deleteNode = (id: string) => {
  window.player.scene.deleteNode(id);
};
