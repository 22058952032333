import React, { useCallback, useEffect, useMemo, useState } from "react";
import s from "./SectionalConfigurator.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGES, tabs } from "../../utils/constants";
import {
  FooterPlayer,
  openFullScreen,
} from "../../components/FooterPlayer/FooterPlayer";
import { Player } from "../../components/Player/Player";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  getActiveCategory,
  getActiveCollectionModelsGroupsList,
} from "../../redux/selectors/ui.selector";
import { collectionsKeysT } from "../../redux/features/ui/ui.types";
const ConfigurationInterface = React.lazy(
  () => import("../../components/ConfigurationInterfaсe/ConfigurationInterfaсe")
);
import {
  changeConfigUI,
  setActiveColection,
  setInitialState,
} from "../../redux/features/ui/uiSlice";
import DeleteModel from "../../components/DeleteModel/DeleteModel";
import { TabsConfigurationInterface } from "../../components/TabsConfigurationInterface/TabsConfigurationInterface";
import AddressLink from "../../components/AddressLink/AddressLink";
import {
  getListNewPoint,
  getPointsShow,
} from "../../redux/selectors/points.selector";
import { deleteAllPillows } from "../../redux/actions/player.action";

import { ErrorMesaggeNotValid } from "../../components/ErrorComponents/ErrorMesaggeNotValid/ErrorMesaggeNotValid";

import Zoom from "../../components/Zoom/Zoom";
import { getMaterialsInDB } from "../../api";
import { instanceApiFunc } from "../../api/instanceApi";

type CustomizedState = {
  collectionId: collectionsKeysT;
  modelThreekitArtibutes: string;
};

export default function SectionalConfigurator() {
  const collectionModelsList = useAppSelector((state) =>
    getActiveCollectionModelsGroupsList(state)
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { hash, key, pathname, search, state } = useLocation();
  const locationState = useLocation().state as CustomizedState;
  const collectionId = !!locationState
    ? (locationState.collectionId as collectionsKeysT)
    : (pathname.split("/")[1] as collectionsKeysT);

  useEffect(() => {
    dispatch(setActiveColection("sectional"));
    dispatch(changeConfigUI(collectionId));

    const instanceApi = instanceApiFunc({
      authToken: process.env.REACT_APP_API_TOKEN || "",
      urlApi: process.env.REACT_APP_API_URL || "/api",
    });
    // getMaterialsInDB(instanceApi).then((resultUpdate: any) => {
    //   console.log("resultUpda123te: ", resultUpdate);
    //   if (resultUpdate.Message == "OK") {
    //   }
    // });

    // /api/getMaterials
  }, []);

  const isModelsList = collectionModelsList.length > 0;
  const goBack = () => {
    if (!isModelsList) dispatch(setInitialState());
    if (isModelsList) {
      navigate(`/${collectionId}`, { state: { collectionId: collectionId } });
    } else {
      navigate("/");
    }
    dispatch(deleteAllPillows());
  };

  const handleClickToLogo = useCallback(() => {
    // dispatch(deleteAllPillows());
    // dispatch(setInitialState());
    // navigate(PAGES.index);
    window.location.href = "/";
  }, []);

  return (
    <div className={s.mainConfigurator}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.player}>
            <DeleteModel />
            <Zoom />
            <div className={s.player_header}>
              <div className={s.header}>
                <div className={s.logo} onClick={handleClickToLogo}>
                  <img src={"/images/assets/logo.png"} alt="" />
                </div>
              </div>

              <div className={s.back}>
                {/* <Back /> */}
                <AddressLink
                  playerTitle={collectionId}
                  onClick={() => {
                    window.constantsUI = {};
                    //window.player.unload()
                    goBack();
                  }}
                />
              </div>

              <div className={s.info__wrapper}>
                <div className={s.info}>Mix it up. Make it yours.</div>
                {/* <div className={s.ar}>
                  <ArSvg />
                  <span>View in my space</span>
                </div> */}
              </div>

              <TabsConfigurationInterface />
              {/* <div className={s.rotate_mob} onClick={openFullScreen}>
                <img
                  src="/images/assets/svg/RotateCamera.svg"
                  alt=""
                />
 
                <p>Use gesture to rotate or zoom</p>
              </div> */}
              <div className={s.playerMobile}>
                {/* <ErrorMesaggeNotValid /> */}
              </div>
            </div>
            <Player />
            <div className={s.player_footer}>
              <div className={s.playerFotterError}>
                {/* <ErrorMesaggeNotValid /> */}
              </div>
              <FooterPlayer />
            </div>
          </div>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ConfigurationInterface />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
