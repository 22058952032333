import React, { useEffect, useLayoutEffect, useState } from "react";

import useMobileWidth from "../../hooks/useMobileWidth";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  getAccordionsForActiveTab,
  getActiveCategory,
  getActiveCollectionId,
  getActiveParamFromIdComponentUI,
  getActiveTab,
  getAllThreekitAtribute,
  getLoadInit3kit,
  getObjActiveParamsUI,
} from "../../redux/selectors/ui.selector";
import { AccordionItem } from "../AccordionItem/AccordionItem";
import { setActiveCategory } from "../../redux/features/ui/uiSlice";
import { getThreekitActivePillow } from "../../redux/selectors/threekit.selectors";
import { LoaderCircle } from "../../assets/svgCompopnent/LoaderCircle";
import s from "./Accordion.module.scss";
import { AccordionLoader } from "../AccordionLoader/AccordionLoader";
import { AllTimePillowHint } from "../Hints/AllTimePillowHint/AllTimePillowHint";
import { checkIsOttomanNotMinsky } from "../../function/accordionHelperFunctions/checkIsOttomanNotMinsky";

export const Accordion = () => {
  let arrAccordionsForActiveTab = useAppSelector((state) =>
    getAccordionsForActiveTab(state)
  );
  const allThreekitAtribute = useAppSelector((state) =>
    getAllThreekitAtribute(state)
  );
  const activePillow = useAppSelector(getThreekitActivePillow);

  const isLoadInit3kit = useAppSelector((state) => getLoadInit3kit(state));
  const activeCategory = useAppSelector((state) => getActiveCategory(state));
  const objActiveParamsUI = useAppSelector((state) =>
    getObjActiveParamsUI(state)
  );
  const activeTab = useAppSelector((state) => getActiveTab(state));
  const valueStorage = useAppSelector(
    getActiveParamFromIdComponentUI("storage")
  );
  let activeCollectionId = useAppSelector((state) =>
    getActiveCollectionId(state)
  );

  let activeAccordionID =
    !!arrAccordionsForActiveTab &&
    arrAccordionsForActiveTab.length >= 1 &&
    arrAccordionsForActiveTab[0]["data"]["id"];

  const [activeAccordion, setActiveAcordion] = useState<any>(activeAccordionID);
  // START Show/Hide one accordions and one accordion height size
  const initVal: any[] = [];
  const elRefs = React.useRef(initVal);
  const dispatch = useAppDispatch();
  const isMobileSize = useMobileWidth();

  const isBedCollection = activeCollectionId === "bed";
  const isDrawer = valueStorage === "End Drawer";

  const handleShowHideAccordion = (idAccordion: any) => {
    if (isLoadInit3kit) return;

    if (activeAccordion !== idAccordion) {
      setActiveAcordion(idAccordion);
      dispatch(setActiveCategory(idAccordion));
    } else {
      setActiveAcordion("");
      dispatch(setActiveCategory(""));
    }
  };
  useEffect(() => {

    if (arrAccordionsForActiveTab && arrAccordionsForActiveTab.length >= 1) {
      setActiveAcordion(arrAccordionsForActiveTab[0].data.id);
    }

    if (activePillow.length > 0) {
      setActiveAcordion("accordionPillowsFabrics");
    }
  }, [activeTab, activeAccordionID]);

  useEffect(() => {
    if (activePillow.length > 0) {
      setActiveAcordion("accordionPillowsFabrics");
    }
  }, [activePillow]);

  useEffect(() => {
    if (activeCategory) {
      setActiveAcordion(activeCategory);
    }
  }, [activeCategory]);

  const setHeightActiveAccordion = (ref: any) => {
    if (ref) {
      const groupList = ref.current;

      let heightDisableGroups: number = 0;
      let activeGroup = groupList.filter(
        (item: any) => !!item && item.className.includes("active")
      );
      let noGroup = groupList.filter(
        (item: any) => !!item && !item.className.includes("active")
      );
      if (noGroup.length > 0 && activeGroup.length > 0) {
        const heightDisableGroup = noGroup[0].offsetHeight;
        heightDisableGroups = noGroup.length * heightDisableGroup;
        let parentNodeHeight = activeGroup[0].parentNode
          ? activeGroup[0].parentNode.offsetHeight - 1
          : 0;
        groupList.forEach((section: any) => {
          if (!!section) {
            section.style.maxHeight = `${
              parentNodeHeight - heightDisableGroups
            }px`;
          }
        });
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      if (!isMobileSize) {
        setHeightActiveAccordion(elRefs);
      }
    });
    if (!isMobileSize) {
      setHeightActiveAccordion(elRefs);
    }
  }, [isLoadInit3kit]);
  // END Show/Hide one accordions and one accordion height size
  arrAccordionsForActiveTab = checkIsOttomanNotMinsky(
    arrAccordionsForActiveTab
  );
  let mapKeyIndex = 0;

  return (
    <>
      {isLoadInit3kit ? (
        <AccordionLoader />
      ) : (
        <>
          <AllTimePillowHint />
          <div className={s.accordionWrap}>
            {!!arrAccordionsForActiveTab &&
              arrAccordionsForActiveTab.map((setting: any, index: number) => {
                let actualLabel = setting["data"].label;
                if (setting.data.id === "accordionFurnitureFabrics") {
                  const checkIfOttoman = arrAccordionsForActiveTab.find(
                    (item: any) =>
                      item.data.id === "accordionFurnitureFabrics_2"
                  );
                  actualLabel = checkIfOttoman
                    ? "Minsky Small"
                    : setting["data"].label;
                }
                const hideInSections = setting.data.hideInSections;
                const additionalLabelIf = setting.data.additionalLabelIf;
                const item_type = objActiveParamsUI["item_type"];
                const checkIsHideSection = hideInSections
                  ? !hideInSections.includes(item_type)
                  : true;
                const checkIsNewLabel = additionalLabelIf
                  ? !additionalLabelIf.section.includes(item_type)
                  : true;

                const isActive =
                  !!activeAccordion && activeAccordion === setting["data"]["id"]
                    ? s.active
                    : "";

                let mainItemType = setting["data"].mainItemType;

                let isActiveAcordion = true;
                if (mainItemType) {
                  isActiveAcordion = allThreekitAtribute[mainItemType]
                    ? true
                    : false;
                }

                if (isBedCollection && isDrawer) {
                  if (setting["data"]["id"] === "accordionLegStyleFinish") {
                    return <></>;
                  }
                  if (setting["data"]["id"] === "accordionFurnitureFabrics") {
                    mapKeyIndex -= 1;
                  }
                }

                if (
                  setting["data"].hideInSections &&
                  setting["data"].hideInSections.includes(item_type)
                ) {
                  mapKeyIndex = 0;
                } else {
                  mapKeyIndex++;
                }

                return (
                  <React.Fragment key={`${setting["data"]["id"]}-${index}`}>
                    {isActiveAcordion && checkIsHideSection && (
                      <div
                        ref={(el) => {
                          if (el !== null) {
                            elRefs.current[index] = el;
                          }
                        }}
                        className={`${s.acordionItemWrap} ${isActive} ${s[activeAccordion]}`}
                        style={
                          arrAccordionsForActiveTab.length === 1
                            ? { borderRadius: "8px" }
                            : {}
                        }
                      >
                        <AccordionItem
                          index={mapKeyIndex}
                          {...setting["data"]}
                          label={
                            checkIsNewLabel
                              ? actualLabel
                              : additionalLabelIf.label
                          }
                          item_type={item_type}
                          isActive={
                            !!activeAccordion &&
                            activeAccordion === setting["data"]["id"]
                          }
                          handleShowHideAccordion={(idAccordion: string) =>
                            handleShowHideAccordion(idAccordion)
                          }
                          disabled={isLoadInit3kit}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};
