import React, { useEffect, useState } from "react";
//@ts-ignore
import s from "./ImgRadio.module.scss";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  getActiveCollectionId,
  getActivelegType,
  getActiveParamFromIdComponentUI,
  getValuesFromIdAttribute,
} from "../../redux/selectors/ui.selector";
import { changeThreekitConfiguration } from "../../redux/actions/player.action";
import { ucFirst } from "../../utils/supportUtils";
import { getImageSrcForImgRadio } from "../../utils/ui/utilsForFiles";

export const ImgRadio = ({ ...props }: any) => {
  let { id, label, typeOptions, values } = props;

  const dispatch = useAppDispatch();
  const valueActiveOption = useAppSelector(getActiveParamFromIdComponentUI(id));
  const valuesOptionsFromThreekit = useAppSelector((state) =>
    getValuesFromIdAttribute(id)(state)
  );
  let activelegType = useAppSelector((state) => getActivelegType(state));

  function onChange(value: string, metadata: any) {
    dispatch(changeThreekitConfiguration({ [id]: value }));
  }

  return (
    <div className={s.imgRadioWrap}>
      {!!label && <div className={s.boxItemsTitle}>{label}</div>}
      {(id === "fabric" || id === "fabric_2" || id === "fabric_3") &&
        !!valueActiveOption && (
          <div className={s.selectedName}>
            {/* <span className={s.selectedName__label}>Selected Fabric:</span>
                <span className={s.selectedName__value}>{valueActiveOption.replace('_', ' ')}</span> */}
          </div>
        )}

      <div className={s.boxItems}>
        {values.map((option: any, index: number) => {
          let classItem = `${s.itemClass}`;
          const label = option["label"];
          let fileName =
            label && label.trim().toLowerCase().split(" ").join("_");

          if (id === "leg_material") {
            fileName = `${activelegType
              .trim()
              .toLowerCase()
              .split(" ")
              .join("_")}_${fileName}`;
          }

          const imageSrc: any = getImageSrcForImgRadio(id, fileName);

          if (valueActiveOption === option["name"]) classItem += ` ${s.active}`;

          return (
            <div
              key={`${option.name.replace(/\s/g, "")}_${index}`}
              className={classItem}
              onClick={() => onChange(option["name"], option["metadata"])}
            >
              <div className={s.imgWrap}>
                <img src={imageSrc} alt={option["value"]} />
              </div>
              <span className={s.label}>
                {label.replace("_", " ").toLowerCase()}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
