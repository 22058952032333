export const filterRules = {
  CURRENT: [
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "5TH AVENUE LINEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201126",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/17/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '55"',
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "5TH AVENUE LUNA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201135",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "6/22/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '55"',
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "5TH AVENUE MOONLIGHT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201136",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "6/22/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '55"',
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "ABBINGTON SEVEN SEAS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200985",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ACDC NATURAL",
      "Fabric Content": "57% VISCOSE, 35% POLYESTER, 8% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200512",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27.5"',
      "Vertical Repeat (in.)": '26"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ACDC PEWTER",
      "Fabric Content": "57% VISCOSE, 35% POLYESTER, 8% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200961",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27.5"',
      "Vertical Repeat (in.)": '26"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ADIOS JEANS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200461",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ADIOS SNOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200460",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AGLER MELON",
      "Fabric Content": "69% POLYESTER, 31% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200123",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.375"',
      "Vertical Repeat (in.)": '7.938"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ALDOUS HONEY",
      "Fabric Content": "59% COTTON, 18% UV POLYESTER, 12% VISCOSE, 12% OLEFIN",
      "Cleaning Code": "S",
      "Part #": "FAB201287",
      "Thread ": "Sand",
      "Thread Code": "Sand",
      "Date Added": "6/8/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ALFONSO HARBOR",
      "Fabric Content": "55% COTTON, 22% LINEN, 14% VISCOSE, 9% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201008",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3.35" ',
      "Vertical Repeat (in.)": '4.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ALINA MOONLIGHT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201022",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.17"',
      "Vertical Repeat (in.)": '14.8"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ALINA REDSTONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201023",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.17"',
      "Vertical Repeat (in.)": '14.8"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ALLEY PEPPER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201024",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "DORELL",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "ALMONDS MASCULINE CHIC",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200022",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },

    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AMADEO TANGERINE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200941",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "AMBIANCE COCOA",
      "Fabric Content": "50% POLYESTER, 35% RAYON, 15% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200111",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "AMBIANCE LANA",
      "Fabric Content": "50% POLYESTER, 35% RAYON, 15% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200436",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "AMBIANCE LUNAR",
      "Fabric Content": "50% POLYESTER, 35% RAYON, 15% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200112",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AMICI GINGER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201233",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "4/15/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AMICI INDIGO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201235",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/15/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AMICI MOSS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201232",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/15/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AMICI SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201234",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/15/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AMICI SMOKE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201236",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "4/15/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "ANDERS LINEN",
      "Fabric Content": "42% POLYESTER, 39% COTTON, 19% OLEFIN",
      "Cleaning Code": "S",
      "Part #": "FAB200729",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": '2.78"',
      "Vertical Repeat (in.)": '2.29"',
      "Solid or Pattern": "Solid",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "ANDERS SLATE",
      "Fabric Content": "42% POLYESTER, 39% COTTON, 19% OLEFIN",
      "Cleaning Code": "S",
      "Part #": "FAB200730",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": '2.78"',
      "Vertical Repeat (in.)": '2.29"',
      "Solid or Pattern": "Solid",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ANGEL PEWTER",
      "Fabric Content": "80% COTTON, 20% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101036",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '14.25"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "ANT FARM REED",
      "Fabric Content":
        "51% POLYESTER (UV),33% COTTON, 11% POLYESTER, 4% PCRPOLY, 1% NYLON",
      "Cleaning Code": "WS",
      "Part #": "FAB200429",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ANTHROPOLOGY COPPER",
      "Fabric Content": "82% POLYESTER, 18% ACRYLIC",
      "Cleaning Code": "W",
      "Part #": "FAB201064",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.87"',
      "Vertical Repeat (in.)": '0.94"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ANYA ADOBE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201226",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "3/2/22",
      "Month Added": "MARCH 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Pending",
      "Solid or Pattern": "Pattern",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ANYA BRASS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201227",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "3/2/22",
      "Month Added": "MARCH 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Pending",
      "Solid or Pattern": "Pattern",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AQUILINE PEWTER",
      "Fabric Content": "53% COTTON, 47% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101041",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '29.313"',
      "Vertical Repeat (in.)": '26.938"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AQUINTO CANYON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200046",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3.35"',
      "Vertical Repeat (in.)": '4.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ARCH GOLDEN HOUR",
      "Fabric Content": "100% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200957",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.50"',
      "Vertical Repeat (in.)": '25.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Geometric",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ARROWS INDIGO",
      "Fabric Content": "70% POLYESTER, 30% POLYPROPYLENE",
      "Cleaning Code": "W",
      "Part #": "FAB201063",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ARTISAN BARLEY",
      "Fabric Content": "45% POLYESTER, 30% VISCOSE, 25% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB201242",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/26/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ARTISAN PRALINE",
      "Fabric Content": "45% POLYESTER, 30% VISCOSE, 25% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB201243",
      "Date Added": "5/26/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ARTISAN SAGE",
      "Fabric Content": "45% POLYESTER, 30% VISCOSE, 25% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB201244",
      "Date Added": "5/26/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASGARD GLAM CANYON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200940",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.25"',
      "Vertical Repeat (in.)": '13.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASGARD MERMAID",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200506",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.25"',
      "Vertical Repeat (in.)": '13.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASGARD OFF THE GRID",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200987",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.25"',
      "Vertical Repeat (in.)": '13.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ASHFIELD GRAPHITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201177",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '9.25"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASPEN CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201195",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Faux Fur",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASTON SAPHIRE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200942",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASTON TRUFFLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201205",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "11/1/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Print",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ASTON WHISPER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201206",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "11/1/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Print",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ATHENA MARINE",
      "Fabric Content": "54% POLYESTER, 46% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB101048",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.75"',
      "Vertical Repeat (in.)": '6.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ATLANTA MINK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201025",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ATLANTA PUTTY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201026",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ATLANTA SHADOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201027",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "AWESOME CORNERSTONE",
      "Fabric Content": "79% POLYESTER, 21% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB101050",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AWESOME DENIM",
      "Fabric Content": "79% POLYESTER, 21% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB101051",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.188"',
      "Vertical Repeat (in.)": '18.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AWESOME HAVEN",
      "Fabric Content": "79% POLYESTER, 21% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB101053",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.188"',
      "Vertical Repeat (in.)": '18.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AWESOME SUNSET",
      "Fabric Content": "79% POLYESTER, 21% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB101054",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.188"',
      "Vertical Repeat (in.)": '18.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AZERA ONYX",
      "Fabric Content": "33% POLYESTER, 45% COTTON, 22% OLEFIN",
      "Cleaning Code": "SW",
      "Part #": "FAB101056",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28"',
      "Vertical Repeat (in.)": '14.1"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "AZUSA OCEANIC",
      "Fabric Content": "67% POLYESTER, 33% ACETATE",
      "Cleaning Code": "WS",
      "Part #": "FAB201127",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '7.1"',
      "Vertical Repeat (in.)": '7.1"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BABY VELOUR JEAN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200507",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BAMFORD TUXEDO",
      "Fabric Content": "43% POLYESTER, 39% RAYON, 18% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201017",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '13.84"',
      "Vertical Repeat (in.)": '14.19"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BANDITO CERULEAN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200024",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BANDITO MUSK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200473",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BANDITO SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200025",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BARSHA SURF",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "WS",
      "Part #": "FAB201115",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "4/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '13.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
      "Color Category 2": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BEACH FOG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201178",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "9/1/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "GUMTREE",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BEACH INDIGO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200965",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BEACH LINEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200966",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BEACH SMOKE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200967",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "BELEM A BURLAP",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101068",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.984"',
      "Vertical Repeat (in.)": '0.984"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "BELEM A FLAX",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101069",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.984"',
      "Vertical Repeat (in.)": '0.984"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "BELEM A POWDER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101070",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.984"',
      "Vertical Repeat (in.)": '0.984"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "BELEM A PUTTY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101071",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.984"',
      "Vertical Repeat (in.)": '0.984"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA ASPHALT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101086",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA AUBERGINE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101087",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA BEACH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101088",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA BERRY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101089",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA BLACK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101090",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "BELLA BONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101091",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA BUCKWHEAT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101092",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA CHOCOLATE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101094",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA COCOA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101095",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA COFFEE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101096",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA CORNFLOWER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101097",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101098",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA GRANITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101100",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA GREY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101101",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA HUNTER GREEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101102",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA INK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101103",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BELLA IVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201179",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA LAGOON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101105",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA MOCHA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101107",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101108",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101109",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA PEARL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101110",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "BELLA SKYLIGHT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200210",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "BELLA SMOKE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101114",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA STORM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101115",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA TOAST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101116",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BELLA TWILIGHT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201180",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA VINTAGE (OTTER)",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101117",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELLA WHITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101118",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT CLAY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201185",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "10/14/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT JADE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201186",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/14/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT MINK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201184",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "10/14/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT PLATINUM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201207",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/14/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT ROSE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201220",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "1/13/22",
      "Month Added": "DECEMBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "PENDING",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT SKY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201187",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/14/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BELMONT STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201188",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/14/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.375"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BENNETT ALMOND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101119",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BENNETT CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101121",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "BENNETT DENIM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200026",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BENNETT INDIGO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101123",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BENNETT INK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101124",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BENNETT PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101129",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BENNETT PRALINE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101130",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BEZOS CHAMBRAY",
      "Fabric Content": "65% POLYESTER, 35% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200516",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.438"',
      "Vertical Repeat (in.)": '16.563"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BIPPY BOP",
      "Fabric Content": "100 % POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101141",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.75"',
      "Vertical Repeat (in.)": '14.125"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BOCCI MINERAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200134",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27"',
      "Vertical Repeat (in.)": '27"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BONA CONFETTI",
      "Fabric Content": "59% ACRYLIC, 38% POLYESTER, 3% VISCOSE",
      "Cleaning Code": "W",
      "Part #": "FAB201062",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.17"',
      "Vertical Repeat (in.)": '5.90"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BONELLI MINERAL",
      "Fabric Content": "52% POLYESTER, 48% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB201247",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/27/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "DELEO",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '12.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BRAVADO ASH",
      "Fabric Content": "94% POLYESTER, 6% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200028",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BRAVADO BIRCH",
      "Fabric Content": "94% POLYESTER, 6% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200029",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BRAVADO CHAR",
      "Fabric Content": "94% POLYESTER, 6% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200031",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIDGER SEASPRAY ",
      "Fabric Content": "68% POLYESTER, 32% REPREVE POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201012",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.313"',
      "Vertical Repeat (in.)": '0.875"',
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON BLACK ",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100034",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON CEDAR GROVE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100035",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON DUNE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100036",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON EVERGLADE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100037",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON HERO",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100038",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON LAGOON",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100039",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON LINEN",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100040",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON LODEN",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100032",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Low res",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON MALACHITE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100041",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON MINK",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100042",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON PEWTER",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100043",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BRIGHTON PUTTY",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100084",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON RED CLAY",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100044",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIGHTON SHADOW",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100045",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIX MIDNIGHT",
      "Fabric Content": "60% POLYESTER, 40% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB101185",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '4.21"',
      "Vertical Repeat (in.)": '2.28"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Geometric",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BRIXIE NATURAL",
      "Fabric Content": "82% COTTON, 18% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201298",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "8/1/22",
      "Month Added": "AUGUST 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Requested 08/18",
      "Horizontal Repeat (in.)": '3.14"',
      "Vertical Repeat (in.)": '4.72"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BROMPTON BLUE & BLACK ",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100046",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BRONX CHARCOAL",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB201161",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "BRONX NICKEL",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB201162",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BUENA VISTA DENIM",
      "Fabric Content": "63% COTTON, 37% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101193",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.8"',
      "Vertical Repeat (in.)": '6.4"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BUNGALOW ECLIPSE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB201013",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BUNGALOW GRAPHITE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB201014",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BUNGALOW MOCHA",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200996",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "BUNGALOW SNOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200949",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "BURTON DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101194",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CAITLIN  CLOUD",
      "Fabric Content": "80% POLYESTER, 20% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200032",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CAITLIN FLAX",
      "Fabric Content": "80% POLYESTER, 20% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200033",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "CALI CITRUS",
      "Fabric Content":
        "38% VISCOSE, 22% COTTON, 16% LINEN, 16% POLYESTR, 6% ACRYLC, 2% NYLON",
      "Cleaning Code": "DRY CLN",
      "Part #": "FAB200528",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.88"',
      "Vertical Repeat (in.)": '5.51"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "CALI MIDNIGHT",
      "Fabric Content":
        "38% VISCOSE, 22% COTTON, 16% LINEN, 16% POLYESTR, 6% ACRYLC, 2% NYLON",
      "Cleaning Code": "DRY CLN",
      "Part #": "FAB200527",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '6.88"',
      "Vertical Repeat (in.)": '5.51"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "CALI SUNSET",
      "Fabric Content":
        "38% VISCOSE, 22% COTTON, 16% LINEN, 16% POLYESTR, 6% ACRYLC, 2% NYLON",
      "Cleaning Code": "DRY CLN",
      "Part #": "FAB200526",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.88"',
      "Vertical Repeat (in.)": '5.51"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAMILA DOVE",
      "Fabric Content": "95% POLYESTER, 5% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB201239",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/26/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAMILA MOCHA",
      "Fabric Content": "95% POLYESTER, 5% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB201240",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "5/26/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAMILA OYSTER",
      "Fabric Content": "95% POLYESTER, 5% LINEN",
      "Cleaning Code": "WS",
      "Date Added": "7/25/22",
      "Month Added": "JULY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAMILA STEEL",
      "Fabric Content": "95% POLYESTER, 5% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB201241",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "5/26/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAMPORA TUXEDO",
      "Fabric Content":
        "53% RAYON, 32% POLYESTER, 9% PCRPOLY, 5% COTTON, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200380",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27.063"',
      "Vertical Repeat (in.)": '31.508"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE CERULEAN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101268",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101269",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE CREAM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101272",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE GRANITE ",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101273",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE HEMP",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101274",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE JAVA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101275",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE KHAKI",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101276",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE MIDNIGHT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101278",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE MINK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101279",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE MULBERRY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101280",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE TRUFFLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101281",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CAPRICE WALNUT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101282",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CARRINGTON GLACIER",
      "Fabric Content": "60% ACRYLIC, 40% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101293",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CASBAH MARDI GRAS",
      "Fabric Content": "57% VISCOSE, 30% POLYESTER, 13% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201123",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CATALINA PEPPER",
      "Fabric Content": "65% POLYESTER, 35% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201189",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/20/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.25"',
      "Vertical Repeat (in.)": '10.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
      "Color Category 2": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CHACO FIESTA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200462",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CHAGALL MIST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101324",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.63"',
      "Vertical Repeat (in.)": '14.21"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CHICOPEE PRAIRIE",
      "Fabric Content": "49% POLYESTER, 27% LINEN, 22% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB200151",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "0",
      "Vertical Repeat (in.)": '14.96"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CHICOPEE SUNDANCE",
      "Fabric Content": "49% POLYESTER, 27% LINEN, 22% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB200153",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CHOMPS NATURE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201163",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CHUMLEE PEWTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200265",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CHUMLEE TUXEDO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200266",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CHUNKY MONKEY PEWTER",
      "Fabric Content": "94% COTTON, 6% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101348",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '1.07"',
      "Vertical Repeat (in.)": '0.76"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CIAO INDIGO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101349",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CIAO MERCURY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101350",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CIAO MUSHROOM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101351",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CISCO CARBON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101352",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CISCO DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101354",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CLIPPER FOG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200141",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '2.20"',
      "Vertical Repeat (in.)": '0.78"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "RC Willey Exclusive",
      "Fabric Name/Color": "CLIPPER HIBISCUS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200012",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "COCO BLUE",
      "Fabric Content":
        "44% POLYESTER, 30% ACRYLIC, 18% POLYPROPYLENE, 8% VISCOSE",
      "Cleaning Code": "W",
      "Part #": "FAB201160",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "COMET ONYX",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201181",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "COMFY CHOCOLATE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201150",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "COMFY OLIVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201028",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTESSA DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201143",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTESSA DUSK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201029",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "7/13/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTESSA IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201144",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTESSA PAPRIKA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201002",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTESSA SHADOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201003",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTINUUM AUTUMN LEAVES",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200356",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTINUUM BLUR",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200357",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTINUUM VOLCANIC",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200359",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTINUUM WATERFRONT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": " FAB200360",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "  ",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CONTOUR LIGHT GREY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200127",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Haverty's Exclusive",
      "Fabric Name/Color": "CONVEX PEACOCK",
      "Fabric Content": "90% POLYESTER, 5% ACRYLIC, 5% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200741",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CORTEZ LAVA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201201",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '7.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "COVENANT INDIGO",
      "Fabric Content": "97% POLYPROPYLENE, 3% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200432",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "CB2",
      "Fabric Name/Color": "COVERLET OTTER",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB201285",
      "Date Added": "6/20/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Requested",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "CB2",
      "Fabric Name/Color": "COVERLET WHEAT",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB201285",
      "Date Added": "6/20/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CREIGHTON PARCHMENT",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB200058",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CRINKLE APPLE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200365",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CRUISE WHITE HOT",
      "Fabric Content":
        "39% POLYESTER 25% VISCOSE 15% ACRYLIC 15% COTTON 6% LINEN",
      "Cleaning Code": "WS",
      "Part #": "FAB200945",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CUBA BARK",
      "Fabric Content": "53% VISCOSE 40% POLYESTER 71% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB201282",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/8/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "NARDES",
      "Horizontal Repeat (in.)": '3.54"',
      "Vertical Repeat (in.)": '3.54"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CURIOSITY TWILIGHT",
      "Fabric Content": "48% ACRYLIC, 48% POLYESTER, 4% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB200015",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '7.44"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CURIOUS CHARCOAL",
      "Fabric Content": "92% POLYESTER, 8% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200208",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CURIOUS ECLIPSE",
      "Fabric Content": "92% POLYESTER, 8% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200207",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CURIOUS PEARL",
      "Fabric Content": "92% POLYESTER, 8% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200209",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "CURIOUS SILVERPINE",
      "Fabric Content": "92% POLYESTER, 8% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200206",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "CYRUS CLAY",
      "Fabric Content": "76% COTTON, 24% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201291",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "6/22/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "MARLATEX",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DADDIO KONA",
      "Fabric Content": "89% COTTON, 11% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101401",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.375"',
      "Vertical Repeat (in.)": '11.813"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DALTON MOCHA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200188",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DALTON SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200187",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DANTE SEAGLASS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101403",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '19.68"',
      "Vertical Repeat (in.)": '14.17"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DARMA SABLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201031",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DARMA SMOKE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201032",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "9/20/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DAWSON BRINDLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101407",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DAWSON GUNMETAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101411",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DAWSON OATMEAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101413",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEAUVILLE DOVE",
      "Fabric Content": "70% POLYESTER, 30% ACRYILIC",
      "Cleaning Code": "WS",
      "Part #": "FAB101416",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEAUVILLE INDIGO",
      "Fabric Content": "70% POLYESTER, 30% ACRYILIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200354",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEAUVILLE STONE",
      "Fabric Content": "70% POLYESTER, 30% ACRYILIC",
      "Cleaning Code": "WS",
      "Part #": "FAB101417",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DELRAY ASH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201151",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DELRAY SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201152",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DERBY CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101441",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DERBY GREY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101442",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DERBY LINEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101443",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DERBY NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101444",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DERBY TAUPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101445",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DESHAWN PARCHMENT",
      "Fabric Content": "59% POLYESTER, 41% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB201222",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "1/19/22",
      "Month Added": "JANUARY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.38"',
      "Vertical Repeat (in.)": '1.56"',
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEVON BALTIC",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101449",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "DEVON CAMEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101450",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEVON HEATHER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101451",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "DEVON MOCHA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101454",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEVON MOUSE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101455",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEVON PEWTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101456",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEVON PLATINUM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101457",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DEVON SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101458",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "DEVON TANGELO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101459",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Haverty's Exclusive",
      "Fabric Name/Color": "DEVOTION PEWTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200740",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DOLLY BLUE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200943",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "D'ORO BURNISH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200034",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "DOTTIE INDIGO",
      "Fabric Content": "85% POLYESTER, 15% VISCOSE",
      "Cleaning Code": "S",
      "Part #": "FAB200273",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DOVER BARK",
      "Fabric Content": "96% POLYESTER, 2% NYLON, 2% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB101493",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DOVER BRINDLE",
      "Fabric Content": "96% POLYESTER, 2% NYLON, 2% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB101494",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DOVER IVORY",
      "Fabric Content": "96% POLYESTER, 2% NYLON, 2% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB200035",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DOVER NATURAL",
      "Fabric Content": "96% POLYESTER, 2% NYLON, 2% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB101495",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DOVER STONE",
      "Fabric Content": "96% POLYESTER, 2% NYLON, 2% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB101496",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "DRAMA MAMA MOLTEN",
      "Fabric Content": "51% POLYESTER, 35% COTTON, 13% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB200917",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.25"',
      "Vertical Repeat (in.)": '13.88"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "DRESDEN CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101498",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "DRESDEN FEATHER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200735",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "DUOMO BEACHGLASS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200738",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.6"',
      "Vertical Repeat (in.)": '17"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECHO GRANITE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100053",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECHO LAGER",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100055",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECHO LIMESTONE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100056",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECHO MOUNTAIN SPRING",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100058",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECHO TAUPE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100059",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECHO UMBER",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100060",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ECSTATIC CATALINA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101526",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.688"',
      "Vertical Repeat (in.)": '13.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "EDGAR GRAPHITE",
      "Fabric Content": "100% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200062",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EDGERLY BLUEGRASS",
      "Fabric Content": "99% Polyester, 1% Linen",
      "Cleaning Code": "S",
      "Part #": "FAB200437",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '28"',
      "Vertical Repeat (in.)": '41.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EDWARD GREY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101531",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EDWARD PORCELAIN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101533",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EDWARD SLATE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101534",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EFFLORESCE DENIM",
      "Fabric Content": "52% COTTON, 48% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101538",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27.5591"',
      "Vertical Repeat (in.)": ' 31.37795"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ELLIOT SNOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201033",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EMPIRE ASH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101554",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EMPIRE ICE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101557",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EMPIRE STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101564",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EUPHORIA MANATEE BAY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201145",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EUPHORIA NEW MOON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201146",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EUPHORIA SOUTH SEAS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201147",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "low res",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EUPHORIA WHITE DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201148",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "EVAN BISQUE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200115",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "EVAN MEDITERANEAN ",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200114",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "EVAN METAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101581",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Haverty's Exclusive",
      "Fabric Name/Color": "EWOKS NATURAL",
      "Fabric Content": "97% OLEFIN, 3% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200172",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": '1.875"',
      "Vertical Repeat (in.)": '3"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FARINA GRAPHITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201197",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FARINA NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201198",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FARINA OATMEAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200997",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FASCINATE VERSATILE",
      "Fabric Content": "20% COTTON 25% POLYESTER 55% VISCOSE",
      "Cleaning Code": "W",
      "Part #": "FAB101588",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '16"',
      "Vertical Repeat (in.)": "NOT PUBLISHED",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FEIVEL TWEED",
      "Fabric Content": "79% COTTON, 11% POLYESTER, 10% FLAX",
      "Cleaning Code": "S",
      "Part #": "FAB101589",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.938"',
      "Vertical Repeat (in.)": '11.313"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FETTY WAP ROSEWOOD",
      "Fabric Content":
        "41% COTTON, 36% RAYON, 20%POLYESTER, 2% FLAX, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200154",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.625"',
      "Vertical Repeat (in.)": '20.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FETTY WAP SISAL",
      "Fabric Content":
        "41% COTTON, 36% RAYON, 20%POLYESTER, 2% FLAX, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200381",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.625"',
      "Vertical Repeat (in.)": '20.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FILMORE MOCHA",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB201006",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.94"',
      "Vertical Repeat (in.)": '0.79"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FINE POINT CURRY",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB200133",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FINO MINERAL",
      "Fabric Content": "78% POLYESTER, 22% POLYACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB201202",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "DELEO",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FLETCHER LINEN",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200745",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FLETCHER NICKEL",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200746",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FLORENCE BLUSH",
      "Fabric Content": "90% COTTON, 10% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200956",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '2.56"',
      "Vertical Repeat (in.)": '3.43"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FOUNDATION SMOKE",
      "Fabric Content": "49% COTTON, 36% RAYON, 15% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201204",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.063"',
      "Vertical Repeat (in.)": '14.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
      "Color Category 2": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FRECKLES SILVER FOX",
      "Fabric Content": "44% COTTON, 28% LINEN, 28% VISCOSE",
      "Cleaning Code": "S",
      "Part #": "FAB101639",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '8.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FRESNO AQUA",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "W",
      "Part #": "FAB201129",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FRESNO CEMENT",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "W",
      "Part #": "FAB200185",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FROU FROU ZINC",
      "Fabric Content": "80% RAYON, 20% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB101657",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '2"',
      "Vertical Repeat (in.)": '6"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FUROCIOUS ARCTIC",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB101664",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FUROCIOUS BEAR",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB101665",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FUROCIOUS PANTHER",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB101667",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "FUROCIOUS SABLE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB101668",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GAFFIGAN TAHITI",
      "Fabric Content":
        "32% ACRYLIC, 32% RAYON, 22% POLYESTER (UV), 9%COTTON, 3% PIR POLY, 2% PCR POLY",
      "Cleaning Code": "S",
      "Part #": "FAB201061",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '.313"',
      "Vertical Repeat (in.)": '.88"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GAMBELLA 1 KONA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101669",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '8.625"',
      "Vertical Repeat (in.)": '14.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "GEO RAVEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200039",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "GEO STERLING",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200040",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GERVAIS EARTH",
      "Fabric Content": "63% POLYPROPYLENE, 21% COTTON, 16% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201058",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.75"',
      "Vertical Repeat (in.)": '7.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GILDA NATURAL",
      "Fabric Content": "60% POLYESTER, 30% VISCOSE, 10% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201142",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "7/8/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '10.2"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "GLAM BLACK",
      "Fabric Content": "100% POLYESTER",
      "Part #": "FAB201215",
      "Date Added": "11/8/21",
      "Month Added": "NOVEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "J.Allen",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "GLAM OTTER",
      "Fabric Content": "100% POLYESTER",
      "Part #": "FAB201216",
      "Date Added": "11/8/21",
      "Month Added": "NOVEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "J.Allen",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "GLAM PEARL",
      "Fabric Content": "100% POLYESTER",
      "Part #": "FAB201217",
      "Date Added": "11/8/21",
      "Month Added": "NOVEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "J.Allen",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GLEN ELLEN DENIM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201138",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "6/22/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GLEN ELLEN PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201005",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GLEN ELLEN SABLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201134",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GLEN ELLEN STEEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201300",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "9/6/22",
      "Month Added": "SEPTEMBER 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GLEN ELLEN STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201128",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GO GLOBAL SANDALWOOD",
      "Fabric Content": "50% COTTON, 30% POLYESTER, 20% VISCOSE",
      "Cleaning Code": "S",
      "Part #": "FAB200373",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '32"',
      "Vertical Repeat (in.)": '38"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GRANGE NUTMEG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201130",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.17"',
      "Vertical Repeat (in.)": '13.58"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
      "Color Category 2": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GROGAN DENIM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201296",
      "Date Added": "8/1/22",
      "Month Added": "AUGUST 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Requested 08/16",
      "Horizontal Repeat (in.)": '18.82"',
      "Vertical Repeat (in.)": '9.61"',
      "Solid or Pattern": "Pattern",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GROOVY DOMINO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101691",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GROUNDWORK INDIGO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101692",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '14.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GUATEMALA COPEN",
      "Fabric Content": "82% POLYPROPYLENE, 18% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101693",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '27"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "GUSTO CHARCOAL",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "W",
      "Part #": "FAB200425",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY ARROW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101695",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY BLUE GOBLIN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101696",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY BLUSH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101697",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY CHOCOLATE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101698",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY CRÈME",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101699",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY EGGPLANT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101701",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "GYPSY FLANNEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101703",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY GRAIN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101704",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY GRAPHITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101705",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101707",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY PLATINUM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101708",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY QUARRY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101709",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SAFARI",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101710",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SAGE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101711",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SCARLET",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101712",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SEASPRAY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101713",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SLATE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101714",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SMOKED PEARL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101715",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101716",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "GYPSY SUBTLE GREEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101717",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY SUNSET",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101718",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY TAUPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101719",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY TEAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101720",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY TRUFFLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101721",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY VAPOR",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101722",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "GYPSY VINTAGE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101723",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HAMRICK ROYAL",
      "Fabric Content": "66% POLYESTER, 24% COTTON, 10% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB200449",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HAPPY NEST CONFETTI",
      "Fabric Content": "69% POLYESTER, 23% ACRYLIC, 8% LINEN",
      "Cleaning Code": "DryCln",
      "Part #": "FAB201004",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '15.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HARNESS CHARCOAL",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100080",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HART PORCELAIN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201164",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HARVEY ASH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200919",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HARVEY CAFÉ",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201153",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HARVEY ECLIPSE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201154",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY APPLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101732",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY CAFÉ",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101733",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY CARAMEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101734",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY CHROME",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101735",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY CINDER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101736",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY JAVA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101737",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY MOCHA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101739",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY MULBERRY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101740",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY NAVAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101741",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY OLIVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101742",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY OYSTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101743",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY ROBINS EGG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101744",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEAVENLY SAPPHIRE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101745",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEFLIN PARCHMENT",
      "Fabric Content": "39% VISCOSE, 37% COTTON, 14% POLYESTER, 7% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB201125",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/18/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.89"',
      "Vertical Repeat (in.)": '15.95"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HEIRO CHARCOAL",
      "Fabric Content": "55% POLYPROPYLENE, 36% ACRYLIC, 9% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200455",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HELIO OYSTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200492",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HELIO PEWTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200493",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HENSON BIRCH",
      "Fabric Content": "99% UV POLYESTER, 1% PCR POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201288",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/8/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEPBURN DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201140",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "6/22/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEPBURN FAWN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201139",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/22/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEPBURN POTTERY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201132",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HEVERON ONYX",
      "Fabric Content": "90% COTTON, 10% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101758",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HIPSTER SILVER",
      "Fabric Content": "36% POLYESTER, 64% VISCOSE",
      "Cleaning Code": "S",
      "Part #": "FAB101759",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HITCHCOCK FRESCO",
      "Fabric Content": "55% POLYESTER, 32% RAYON, 13% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB101760",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HOLLAND PEARL",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100062",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HOLLAND SKY",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100063",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HOLLYN PEARL",
      "Fabric Content": "100% Polyester",
      "Cleaning Code": "S",
      "Part #": "FAB200438",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '17.375"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HOONAH DENIM",
      "Fabric Content": "66% POLYESTER, 33% COTTON, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200469",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "HOONAH OBSIDIAN",
      "Fabric Content": "66% POLYESTER, 33% COTTON, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200205",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HUGO CHARCOAL",
      "Fabric Content": "89% POLYESTER, 11% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB101766",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HUGO INDIGO",
      "Fabric Content": "89% POLYESTER, 11% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB101768",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Haverty's Exclusive",
      "Fabric Name/Color": "HUNKY DORY ZINC",
      "Fabric Content": "73% Cotton, 17% UV, 7% Pre-Crcotn",
      "Cleaning Code": "S",
      "Part #": "FAB200739",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "HYBRID ADOBE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200199",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '1.75"',
      "Vertical Repeat (in.)": '11.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "INCA FIJI",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101777",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '11.61"',
      "Vertical Repeat (in.)": '13.58"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "INVERSE EARTH",
      "Fabric Content": "45% OLEFIN, 37% POLYESTER, 18% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB102528",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.188"',
      "Vertical Repeat (in.)": '12.813"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "INZANE DUSK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200254",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ISLA SUNSET",
      "Fabric Content": "40% VISCOSE, 39% POLYESTER, 15% LINEN, 6% NYLON",
      "Cleaning Code": "DryCln",
      "Part #": "FAB200923",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.19"',
      "Vertical Repeat (in.)": '13.78"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "JAHA AZURE",
      "Fabric Content": "63% POLYESTER, 19% RAYON, 17% COTTON, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200070",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "JAM SESSION FOG",
      "Fabric Content": "65% COTTON, 22% POLYESTER, 13% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB200071",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JAMBOREE OASIS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200926",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '17.05"',
      "Vertical Repeat (in.)": '9.45"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JASPAR DESERT",
      "Fabric Content": "25% ACRYLIC 75% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201245",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/27/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "NARDES",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "JAZ INK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB100489",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JIGSAW GOLDSTONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB101791",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '12.59"',
      "Vertical Repeat (in.)": '14.09"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "JITTERBUG TAUPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200503",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JOBRANI DUNE",
      "Fabric Content":
        "50% POLYESTER (UV), 33% COTTON, 13% PCR POLY, 3% POLYESTER, 1% NYLON",
      "Cleaning Code": "WS",
      "Part #": "FAB201066",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.25"',
      "Vertical Repeat (in.)": '26.087"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JUBILEE MINT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200072",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "JUBILEE PACIFIC",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200392",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JUBILEE PEARL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200019",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "JUBILEE SNOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200042",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JUBILEE STEEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200074",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JUBILEE TURQUOISE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200016",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "JULANA KUBA",
      "Fabric Content": "57% Cotton, 29% Rayon, 14% Polyester",
      "Cleaning Code": "S",
      "Part #": "FAB200441",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.438"',
      "Vertical Repeat (in.)": '13.875"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KADEEN HEIRLOOM",
      "Fabric Content": "54% POLYESTER, 46% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200431",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.375"',
      "Vertical Repeat (in.)": '27.288"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KALISPAR DRIFTWOOD",
      "Fabric Content": "53% Polyester 18% Rayon 15% Poly (UV) 14% Cotton",
      "Cleaning Code": "S",
      "Part #": "FAB201200",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.188"',
      "Vertical Repeat (in.)": '14"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KANTHA BERRY",
      "Fabric Content": "92% POLYESTER, 8% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201052",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '2"',
      "Vertical Repeat (in.)": '2.4"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KANTHA KOI",
      "Fabric Content": "92% POLYESTER, 8% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201053",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '2"',
      "Vertical Repeat (in.)": '2.4"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KARIOUT HEIRLOOM",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200382",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.625"',
      "Vertical Repeat (in.)": '13.0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KATALINA SHELL",
      "Fabric Content": "67% RAYON, 32% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200132",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.3465"',
      "Vertical Repeat (in.)": '26.378"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "KEANU PEPPERCORN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200253",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Haverty's Exclusive",
      "Fabric Name/Color": "KEEBLER SAPHIRE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200192",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KEEBLER TUXEDO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200332",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.25"',
      "Vertical Repeat (in.)": '6"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KEEBLER VAPOR",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200748",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.25"',
      "Vertical Repeat (in.)": '6"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "KENLEY CANVAS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200991",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "KENLEY CHIMNEY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200992",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "KENLEY MIDNIGHT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200993",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "KENLEY MOONDUST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200990",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "KENLEY QUARTS",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200995",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "KENLEY SNOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201069",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "KENZIE SPECTRUM",
      "Fabric Content": "89% POLYESTER, 11% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB201156",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "KENZO FIG",
      "Fabric Content":
        "38% POLYPROPYLENE, 32% POLYESTER, 19% ACRYLIC, 9% VISCOSE, 2% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB201034",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "9/20/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KERRYN RASPBERRY",
      "Fabric Content": "83% COTTON, 16% RAYON, 1% NYLON",
      "Cleaning Code": "S",
      "Part #": "fab200013",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3.438"',
      "Vertical Repeat (in.)": '3.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "KHALEESI JEWEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200456",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KIKKAN MINERAL",
      "Fabric Content": "65% VISCOSE 20% POLYESTER 15% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201290",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/22/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '2.44"',
      "Vertical Repeat (in.)": '6.31"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KINETIC CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200136",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.3"',
      "Vertical Repeat (in.)": '22.8"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "KING KONG SAGE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201035",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KIPLING METALLIC ONYX",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100068",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "KRAUSS DEW",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200124",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": '1.063"',
      "Vertical Repeat (in.)": '1.063"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LABYRINTH SEASIDE",
      "Fabric Content": "67% ACRYLIC, 29% POLYESTER, 4% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB201049",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '14.96"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LAKELAWN NATURAL",
      "Fabric Content": "94% POLYESTER, 6% ACRYLIC",
      "Cleaning Code": "W",
      "Part #": "FAB201120",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "4/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "LANDMARK SEASCAPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201182",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LATTICE GREY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101821",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '1.2"',
      "Vertical Repeat (in.)": '0.65"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "Jordans Exclusive",
      "Fabric Name/Color": "LENNOX PEARL",
      "Fabric Content": "88% POLYESTER, 7% LINEN, 5% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB101825",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Solid",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LETTERMAN COPPER",
      "Fabric Content": "59% VISCOSE, 23% COTTON, 18% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201286",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "6/8/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LEVEL TAUPE",
      "Fabric Content": "91% POLYESTER, 9%NYLON ",
      "Cleaning Code": "W",
      "Part #": "FAB200118",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LINDEN NAVY",
      "Fabric Content": "90% COTTON, 10% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101830",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "LINUS CHARCOAL",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB101842",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LINUS MONDO",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB101843",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LINUS PLATINUM",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB101844",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LINUS STERLING",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB101845",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.25"',
      "Vertical Repeat (in.)": '0.25"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LLAMA FUR BLACK",
      "Fabric Content": "100% ACRYLIC",
      "Cleaning Code": "DryCln",
      "Part #": "FAB101848",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LLAMA FUR CHARCOAL",
      "Fabric Content": "100% ACRYLIC",
      "Cleaning Code": "DryCln",
      "Part #": "FAB101849",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LLAMA FUR SAND",
      "Fabric Content": "100% ACRYLIC",
      "Cleaning Code": "DryCln",
      "Part #": "FAB101850",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LLAMA FUR SILVER",
      "Fabric Content": "100% ACRYLIC",
      "Cleaning Code": "DryCln",
      "Part #": "FAB101851",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LLAMA FUR SNOW WHITE",
      "Fabric Content": "100% ACRYLIC",
      "Cleaning Code": "DryCln",
      "Part #": "FAB101852",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LLAMA FUR TEAL",
      "Fabric Content": "100% ACRYLIC",
      "Cleaning Code": "DryCln",
      "Part #": "FAB101853",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "LOLA DENIM",
      "Fabric Content": "83% POLYESTER, 17% VISCOSE",
      "Cleaning Code": "S",
      "Part #": "FAB201010",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "LOXY PEWTER",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "SW",
      "Part #": "FAB200969",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.17"',
      "Vertical Repeat (in.)": '15.28"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "LUIGI MINK",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB201165",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "ZWOVENS",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "LUIGI NATURE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200495",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MACDUFF CHARCOAL",
      "Fabric Content": "79% OLEFIN, 21% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200463",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MACINTOSH COTTON",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200491",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MACINTOSH MIST",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200156",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MACINTOSH SAGE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200157",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MAESTRO SHADOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201036",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '9.65"',
      "Vertical Repeat (in.)": '10.47"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MAJESTIC MINK",
      "Fabric Content": "92% POLYESTER, 8% ACRYLIC",
      "Cleaning Code": "SW",
      "Part #": "FAB201157",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MAJESTIC PEARL",
      "Fabric Content": "45% ACRYLIC, 40% POLYESTER, 11% RAYON, 4% LINEN ",
      "Cleaning Code": "SW",
      "Part #": "FAB201037",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MALDON COPPER",
      "Fabric Content": "80% POLYESTER, 20% COTTON",
      "Cleaning Code": "W",
      "Part #": "FAB201289",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "6/22/22",
      "Month Added": "JUNE 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.44"',
      "Vertical Repeat (in.)": '12.94"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MALIBU DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101878",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MALIBU LINEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101880",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MALIBU SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101881",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MALIBU SEA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101882",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MARAMA STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201166",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "ZWOVENS",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARIN BREEZE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200928",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARIN SUNFLOWER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200950",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "MARLEY DIJON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200728",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARLEY NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200727",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARLEY OLIVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200726",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARLEY SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200725",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARMONT WHITE",
      "Fabric Content": "81%POLYESTER 19%NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB101888",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.3"',
      "Vertical Repeat (in.)": '0.4"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MARRAZOS SEAGLASS",
      "Fabric Content": "39% RAYON, 38% POLYESTER, 23% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201292",
      "Date Added": "7/7/22",
      "Month Added": "JULY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Valdese",
      "Solid or Pattern": "Pattern",
    },
    {
      "Grade/Pricing Tier": "Restricted Premium C",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "MARSEILLES CHARCOAL",
      "Fabric Content": "50% RAYON, 41% COTTON, 9% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101889",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Restricted Premium C",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "MARSEILLES MOONBEAM",
      "Fabric Content": "50% RAYON, 41% COTTON, 9% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101890",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MASTERPIECE TURMERIC",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200139",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '18.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MATOYA PEKOE",
      "Fabric Content": "47% POLYESTER, 31% PRE-CR COTTON, 22% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201050",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.31"',
      "Vertical Repeat (in.)": '12.56"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MAYA CARAMEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200524",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.08"',
      "Vertical Repeat (in.)": '5.9"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MCKITTRICK CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200352",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MCKITTRICK TAUPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200353",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ME TOO PICANTE",
      "Fabric Content": "55% COTTON, 54% POLYESTER (UV)",
      "Cleaning Code": "S",
      "Part #": "FAB200466",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ME TOO TERRAZO",
      "Fabric Content": "55% COTTON, 54% POLYESTER (UV)",
      "Cleaning Code": "S",
      "Part #": "FAB200467",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MEATBALL IVORY",
      "Fabric Content": "75% POLYESTER, 21% ACRYLIC, 4%VISCOSE ",
      "Cleaning Code": "WS",
      "Part #": "FAB101904",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '1.18"',
      "Vertical Repeat (in.)": '.87"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MEDIA GRANITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200160",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '8.82"',
      "Vertical Repeat (in.)": '14.48"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MEDIA SPA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200159",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MEGA BLACK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200973",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MEGA IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200929",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MEMORY APPLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200930",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MENOUNOS TWINE",
      "Fabric Content": "38% OLEFIN, 11% COTTON, 51% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201238",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/27/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '28.378"',
      "Vertical Repeat (in.)": '27.391"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MERINO COTTON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201167",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MERIT CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101927",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MERIT DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101928",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MERIT ECLIPSE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200974",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MERIT GREYSTONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101929",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MERIT ONYX",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201038",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MERIT PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101930",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MERIT PEARL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101931",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MERIT SCARLET",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201040",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MERIT SNOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200135",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MESA ACACIA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200947",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "METAPHOR JETSET",
      "Fabric Content": "84% POLYESTER, 16% RAYON",
      "Cleaning Code": "WS",
      "Part #": "FAB201190",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/20/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Stain Resistant": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "METAPHOR WINTER",
      "Fabric Content": "84% POLYESTER, 16% RAYON",
      "Cleaning Code": "WS",
      "Part #": "FAB201191",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/20/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Stain Resistant": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MILANO GRAPHITE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100069",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MILLIKEN NATURAL",
      "Fabric Content": "55% POLYESTER, 28% COTTON, 17% FLAX",
      "Cleaning Code": "S",
      "Part #": "FAB201059",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.625"',
      "Vertical Repeat (in.)": '5.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "MILSAP BEACH",
      "Fabric Content": "97% OLEFIN, 3% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200178",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "MILSAP SPRAY",
      "Fabric Content": "97% OLEFIN, 3% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200180",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MIND GAME SAPPHIRE",
      "Fabric Content": "45% PRE-COTTON, 22% RAYON, 18% NYLON, 15% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200446",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '12.031"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MINEYA MEADOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200980",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.09"',
      "Vertical Repeat (in.)": '14.17"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MITOS HEATHER",
      "Fabric Content": "77% VISCOSE, 23% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101940",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '3.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MITOS TAUPE",
      "Fabric Content": "77% VISCOSE, 23% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101943",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '3.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MIZA DESERT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201041",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '4.76"',
      "Vertical Repeat (in.)": '12.36"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "MODERN VELVET LODEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200240",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "MODERN VELVET SHITAKE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB101953",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "MODERN VELVET WILLOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200434",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO AVOCADO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101956",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO CITRONELLA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101957",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO COAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101958",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO COFFEE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101959",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO COMMODORE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101960",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO EMERALD",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101961",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO INK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101962",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO MONDO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101963",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101964",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO RUST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101965",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO SPA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101966",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO STEEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101967",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONACO TAUPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB101968",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONMOUTH TANGERINE",
      "Fabric Content": "40% POLYESTER, 30% RAYON, 20% COTTON, 10% LINEN",
      "Cleaning Code": "W",
      "Part #": "FAB200948",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONOLITH TUXEDO",
      "Fabric Content": "52% COTTON, 27% POLYESTER, 21% POLY(UV)",
      "Cleaning Code": "S",
      "Part #": "FAB200936",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.688"',
      "Vertical Repeat (in.)": '9.313"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONT BLANC ANTHRACITE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100070",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONT BLANC CHIANTI",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100071",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONT BLANC IRON",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100083",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONT BLANC IVORY",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100073",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONT BLANC MAUVE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100081",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONT BLANC TANGERINE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100075",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONTERA WHITESAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101970",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MONTERREY PEBBLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101971",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MOONWALK NUTMEG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201131",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "6/15/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '26.77"',
      "Vertical Repeat (in.)": '19.41"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
      "Color Category 2": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MOTLEY BLUSH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200955",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.25"',
      "Vertical Repeat (in.)": '15.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MOUNTAINEER MOCCASIN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201168",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "ZWOVENS",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "Mr. Cheetah Flannel",
      "Fabric Content": "48% RAYON, 39% POLYESTER, 12% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB101975",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '28.445"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MUNICH METAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200120",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.75"',
      "Vertical Repeat (in.)": '13.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "MUSE STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201149",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "7/13/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "MUSEO MARBLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201169",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "ZWOVENS",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "NABILA FOG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200939",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.25"',
      "Vertical Repeat (in.)": '19.813"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "NABILA IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200520",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.25"',
      "Vertical Repeat (in.)": '19.813"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "NABILA OCEAN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200519",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.25"',
      "Vertical Repeat (in.)": '19.813"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "NABILA RADIANT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200518",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.25"',
      "Vertical Repeat (in.)": '19.813"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA BROWN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200348",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200346",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA STEEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200344",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA STORM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200349",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA TAUPE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200347",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA TEAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200345",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPA VINTAGE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200343",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAPLES STERLING",
      "Fabric Content": "26% COTTON, 36% POLYESTER, 22% VISCOSE, 16% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB201068",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NARRATIVE CITRON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201051",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NARRATIVE PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201015",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NATORI BLAZE",
      "Fabric Content": "43% ACRYLIC, 38% TEXT PL., 11% POLYESTER, 7% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB101986",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '17.7"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NAXOS BRICK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB101990",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NEPAL ASH",
      "Fabric Content": "89% POLYESTER, 11% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB200515",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NEPAL CLAY",
      "Fabric Content": "89% POLYESTER, 11% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB200514",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NEPAL FRENCH BLUE",
      "Fabric Content": "89% POLYESTER, 11% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB200513",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NOLONI PORCELAIN",
      "Fabric Content": "74% POLYESTER, 26% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201011",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.563"',
      "Vertical Repeat (in.)": '17.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "NOTION GUNMETAL",
      "Fabric Content": "52% POLYESTER, 48% ACRYLIC",
      "Cleaning Code": "S",
      "Part #": "FAB101997",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "NYLA NOIR",
      "Fabric Content": "45% PRE-CROTN, 36% POLYESTER, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200465",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "NYLA SPICE",
      "Fabric Content": "45% PRE-CROTN, 36% POLYESTER, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200918",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OCAMPO DUSK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102006",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ODIN MINERAL",
      "Fabric Content": "57% POLYESTER, 33% ACRYLIC, 10% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB201018",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.2"',
      "Vertical Repeat (in.)": '14.2"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OH BROTHER OCEAN",
      "Fabric Content": "46% RAYON, 21% POLYESTER, 19% PCR POLY, 14% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201007",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '.88"',
      "Vertical Repeat (in.)": '6.19"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OLLIEROO PEWTER",
      "Fabric Content":
        "39% Cotton 27% Polyesyter 20% Rayon 14% Polyester (UV)",
      "Cleaning Code": "S",
      "Part #": "FAB201193",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/20/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.75"',
      "Vertical Repeat (in.)": '0.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
      "Color Category 2": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OLLIEROO SISAL",
      "Fabric Content":
        "39% Cotton 27% Polyesyter 20% Rayon 14% Polyester (UV)",
      "Cleaning Code": "S",
      "Part #": "FAB201246",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/27/22",
      "Month Added": "MAY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.75"',
      "Vertical Repeat (in.)": '0.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "OPULENT BLUEBERRY",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102010",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "OPULENT CARBON",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102011",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
      Notes: "Dropped 07.20, but Active in INFOR",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "OPULENT GLACIER",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102012",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OPULENT MARGARITA",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102013",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OPULENT POLAR",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102014",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OPULENT SEAL",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102015",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "OPULENT SNOW",
      "Fabric Content": "90% POLYESTER, 10% COTTON",
      "Cleaning Code": "Dry Cln",
      "Part #": "FAB102016",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Exception- Major Acct.",
      "Fabric Name/Color": "OSAKA FUSCHIA",
      "Fabric Content": "51% POLYESTER, 29% VISCOSE, 16% ACRYLIC, 4% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB102031",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Linen Look",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "OSLO IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201183",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Design Lab Exclusive",
      "Fabric Name/Color": "OSLO TAUPE",
      "Fabric Content": "52% RAYON, 48% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200924",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '4.563"',
      "Vertical Repeat (in.)": '4.875"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PABON BRASS",
      "Fabric Content": "31% RAYON,31% COTTON, 20%POLY,17% POLY(UV), 1%NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB201054",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '18.38"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PABON DOMINO",
      "Fabric Content": "31% Rayon 31% Cotton 20% Polyester 18% Polyester (UV)",
      "Cleaning Code": "S",
      "Part #": "FAB201192",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/20/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '18.375"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PALERMO MAGENTA",
      "Fabric Content": "80% VISCOSE, 20% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200104",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '36"',
      "Vertical Repeat (in.)": '51"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PALM MIST MICA",
      "Fabric Content": "50% ACRYLIC, 41% POLYESTER, 9%VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB102041",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PANNIER POTTERY",
      "Fabric Content": "77% POLYESTER, 23% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102043",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL CAMEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102044",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102045",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102046",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102047",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL MOCHA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102048",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL PAPRIKA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200522",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL ROSEWOOD",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200521",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102049",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PARALLEL TOBACCO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200523",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "PASSION CAMEL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201045",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEARL LEOPARD SNUGGLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102057",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '8.86"',
      "Vertical Repeat (in.)": '4.09"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PERK SEACREST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201056",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON CHOCOLATE",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102064",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "PEYTON CINDER",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102523",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON FAWN",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB200081",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON LIGHT BLUE",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102067",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON NAVY",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102068",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON PEARL",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102069",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON PEPPER",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102070",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON ROSE",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102071",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PEYTON SLATE",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB102072",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "LiveSmart",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PHILOSOPHY ALUMINUM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102074",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3"',
      "Vertical Repeat (in.)": '4.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PIERINA POOL",
      "Fabric Content":
        "52% COTTON, 27% OLEFIN, 15% PRE-CR COTTON, 5% POLYESTER ",
      "Cleaning Code": "S",
      "Part #": "FAB201116",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.63"',
      "Vertical Repeat (in.)": '13.81"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PIRELLI ASPHALT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200131",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '19.75"',
      "Vertical Repeat (in.)": '29"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PIXIE NATURAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201231",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/7/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received 04/18/22",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PLAYTHING PEWTER",
      "Fabric Content": "75% POLYESTER, 25% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102085",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3.625"',
      "Vertical Repeat (in.)": '7"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PLEAT COCONUT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200958",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "CSWO Exclusive",
      "Fabric Name/Color": "PLUS SIZE ALCHEMY",
      "Fabric Content": "85% Cotton, 15% Polyester",
      "Cleaning Code": "S",
      "Part #": "FAB200442",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "POP TRIVIA",
      "Fabric Content": "52% POLYESTER, 48% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200747",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.5"',
      "Vertical Repeat (in.)": '9.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "POSITIVE ENERGY QUINCE",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB102107",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.25"',
      "Vertical Repeat (in.)": '4.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "PREFERENCE CREAM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200085",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium B",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "PREMIERE STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102110",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "PRICKETT MINERAL",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "W",
      "Part #": "FAB201218",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "2/1/22",
      "Month Added": "FEBRUARY 2022",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "QUIZADA AZURE",
      "Fabric Content": "51% RAYON, 28% POLYESTER 21% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102116",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.063"',
      "Vertical Repeat (in.)": '11.188"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "RADICAL CHALK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201170",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RAIN CHARCOAL",
      "Fabric Content": "65% COTTON, 35% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201237",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/21/21",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "NARDES",
      "Solid or Pattern": "Pattern",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RAJI INDIGO",
      "Fabric Content": "58% POLYESTER, 30% RAYON, 6% LINEN, 6% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200043",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.8"',
      "Vertical Repeat (in.)": '9.45"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "RAMY BEIGE (MUSLIN)",
      "Fabric Content": "95% POLYESTER, 5% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB200234",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "RAMY BLUE (NAVY)",
      "Fabric Content": "95% POLYESTER, 5% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB200236",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "RAMY GREY (GRANITE)",
      "Fabric Content": "95% POLYESTER, 5% NYLON",
      "Cleaning Code": "W",
      "Part #": "FAB200235",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RAVEN BAY BLUE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Part #": "LEA100077",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "REASON GREYSTONE",
      "Fabric Content": "88% POLYESTER, 7% RAYON, 5% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200363",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '1.68"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RHYTHM LINEN",
      "Fabric Content": "57% RAYON 22% COTTON 21% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200937",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.5"',
      "Vertical Repeat (in.)": '16.563"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RIC RAC PATINA",
      "Fabric Content": "47% COTTON, 39% RAYON, 14% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201060",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.906"',
      "Vertical Repeat (in.)": '9.063"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RICOCHET SAPPHIRE",
      "Fabric Content": "44% ACRYLIC, 36% VICOSE, 20% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201009",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3.4"',
      "Vertical Repeat (in.)": '2.9"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RIDDLE PEWTER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200086",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '3.38"',
      "Vertical Repeat (in.)": '11"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RIZZO ALMOND",
      "Fabric Content": "93% POLYESTER, 7% ACRYLIC",
      "Cleaning Code": "S",
      "Part #": "FAB200355",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '7.38"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RODMAN AMBER",
      "Fabric Content": "76% POLYESTER, 24% SEAQUAL POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201297",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "8/1/22",
      "Month Added": "AUGUST 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '13.88"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "ROMERO STEEL",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "WS",
      "Part #": "FAB200175",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "ROMERO STRAW",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "WS",
      "Part #": "FAB200174",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE APPLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201121",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/6/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE ASH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200284",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE BERRY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200470",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "8/3/21",
      "Month Added": "AUGUST 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE BLUSH",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102167",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE COBALT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200285",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE CORAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200471",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE DIJON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200443",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE EVERGREEN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102168",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE GUNMETAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200287",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE MONDO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200238",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "ROYALE ONYX",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201214",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "11/18/21",
      "Month Added": "NOVEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "Received",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ROYALE PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200288",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "ROYALE RUST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201212",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "11/18/21",
      "Month Added": "NOVEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "Received",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Fabric Name/Color": "ROYALE SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201213",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "11/18/21",
      "Month Added": "NOVEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "n/a",
      "JL- Bedroom": "n/a",
      "JL- Accessories": "n/a",
      "JL- Toss Pillows": "n/a",
      "JL Design Lab- Sofa & Sectional": "n/a",
      "JL Design Lab- Bedroom": "n/a",
      "Sofa POP- Accents": "n/a",
      "Sofa POP- Pillows": "n/a",
      "Just Live™ - Bleach Cleanable": "n/a",
      "Just Live™ - Durable 30,000 DR": "n/a",
      "Just Live™ - Fade Resistant": "n/a",
      "Just Live™ - Moisture Repellent": "n/a",
      "Just Live™ - Soap Cleanable": "n/a",
      "Just Live™ - Stain Resistant": "n/a",
      "Fabric Image": "Received",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "RUMBA METAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200113",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SABIRA COBALT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102174",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28"',
      "Vertical Repeat (in.)": '28.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SANTA FE MINERAL",
      "Fabric Content": "66% VISCOSE, 24% POLYESTER, 10% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201124",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "5/18/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.27"',
      "Vertical Repeat (in.)": '10.79"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
      "Color Category 2": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SASCHA JADE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201171",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SASCHA SAPPHIRE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201172",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SATILLO DRIFTWOOD",
      "Fabric Content": "70% Cotton 16% Poly (UV) 14% Polyester",
      "Cleaning Code": "S",
      "Part #": "FAB201199",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '9.375"',
      "Vertical Repeat (in.)": '17.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SAVVY ECLIPSE",
      "Fabric Content": "98% POLYESTER, 2% NYLON",
      "Cleaning Code": "WS",
      "Part #": "FAB200202",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SCRIBBLE INDIGO",
      "Fabric Content": "61% POLYESTER, 39% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB102208",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.09"',
      "Vertical Repeat (in.)": '2.36"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SCRIBBLE MINK",
      "Fabric Content": "61% POLYESTER, 39% COTTON",
      "Cleaning Code": "WS",
      "Part #": "FAB102209",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.09"',
      "Vertical Repeat (in.)": '2.36"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SCRIPPS KONA",
      "Fabric Content": "51% POLYESTER, 49% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102210",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.25"',
      "Vertical Repeat (in.)": '13.875"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SEA CHANGE PASTEL",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "WS",
      "Part #": "FAB102527",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '7"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SEISMIC DRIFTWOOD",
      "Fabric Content": "35% POLYESTER, 34% RAYON, 31% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102212",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.75"',
      "Vertical Repeat (in.)": '6.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SEISMIC RAIN",
      "Fabric Content": "35% POLYESTER, 34% RAYON, 31% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102213",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.75"',
      "Vertical Repeat (in.)": '6.75"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SENSU CEMENT",
      "Fabric Content": "95% POLYESTER/5% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB102216",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SENSU CHARCOAL",
      "Fabric Content": "95% POLYESTER/5% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB102218",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SENSU FLAX",
      "Fabric Content": "95% POLYESTER/5% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB102219",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SENSU SNOW",
      "Fabric Content": "95% POLYESTER/5% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB102222",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHAPAN ADOBE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201122",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "5/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.9"',
      "Vertical Repeat (in.)": '1.44"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHAPAN INK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201223",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "1/19/22",
      "Month Added": "JANUARY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0.9"',
      "Vertical Repeat (in.)": '1.44"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHAPAN LEAF",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201117",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0.9"',
      "Vertical Repeat (in.)": '1.44"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHEBANG KIMONO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102234",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.625"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHEEPSKIN NATURAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200089",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHEEPSKIN TOAST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200989",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHIMMER GOLD",
      "Fabric Content":
        "38% VISCOSE, 22% COTTON, 14% ACRYLIC, 10% WOOL, 9% LINEN, 9% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102235",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHIMMY COTTAGE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200335",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SHIMMY FAWN",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200334",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SINATRA FIDDLEFERN",
      "Fabric Content": "58% RAYON, 42% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200376",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '48.375"',
      "Vertical Repeat (in.)": '38.375"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SKIDROW ONYX",
      "Fabric Content": "66% POLYESTER, 34% COTTO",
      "Cleaning Code": "S",
      "Part #": "FAB201118",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.25"',
      "Vertical Repeat (in.)": '6.875"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "SKOOPA CERULEAN",
      "Fabric Content": "88% POLYESTER, 12% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200189",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "SKOOPA FLANNEL",
      "Fabric Content": "88% POLYESTER, 12% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200190",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "SKOOPA TANGO",
      "Fabric Content": "88% POLYESTER, 12% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200191",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SKYSCRAPER CHAMPAGNE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200932",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SKYSCRAPER MEADOW",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200933",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SOLANO COTTON",
      "Fabric Content": "100% COTTON ",
      "Cleaning Code": "S",
      "Part #": "FAB200959",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SOLANO INK",
      "Fabric Content": "100% COTTON ",
      "Cleaning Code": "S",
      "Part #": "FAB200737",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SOLANO IRON",
      "Fabric Content": "100% COTTON ",
      "Cleaning Code": "S",
      "Part #": "FAB200736",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SOLANO NICKEL",
      "Fabric Content": "100% COTTON ",
      "Cleaning Code": "S",
      "Part #": "FAB200960",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Moisture Repellent": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SOUFFLE AQUA",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200981",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '1.18"',
      "Vertical Repeat (in.)": '0.59"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SPECTRA HOT STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200743",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SPECTRA RAINFALL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200742",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SPECTRA YOGI",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB200744",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SPEEDWAY MARINE",
      "Fabric Content": "67% POLYESTER 33% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB102294",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Low res",
      "Horizontal Repeat (in.)": '3.75"',
      "Vertical Repeat (in.)": '6"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Premium B ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SPLENDOUR DESERT DUNE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201196",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/26/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SPREE PUTTY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200927",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Low res",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "STAR VELVET CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102314",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "STAR WHITEGOLD",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201173",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "STARLET STEEL",
      "Fabric Content": "47% POLYESTER, 53% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB102327",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '2"',
      "Vertical Repeat (in.)": '1.625"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "STORM CHASER STONE",
      "Fabric Content": "71%POLYPROPYLENE, 29%POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102329",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '16.5"',
      "Vertical Repeat (in.)": '14.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "STRANDS CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "DryCln",
      "Part #": "FAB102330",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '2.5"',
      "Vertical Repeat (in.)": '5.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "STRUMMER DENIM",
      "Fabric Content": "84% POLYESTER, 16% OLEFIN",
      "Cleaning Code": "WS",
      "Part #": "FAB200090",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.5"',
      "Vertical Repeat (in.)": '28.767"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "SUEDE DOVE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200984",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB COBALT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200976",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "4/1/21",
      "Month Added": "APRIL 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB DIJON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200496",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB GUNMETAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200497",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB PEACOCK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200498",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB PEONY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200920",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB RUST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200499",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "SUPERB WINE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200500",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SWEATER DESERT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200368",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SWEATER SILVER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB200369",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SYMBA ONYX",
      "Fabric Content": "60% RAYON 40% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102349",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '6.75"',
      "Vertical Repeat (in.)": '6.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "SYNERGY CHARCOAL",
      "Fabric Content": "81% POLYESTER CHENILLE, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200366",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "SYNERGY DARK TAN",
      "Fabric Content": "81% POLYESTER CHENILLE, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200475",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "SYNERGY LIGHT CREAM",
      "Fabric Content": "81% POLYESTER CHENILLE, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200477",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "SYNERGY LIGHT GREY",
      "Fabric Content": "81% POLYESTER CHENILLE, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200478",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "SYNERGY NAVY",
      "Fabric Content": "81% POLYESTER CHENILLE, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200479",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "SYNERGY TAUPE",
      "Fabric Content": "81% POLYESTER CHENILLE, 19% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200476",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TALLY CAPRI",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "WS",
      "Part #": "FAB200014",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '27"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TAMARACK GRANITE",
      "Fabric Content": "78% POLYESTER, 22% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB200094",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '7.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "TAVERNIER BLUE STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201174",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "TAVERNIER HARVEST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201175",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TAYLOR FELT GREY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102379",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TECH ARCTIC",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102380",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TECH BROWNSTONE ",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102381",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TECH GRANITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102382",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TECH OAK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102383",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TECH PEBBLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102384",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TECH STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102385",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TED CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200162",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TED FOG",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200163",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TED METAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200351",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TED NAVY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200164",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TERRAIN LICHEN",
      "Fabric Content": "65% RAYON, 35% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201203",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/27/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '28.5"',
      "Vertical Repeat (in.)": '32"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TERRAZO IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200935",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TERRAZO JASPER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200571",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "THRILLIST DENIM",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200018",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "THRILLIST FOG",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200098",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "THRILLIST GRAPHITE",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200099",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "THRILLIST PARCHMENT",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200100",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "THRILLIST PEARL",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200140",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "THRILLIST TRANQUIL",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200193",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "THRILLIST TWIG",
      "Fabric Content": "100% OLEFIN",
      "Cleaning Code": "W",
      "Part #": "FAB200101",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "Yes",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Just Live™ - Bleach Cleanable": "l",
      "Just Live™ - Durable 30,000 DR": "l",
      "Just Live™ - Soap Cleanable": "l",
      "Just Live™ - Stain Resistant": "l",
      "Just Live™ -FABRIC VENDOR LOGO": "Sustain",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TIKA JADE",
      "Fabric Content": "58% RAYON, 42% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201057",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '28"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TIMBUKTU CANYON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102402",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.58"',
      "Vertical Repeat (in.)": '6.92"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TIME SQUARE HARBOUR",
      "Fabric Content": "55% VISCOSE 45% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102403",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '5.75"',
      "Vertical Repeat (in.)": '1.25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "TITAN SHADOW",
      "Fabric Content": "96% POLYESTER, 4% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200983",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TRAMORE AMARETTO",
      "Fabric Content": "90% POLYESTER, 7% NYLON, 3% SPANDEX",
      "Cleaning Code": "S",
      "Part #": "FAB201225",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "1/20/22",
      "Month Added": "JANUARY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "TRAVELER GARNET",
      "Fabric Content":
        "45% ACRYLIC, 18% POLYESTER, 17% POLYPROPYLENE, 13% VISCOSE, 7% LINEN",
      "Cleaning Code": "W",
      "Part #": "FAB200453",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TRAVIATA MARBLE",
      "Fabric Content": "88% POLYESTER, 12% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200142",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '16.1"',
      "Vertical Repeat (in.)": '14.2"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Fabric Name/Color": "TRENDSETTER FLANNEL",
      "Fabric Content": "56% POLYESTER(UV) , 44% POLYSTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200430",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TRIBECA ALPINE",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100087",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TRIBECA CHELSEA",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100085",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TRIBECA GLASS",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100086",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TRINITY FOG",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "W",
      "Part #": "FAB102417",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9"',
      "Vertical Repeat (in.)": '18"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TROPICANA COASTAL",
      "Fabric Content": "74 % COTTON, 26 % POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB102419",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '33.07"',
      "Vertical Repeat (in.)": '28.34"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Top Grain Leather",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TUSCANY FAWN ",
      "Fabric Content": "100% TOP GRAIN LEATHER",
      "Cleaning Code": "n/a",
      "Part #": "LEA100078",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "Sofa POP- Accents": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Leather",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "TUSSAH CHARCOAL",
      "Fabric Content": "100% POLYESTER ",
      "Cleaning Code": "WS",
      "Part #": "FAB200921",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "TWIST & TURN SPA",
      "Fabric Content": "73% POLYESTER, 27% COTTON",
      "Cleaning Code": "S",
      "Part #": "FAB201224",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "1/14/22",
      "Month Added": "JANUARY 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Scanned fabric",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "TWO TONE CHARCOAL",
      "Fabric Content": "88% POLYESTER, 12% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200200",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "TWO TONE SILVER SAGE",
      "Fabric Content": "88% POLYESTER, 12% NYLON",
      "Cleaning Code": "S",
      "Part #": "FAB200201",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "UPSTATE JET",
      "Fabric Content": "73% COTTON, 22% RAYON, 5% LINEN",
      "Cleaning Code": "S",
      "Part #": "FAB200171",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Canvas/Cotton",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "URBANSUEDE BUCKSKIN",
      "Fabric Content": "75% POLYURETHANE, 25% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102432",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Polyurethane",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "URBANSUEDE CHALK",
      "Fabric Content": "75% POLYURETHANE, 25% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102433",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Polyurethane",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "URBANSUEDE GRANITE",
      "Fabric Content": "75% POLYURETHANE, 25% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102436",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Polyurethane",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "URBANSUEDE MOONSHINE",
      "Fabric Content": "75% POLYURETHANE, 25% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102439",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Polyurethane",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "URBANSUEDE PACIFIC",
      "Fabric Content": "75% POLYURETHANE, 25% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102440",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Polyurethane",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "UTOPIA SAND",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB201230",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/7/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received 04/18/22",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VERONA ECRU",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102450",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VERONA SILVER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102451",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE ASHEN",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102453",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE COASTAL",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102454",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE HAZE",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102455",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE MELLOW",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102457",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE NOCTURNAL",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102458",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Macy's Exclusive",
      "Fabric Name/Color": "VIBE ORGANIC",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102459",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE SMOKEY",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102460",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE SOLITUDE",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102461",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE SUNKISSED",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102462",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE TRANQUIL",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102463",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIBE ZEST",
      "Fabric Content": "50% ACRYLIC, 50% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102465",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VICTORY BARLEY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102466",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VICTORY DENIM",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102467",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VICTORY IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102468",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VICTORY SMOKE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB102469",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "VILLA DIJON",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201158",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "VILLA MINT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201159",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VIRGO TEAL",
      "Fabric Content": "75% POLYESTER,  25% RAYON",
      "Cleaning Code": "S",
      "Part #": "FAB102475",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '7.44"',
      "Vertical Repeat (in.)": '3.54"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "VOSBURGH PEARL",
      "Fabric Content": "81% POLYESTER, 18% POLYESTER (UV)",
      "Cleaning Code": "WS",
      "Part #": "FAB201194",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "10/20/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '14.688"',
      "Vertical Repeat (in.)": '14.313"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WABANSIA SUNSHINE",
      "Fabric Content": "61% POLY UV 39% OLEFIN",
      "Cleaning Code": "WS",
      "Part #": "FAB200938",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.625"',
      "Vertical Repeat (in.)": '8.875"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WABI SABI VINTAGE",
      "Fabric Content": "83% RAYON, 17% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201219",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "1/13/22",
      "Month Added": "DECEMBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "PENDING",
      "Horizontal Repeat (in.)": '13.75"',
      "Vertical Repeat (in.)": '27"',
      "Solid or Pattern": "Pattern",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WAKA WAKA COCOA",
      "Fabric Content": "57% POLYESTER, 43% ACRLYIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200435",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "WALDEN PARCHMENT",
      "Fabric Content": "83% POLYESTER, 10% ACRYLIC, 7% LINEN",
      "Cleaning Code": "W",
      "Part #": "FAB200732",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      "Fabric Name/Color": "WALDEN SMOKE",
      "Fabric Content": "83% POLYESTER, 10% ACRYLIC, 7% LINEN",
      "Cleaning Code": "W",
      "Part #": "FAB200733",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Premium A",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WAZE MICA",
      "Fabric Content": "92% POLYESTER, 7% RAYON, 1%NYLON ",
      "Cleaning Code": "WS",
      "Part #": "FAB200137",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WESTON BONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102481",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WESTON GULL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB102483",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WILSHIRE PEPPER",
      "Fabric Content": "95% POLYESTER, 5% ACRYLIC",
      "Cleaning Code": "SW",
      "Part #": "FAB102532",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WINDING IVORY",
      "Fabric Content": "100% Polyester",
      "Cleaning Code": "S",
      "Part #": "FAB200440",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.7"',
      "Vertical Repeat (in.)": '13.7"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WINDING RUSSETT",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102529",
      "Thread ": "Crimson",
      "Thread Code": "SEW100022",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.7"',
      "Vertical Repeat (in.)": '13.7"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WOOFIT QUARTZ",
      "Fabric Content": "64% POLYESTER, 36% PRE-CRCOTTON",
      "Cleaning Code": "S",
      "Part #": "FAB102487",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14.563"',
      "Vertical Repeat (in.)": '16.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "WOOLY FLAX",
      "Fabric Content": "43% COTTON 34% RAYON 22% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102488",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '27.25"',
      "Vertical Repeat (in.)": '31.26"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "YANG BLUSH",
      "Fabric Content":
        "44% ACRYLIC, 38% TEXTURE POLYESTER, 11% POLYESTER, 7% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB200944",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "YANG CANYON",
      "Fabric Content":
        "44% ACRYLIC, 38% TEXTURE POLYESTER, 11% POLYESTER, 7% VISCOSE",
      "Cleaning Code": "WS",
      "Part #": "FAB201055",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '13.5"',
      "Vertical Repeat (in.)": '13.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZAIN DUSK",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102497",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.5"',
      "Vertical Repeat (in.)": '22.563"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZAIN TANGIER",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB102498",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.5"',
      "Vertical Repeat (in.)": '22.563"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Restricted Accent/Pillow",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZALA LIME",
      "Fabric Content": "71% ACETATE, 21% TEXTURED POLYESTER, 9% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201119",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "4/2/21",
      "Month Added": "APRIL / JUNE 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "Select Accents",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '14"',
      "Vertical Repeat (in.)": '25"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      "Fabric Name/Color": "ZANADU VIOLET",
      "Fabric Content": "77% RAYON, 23% POLYSTER",
      "Cleaning Code": "S",
      "Part #": "FAB102506",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
      Notes: "Not on Raymour's lineup, but Active in INFOR",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ZANDER CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200044",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ZANDER GOLD",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200181",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ZANDER INDIAN RED",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200045",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ZANDER INDIGO",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200183",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ZANDER IVORY",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "W",
      "Part #": "FAB200182",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZAZA GRAPHITE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB201016",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/1/20",
      "Month Added": "OCTOBER 2020",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '5.81"',
      "Vertical Repeat (in.)": '2.81"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces Exclusive",
      "Fabric Name/Color": "ZEAL MARBLE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "SW",
      "Part #": "FAB201176",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "9/20/21",
      "Month Added": "SEPTEMBER 2021",
      "Jonathan Louis (JL)": "No",
      "JL Design Lab": "No",
      "Just Live™ Performance Fabric": "No",
      "Fabric Image": "Received",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZEFFA FLAX",
      "Fabric Content": "91% OLEFIN, 9% POLYESTER",
      "Cleaning Code": "WS",
      "Part #": "FAB200452",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '9.5"',
      "Vertical Repeat (in.)": '12.5"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZEUS PEARL",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "WS",
      "Part #": "FAB201228",
      "Thread ": "White",
      "Thread Code": "SEW100026",
      "Date Added": "4/7/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "JLA",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZEUS SILVER",
      "Fabric Content": "100% POLYPROPYLENE",
      "Cleaning Code": "WS",
      "Part #": "FAB201229",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "4/7/22",
      "Month Added": "APRIL 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "JLA",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Accent/Pillow ",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZIGGY APRICOT",
      "Fabric Content": "73% POLYESTER, 27% OLEFIN",
      "Cleaning Code": "WS",
      "Part #": "fab200011",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '28.313"',
      "Vertical Repeat (in.)": '15"',
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZION CHARCOAL",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201211",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/28/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZION FOREST",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201209",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/28/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZION KHAKI",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201208",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "10/28/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Fabric Image": "Received",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZION MARINE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201210",
      "Thread ": "Black",
      "Thread Code": "SEW100021",
      "Date Added": "10/28/21",
      "Month Added": "OCTOBER 2021",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZION STONE",
      "Fabric Content": "100% POLYESTER",
      "Cleaning Code": "S",
      "Part #": "FAB201299",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Month Added": "SEPTEMBER 2022",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": '0"',
      "Vertical Repeat (in.)": '0"',
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZURI FOG",
      "Fabric Content": "83% POLYESTER, 17% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200342",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZURI MIST",
      "Fabric Content": "83% POLYESTER, 17% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200340",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZURI NATURAL",
      "Fabric Content": "83% POLYESTER, 17% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200341",
      "Thread ": "Sand",
      "Thread Code": "SEW100058",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZURI PEWTER",
      "Fabric Content": "83% POLYESTER, 17% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200330",
      "Thread ": "Midnight Grey",
      "Thread Code": "SEW100024",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Grade/Pricing Tier": "Basic",
      "Fabric Selection ": "JL Upholstery",
      "Fabric Name/Color": "ZURI STONE",
      "Fabric Content": "83% POLYESTER, 17% ACRYLIC",
      "Cleaning Code": "WS",
      "Part #": "FAB200329",
      "Thread ": "Dark Brown",
      "Thread Code": "SEW100023",
      "Date Added": "n/a",
      "Jonathan Louis (JL)": "Yes",
      "JL Design Lab": "Yes",
      "Just Live™ Performance Fabric": "No",
      "JL- Stationary": "l",
      "JL- Bedroom": "l",
      "JL- Accessories": "l",
      "JL- Toss Pillows": "l",
      "JL Design Lab- Sofa & Sectional": "l",
      "JL Design Lab- Bedroom": "l",
      "Sofa POP- Accents": "l",
      "Sofa POP- Pillows": "l",
      "Horizontal Repeat (in.)": "n/a",
      "Vertical Repeat (in.)": "n/a",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
    },
    {
      "Fabric Name/Color": "Brighton Dark Pine",
      "Part #": "####",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Fabric Name/Color": "Harness Charcoal",
      "Part #": "####",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Fabric Name/Color": "Holland Pearl",
      "Part #": "####",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Fabric Name/Color": "Larsen Sandcastle",
      "Part #": "####",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Fabric Name/Color": "Tribeca Oyster",
      "Part #": "####",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    {
      "Fabric Name/Color": "Winchester Balsam",
      "Part #": "####",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
    },
    // new

    {
      "Fabric Name/Color": "Aldous Honey",
      "Part #": "FAB201287",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Aquinto Canyon",
      "Part #": "FAB200046",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Bamford Clay",
      "Part #": "FAB201305",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Crimson",
    },
    {
      "Fabric Name/Color": "Bonelli Mineral",
      "Part #": " FAB201247",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Brixie Natural",
      "Part #": "FAB201298",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Caleb Copper",
      "Part #": "FAB201304",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Canyon Putty",
      "Part #": "FAB201471",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Catalina Pepper",
      "Part #": "FAB201189",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Cavandish Tobacco",
      "Part #": "FAB201557",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Cortez Lava",
      "Part #": "FAB201201",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Cuba Bark",
      "Part #": "FAB201282",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Cyrus Clay",
      "Part #": "FAB201291",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Data Sand",
      "Part #": "FAB201480",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Degas Dune",
      "Part #": "FAB201620",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Dominick Driftwood",
      "Part #": "FAB201307",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Elmdorf Bit Of Sugar",
      "Part #": "FAB201564",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Farah Bisque",
      "Part #": "FAB201462",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Fascinate Versatile",
      "Part #": "FAB101588",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Gaga Cuba",
      "Part #": "FAB201558",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Goertel Copper",
      "Part #": "FAB201555",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Grogan Denim",
      "Part #": "FAB201296",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Hakimi Ivory",
      "Part #": "FAB201308",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Hendifar Driftwood",
      "Part #": "FAB201618",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Jaspar Desert",
      "Part #": " FAB201245",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Joker Moonstone",
      "Part #": "FAB201475",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Kali Porcelain",
      "Part #": "FAB201630",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Fabric Name/Color": "Kanjari Cashew",
      "Part #": "FAB201378",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Katalina Shell",
      "Part #": "FAB200132",
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Keebler Tuxedo",
      "Part #": "FAB200332",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blacks & Purples",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Kelly Ivory",
      "Part #": "FAB201478",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Kikkan Fresco",
      "Part #": "FAB201556",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Pinks, Reds, Oranges",
    },
    {
      "Fabric Name/Color": "Klonicki Tranquil",
      "Part #": "FAB201546",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Krasna Tortoise",
      "Part #": "FAB201626",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Browns",
    },
    {
      "Fabric Name/Color": "Lafayette Mica",
      "Part #": "FAB201482",
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Letterman Copper",
      "Part #": "FAB201286",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Llama Fur Black",
      "Part #": "FAB101848",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Blacks & Purples",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Llama Fur Charcoal",
      "Part #": "FAB101849",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Llama Fur Snow White",
      "Part #": "FAB101852",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Lucienne Folklore",
      "Part #": "FAB201627",
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Fabric Name/Color": "Luna Fog",
      "Part #": "FAB201472",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Lyman Cornsilk",
      "Part #": "FAB201368",
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Greens & Yellows",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Madani Celadon",
      "Part #": "FAB201464",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Mahler Grove",
      "Part #": "FAB201559",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Maldon Copper",
      "Part #": "FAB201289",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Marbella Tannery",
      "Part #": "FAB201465",
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Masterpiece Turmeric",
      "Part #": "FAB200139",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Melba Moon",
      "Part #": "FAB201461",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Moonwalk Nutmeg",
      "Part #": "FAB201131",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Browns",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Ocampo Dusk",
      "Part #": "FAB102006",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Ollieroo Sisal",
      "Part #": "FAB201246",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Orlo Ivory",
      "Part #": "FAB201339",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Pax Canvas",
      "Part #": "FAB201463",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Pearl Leopard Snuggle",
      "Part #": "FAB102057",
      "Solid or Pattern": "Pattern",
      "Material Type": "Faux Fur",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Pirelli Asphalt",
      "Part #": "FAB200131",
      "Solid or Pattern": "Pattern",
      "Material Type": "Chenille",
      "Color Category": "Blacks & Purples",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Punch Line Linen",
      "Part #": "FAB201619",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Renew Dawn",
      "Part #": "FAB201481",
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Rodin Alabaster",
      "Part #": "FAB201634",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Fabric Name/Color": "Rodman Amber",
      "Part #": "FAB201297",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Rodman Lagoon",
      "Part #": "FAB201306",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "San Marco Pewter",
      "Part #": "FAB201469",
      "Solid or Pattern": "Pattern",
      "Material Type": "Print",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Santorini Laurel",
      "Part #": "FAB201552",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Saruri Mist",
      "Part #": "FAB201477",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Blues",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Scribble Indigo",
      "Part #": "FAB102208",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Shapan Ink",
      "Part #": "FAB201223",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jacquard",
      "Color Category": "Blues",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Shinedown Fresco",
      "Part #": "FAB201628",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Pinks, Reds, Oranges",
    },
    {
      "Fabric Name/Color": "Sidetracks Coconut",
      "Part #": "FAB201476",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Skoog Dove",
      "Part #": "FAB201338",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Striker Copper",
      "Part #": "FAB201544",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Swardson Stone",
      "Part #": "FAB201367",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Tika Jade",
      "Part #": "FAB201057",
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Time Square Harbour",
      "Part #": "FAB102403",
      "Solid or Pattern": "Pattern",
      "Material Type": "Velvet",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Ullman Desert",
      "Part #": "FAB201484",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Ullman Vanilla",
      "Part #": "FAB201531",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Vacarello Indigo",
      "Part #": "FAB201479",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Blues",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Vundabar Celadon",
      "Part #": "FAB201329",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Zendejas Moss",
      "Part #": "FAB201625",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Zingman Beach",
      "Part #": "FAB201624",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Amici Denim",
      "Part #": "FAB201486",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Amici Oyster",
      "Part #": "FAB201485",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Artisan Praline",
      "Part #": "FAB201244",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Aspen Charcoal",
      "Part #": "FAB201195",
      "Solid or Pattern": "Solid",
      "Material Type": "Faux Fur",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Aston Truffle",
      "Part #": "FAB201205",
      "Solid or Pattern": "Solid",
      "Material Type": "Print",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Aston Whisper",
      "Part #": "FAB201206",
      "Solid or Pattern": "Solid",
      "Material Type": "Print",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Bella Hunter Green",
      "Part #": "FAB101102",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Belmont Clay",
      "Part #": "FAB201185",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Belmont Jade",
      "Part #": "FAB201186",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Belmont Mink",
      "Part #": "FAB201184",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Belmont Platinum",
      "Part #": "FAB201207",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Belmont Rose",
      "Part #": "FAB201220",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Bianca Maize",
      "Part #": "FAB201457",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Bianca Moss",
      "Part #": "FAB201458",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Bianca Prussian",
      "Part #": "FAB201460",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Bianca Rosewood",
      "Part #": "FAB201483",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Bianca Willow",
      "Part #": "FAB201459",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Brazza Dove",
      "Part #": "FAB201346",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Bungalow Ash",
      "Part #": "FAB201487",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Bungalow Snow",
      "Part #": "FAB200949",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Caprice Charcoal",
      "Part #": "FAB101269",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Caprice Granite",
      "Part #": "FAB101273",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Caprice Hemp",
      "Part #": "FAB101274",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Caprice Java",
      "Part #": "FAB101275",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Caprice Khaki",
      "Part #": "FAB101276",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Caprice Midnight",
      "Part #": "FAB101278",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Caprice Mink",
      "Part #": "FAB101279",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Celine Mist",
      "Part #": "FAB201341",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Celine Oatmeal",
      "Part #": "FAB201340",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Celine Pebble",
      "Part #": "FAB201342",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Celine Teal",
      "Part #": "FAB201473",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Common Thread Eucalyptus",
      "Part #": "FAB201470",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Contessa Dove",
      "Part #": "FAB201143",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Contessa Dusk",
      "Part #": "FAB201029",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blacks & Purples",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Contessa Ivory",
      "Part #": "FAB201144",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Euphoria Manatee Bay",
      "Part #": "FAB201145",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Euphoria New Moon",
      "Part #": "FAB201146",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Euphoria White Dove",
      "Part #": "FAB201148",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Friar Stone",
      "Part #": "FAB201474",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greys & Charcoals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Glen Ellen Denim",
      "Part #": "FAB201138",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Glen Ellen Stone",
      "Part #": "FAB201128",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Greys & Charcoal",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Goodwin Latte",
      "Part #": "FAB201468",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Goodwin Pebble",
      "Part #": "FAB201348",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Gypsy Crème",
      "Part #": "FAB101699",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Gypsy Slate",
      "Part #": "FAB101714",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Heavenly Café",
      "Part #": "FAB101733",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Henequen Oatmeal",
      "Part #": "FAB201551",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Henequen Pearl",
      "Part #": "FAB201617",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Henequen Slate",
      "Part #": "FAB201616",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Lakelawn Natural",
      "Part #": "FAB201120",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Lovely Dove",
      "Part #": "FAB201355",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Lovely French Blue",
      "Part #": "FAB201356",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Lovely Russet",
      "Part #": "FAB201354",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Marquis Adobe",
      "Part #": "FAB201547",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Marquis Surf",
      "Part #": "FAB201548",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Matisse Camel",
      "Part #": "FAB201344",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Matisse Jade",
      "Part #": "FAB201343",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Matisse Oyster",
      "Part #": "FAB201345",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Maven Carbon",
      "Part #": "FAB201715",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoals",
    },
    {
      "Fabric Name/Color": "Maven Forest",
      "Part #": "FAB201631",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
    },
    {
      "Fabric Name/Color": "Maven Rust",
      "Part #": "FAB201632",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pinks, Reds, Oranges",
    },
    {
      "Fabric Name/Color": "Maven Sky",
      "Part #": "FAB201718",
    },
    {
      "Fabric Name/Color": "Mesero Fog",
      "Part #": "FAB201621",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Mesero Latte",
      "Part #": "FAB201622",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Metaphor Jetset",
      "Part #": "FAB201190",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Metaphor Winter",
      "Part #": "FAB201191",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Muse Stone",
      "Part #": "FAB201149",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Napa Vintage",
      "Part #": "FAB200343",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoal",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Nottingham Slate",
      "Part #": "FAB201302",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Midnight Grey",
    },
    {
      "Fabric Name/Color": "Nottingham Tuxedo",
      "Part #": "FAB201301",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blacks & Purples",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Queen Bey Cypress",
      "Part #": "FAB201563",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Queen Bey Daffodil",
      "Part #": "FAB201554",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Queen Bey Flax",
      "Part #": "FAB201553",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greys & Charcoals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Rowdy Clay",
      "Part #": "FAB201700",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
    },
    {
      "Fabric Name/Color": "Royale Coral",
      "Part #": "FAB200471",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Pink, Red, & Orange",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Talia Cream",
      "Part #": "FAB201466",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Talia Glacier",
      "Part #": "FAB201635",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
    },
    {
      "Fabric Name/Color": "Tildan Almond",
      "Part #": "FAB201453",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Tildan Oatmeal",
      "Part #": "FAB201455",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greys & Charcoals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Tildan Saddle",
      "Part #": "FAB201456",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Tildan Seafoam",
      "Part #": "FAB201454",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Tira Inlet",
      "Part #": "FAB201633",
      "Solid or Pattern": "Pattern",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
    },
    {
      "Fabric Name/Color": "Tramore Amaretto",
      "Part #": "FAB201225",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Tramore Bisque",
      "Part #": "FAB201533",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Tramore Evening",
      "Part #": "FAB201549",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Tramore Minted",
      "Part #": "FAB201560",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Tramore Sedona",
      "Part #": "FAB201561",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Tramore Sienna",
      "Part #": "FAB201532",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Dark Brown",
    },
    {
      "Fabric Name/Color": "Zayden Honey",
      "Part #": "FAB201550",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Browns",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Zayden Olive",
      "Part #": "FAB201545",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Greens & Yellows",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Monterrey Pebble",
      "Part #": "FAB101971",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Opulent Polar",
      "Part #": "FAB102014",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Premiere Stone",
      "Part #": "FAB102110",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Whites & Neutrals",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Bonnie Meadow Bersano",
      "Part #": "FAB201328",
      "Solid or Pattern": "Pattern",
      "Material Type": "Jaquard",
      "Color Category": "Greens & Yellows",
      "Thread ": "Sand",
    },
    {
      "Fabric Name/Color": "Camila Olive",
      "Part #": "FAB201309",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Greens & Yellows",
      "Thread ": "Black",
    },
    {
      "Fabric Name/Color": "Camila Sand",
      "Part #": "FAB201303",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Common Thread Eucalyptus",
      "Part #": "FAB201470",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Queen Bey Celedon",
      "Part #": "FAB201562",
      "Solid or Pattern": "Solid",
      "Material Type": "Woven Texture",
      "Color Category": "Blues",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Mesero Oat",
      "Part #": "FAB201731",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },
    {
      "Fabric Name/Color": "Mesero Spice",
      "Part #": "FAB201717",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Pinks, Reds, Oranges",
      "Thread ": "Red",
    },
    {
      "Fabric Name/Color": "Mesero Surf",
      "Part #": "FAB201733",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Blues",
      "Thread ": "Blue",
    },
    {
      "Fabric Name/Color": "Parks Jade",
      "Part #": "FAB201723",
      "Solid or Pattern": "Solid",
      "Material Type": "Linen Look",
      "Color Category": "Blues",
      "Thread ": "Green",
    },
    {
      "Fabric Name/Color": "Toscana Olive",
      "Part #": "FAB201719",
      "Solid or Pattern": "Solid",
      "Material Type": "Velvet",
      "Color Category": "Greens & Yellows",
      "Thread ": "Green",
    },
    {
      "Fabric Name/Color": "Henequen Ivory",
      "Part #": "FAB201726",
      "Solid or Pattern": "Solid",
      "Material Type": "Chenille",
      "Color Category": "Whites & Neutrals",
      "Thread ": "White",
    },

  ],
  "DropDown-IGNORE": [
    {
      Grade: "Basic",
      "Fabric Selection ": "JL Upholstery",
      Thread: "Black",
      "JL item no.": "SEW100021",
      "Solid or Pattern": "Solid",
      "Jonathan Louis ": "All JL Items",
      "JL Design Lab": "All JLDL Items",
      "Just Live™ Performance Fabrics": "Bleach Cleanable",
      "Type of Fabric": "Canvas/ Cotton",
      "Color Family": "Blacks & Purples",
      "Yes(Y) or No(N)": "Yes",
    },
    {
      Grade: "Premium A",
      "Fabric Selection ": "JL Design Lab Exclusive",
      Thread: "Crimson",
      "JL item no.": "SEW100022",
      "Solid or Pattern": "Pattern",
      "Jonathan Louis ": "JL Accessories",
      "JL Design Lab": "All Sofa POP",
      "Just Live™ Performance Fabrics": "Durable 30,000 DR",
      "Type of Fabric": "Chenille",
      "Color Family": "Blues",
      "Yes(Y) or No(N)": "No",
    },
    {
      Grade: "Premium B ",
      "Fabric Selection ": "Macy's Exclusive",
      Thread: "Dark Brown",
      "JL item no.": "SEW100023",
      "Jonathan Louis ": "JL Bedroom",
      "JL Design Lab": "JLDL Bedroom",
      "Just Live™ Performance Fabrics": "Fade Resistant",
      "Type of Fabric": "Linen Look",
      "Color Family": "Browns",
    },
    {
      Grade: "Premium C ",
      "Fabric Selection ": "Living Spaces Exclusive",
      Thread: "Midnight Grey",
      "JL item no.": "SEW100024",
      "Jonathan Louis ": "JL Stationary",
      "JL Design Lab": "JLDL Dining",
      "Just Live™ Performance Fabrics": "Moisture Repellent",
      "Type of Fabric": "Velvet",
      "Color Family": "Greens & Yellows",
    },
    {
      Grade: "Accent/Pillow ",
      "Fabric Selection ": "Living Spaces- N&J Exclusive",
      Thread: "Sand",
      "JL item no.": "SEW100058",
      "Jonathan Louis ": "JL Toss Pillows",
      "JL Design Lab": "JLDL Living Room",
      "Just Live™ Performance Fabrics": "Soap Cleanable",
      "Type of Fabric": "Woven Texture",
      "Color Family": "Greys & Charcoal",
    },
    {
      Grade: "Top Grain Leather",
      "Fabric Selection ": "Raymour & Flanigan Exclusive",
      Thread: "White",
      "JL item no.": "SEW100026",
      "Jonathan Louis ": "Select JL Accents",
      "JL Design Lab": "JLDL Sofa & Sectional",
      "Just Live™ Performance Fabrics": "Stain Resistant",
      "Type of Fabric": "Faux Fur",
      "Color Family": "Pink, Red, & Orange",
    },
    {
      Grade: "Bonded Leather",
      "Fabric Selection ": "Interline Exclusive",
      "Jonathan Louis ": "Select JL Bedroom",
      "JL Design Lab": "Sofa POP Accents",
      "Type of Fabric": "Polyurethane",
      "Color Family": "Whites & Neutrals",
    },
    {
      Grade: "Restricted Basic",
      "Fabric Selection ": "Haverty's Exclusive",
      "Jonathan Louis ": "Select JL Stationary",
      "JL Design Lab": "Sofa POP Pillows",
      "Type of Fabric": "Jacquard",
    },
    {
      Grade: "Restricted Premium A",
      "Fabric Selection ": "Jordans Exclusive",
      "Type of Fabric": "Geometric",
    },
    {
      Grade: "Restricted Premium B",
      "Fabric Selection ": "Rooms 2 Go Exclusive",
      "Type of Fabric": "Print",
    },
    {
      Grade: "Restricted Premium C",
      "Fabric Selection ": "Z Gallerie Exclusive",
      "Type of Fabric": "Leather",
    },
    {
      Grade: "Restricted Accent/Pillow",
      "Fabric Selection ": "RC Willey Exclusive",
    },
    {
      Grade: "Accent/Pillow & Premium A",
      "Fabric Selection ": "CSWO Exclusive",
    },
    {
      Grade: "Accent/Pillow & Premium B",
      "Fabric Selection ": "CB2",
    },
    {
      Grade: "Accent/Pillow & Premium C",
    },
    {
      Grade: "Accent/Pillow & Restricted ",
    },
    {
      Grade: "SUNBRELLA- Accent C",
    },
    {
      Grade: "SUNBRELLA-BASIC",
    },
    {
      Grade: "SUNBRELLA-PREMIUM A",
    },
    {
      Grade: "SUNBRELLA-PREMIUM B",
    },
    {
      Grade: "SUNBRELLA-PREMIUM C",
    },
    {
      Grade: "SUNBRELLA- Accent A",
    },
    {
      Grade: "SUNBRELLA- Accent B",
    },
  ],
};

export default filterRules;
