import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { Tooltip } from "antd";
import styled from "styled-components";

import "antd/lib/tooltip/style/index.css";
import "antd/lib/switch/style/index.css";
import s from "./SwitchCenter.module.scss";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { setActiveParamsUI } from "../../redux/features/ui/uiSlice";
import { changeThreekitConfiguration } from "../../redux/actions/player.action";
import { getActiveParamFromIdComponentUI } from "../../redux/selectors/ui.selector";

let StyledSelect = styled(Switch)` 
    &.ant-switch{
        background: #F47963 !important;
    } 
    &.ant-switch-checked{
        background: #F47963 !important;
    } 
    &.ant-switch:focus {
        box-shadow: none !important;
    } 
    &.ant-switch-checked:focus{
        box-shadow: none !important;
    }
     &.ant-switch .ant-click-animating-node{
        box-shadow: none !important;
        -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1)  !important;
    }
    @keyframes waveEffect{
        100% {box-shadow: 0 0 0 6px #425d76; !important; }
    }
`;

export const SwitchCenter = ({ ...props }) => {
  const { id, label, values, value, typeOptions } = props;

  const changeSwitchCenter = (valueNew: boolean) => {
    setSwitchStatus(valueNew);
    dispatch(changeThreekitConfiguration({ [id]: valueNew }));
  };
  const dispatch = useAppDispatch();
  const valueUI = useAppSelector(getActiveParamFromIdComponentUI(id));
  const activeValue = !!valueUI ? valueUI : value;
  const [switchStatus, setSwitchStatus] = useState(activeValue);

  useEffect(() => {
    dispatch(setActiveParamsUI({ [id]: switchStatus }));
  }, []);

  const revertValue =
    switchStatus === values[0].value ? values[1].value : values[0].value;

  return (
    <div className={s.switch}>
      {!!label && (
        <p className={s.title}>
          {label}{" "}
          <Tooltip
            title={"Hii, I am sample tooltip."}
            placement="right"
            mouseLeaveDelay={0}
            trigger={["hover", "click"]}
          >
            {" "}
            <img
              src="/images/assets/svg/InfoTooltip.svg"
              alt=""
            />{" "}
          </Tooltip>
        </p>
      )}
      <div className={s.SwitchCenter}>
        <label className={s.label1}>{values[0].label}</label>
        <StyledSelect
          checked={values[1].value === switchStatus}
          onChange={() => changeSwitchCenter(revertValue)}
        />
        <label className={s.label2}>{values[1].label}</label>
      </div>
    </div>
  );
};
