import React from "react";
import { useLocation } from "react-router-dom";
import { getCountAssets } from "../../../redux/selectors/ui.selector";
import { useAppSelector } from "../../../utils/hooks";
import s from "./CounterOption.module.scss";

export const CounterOption = ({ ...props }) => {
  const { parentId, id } = props;
  const { pathname } = useLocation();

  const count = useAppSelector(getCountAssets(parentId, id));

  if (pathname.includes("config")) {
    return count > 0 ? <div className={s.option_count}>x{count}</div> : <></>;
  }

  return <></>;
};
