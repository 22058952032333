export const rayCreation = (pillowId: string) => {
  const selectedPillowPosition = window.player.scene.get({
    id: pillowId,
    evalNode: true,
  }).node.plugs.Transform[0].translation;
  const point = new window.player.THREE.Vector3(
    selectedPillowPosition.x,
    selectedPillowPosition.y,
    selectedPillowPosition.z
  );
  const origin = window.player.camera.getPosition();
  const direction = point
    .clone()
    .sub(window.player.camera.getPosition())
    .normalize();
  const ray = new window.player.THREE.Ray(origin, direction);

  return {ray, point};
};
