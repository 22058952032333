import { modelsBedroom } from "./modelsBedroom";
import { modelsChairs } from "./modelsChairs";
import { modelsClassicSofa } from "./modelsClassicSofa";
import { modelsHeadboards } from "./modelsHeadboards";
import { modelsOttomans } from "./modelsOttomans";
import { modelsSectionalPresets } from "./modelsSectionalPresets";
import { modelsSectionalSections } from "./modelsSectionalSections";

export const Models = {
  sofa: [
    {
      title: "Start from scratch",
      subtitle: "Please select a product type to begin customization",
      values: modelsClassicSofa,
    },
  ],
  sectional: [
    {
      title: "Build Your Own Sectional",
      subtitle: "Please select a piece to begin customization",
      values: modelsSectionalSections,
    },
    {
      title: "Or start with our Bestsellers",
      subtitle: "Select one of the top 3 configurations to start customizing",
      values: modelsSectionalPresets,
    },
  ],
  ottoman: [
    {
      title: "Start from scratch",
      subtitle: "Please select a product type to begin customization",
      values: modelsOttomans,
    },
  ],
  pillows: [],
  chair: [
    {
      title: "Start from scratch",
      subtitle: "Please select a product type to begin customization",
      values: modelsChairs,
    },
  ],
  bed: [
    {
      title: "Start from scratch",
      subtitle: "Please select a product type to begin customization",
      values: modelsBedroom,
    },
    {
      title: "Or start with headboards only",
      subtitle: "Please select a headboard type to begin customization",
      values: modelsHeadboards,
    },
  ],
};
