import filterRules from "./Constants/filterRules";
import { getPillowMaterialInModel } from "./threekit/attributesFunctions";

export function compareStrings(str1: any, str2: any) {
  // Используем метод includes(), чтобы проверить, содержит ли str1 подстроку из str2
  if (str1.includes(str2)) {
    return true;
  }

  // Используем метод includes(), чтобы проверить, содержит ли str2 подстроку из str1
  if (str2.includes(str1)) {
    return true;
  }

  // Если ни одна из строк не содержит подстроку из другой строки, возвращаем false
  return false;
}

export const filterFabrics = (
  valueFromThreekit: any[],
  filterObj: { [key: string]: string }
) => {
  let filterList: any[] = [];
  let filterValues: any[] = [];

  Object.keys(filterObj).forEach((key) => {
    const value = filterObj[key];
    const filterArray =
      filterList.length === 0 ? filterRules["CURRENT"] : filterList;

    if (value.length === 0) {
      return;
    }

    if (key.toLowerCase() === "pattern or solid") {
      filterList = filterArray.filter((item) => {
        if (
          item["Solid or Pattern"] &&
          value.includes(item["Solid or Pattern"])
        ) {
          return true;
        }

        return false;
      });
    }

    if (key.toLowerCase() === "material") {
      filterList = filterArray.filter((item) => {
        if (item["Material Type"] && value.includes(item["Material Type"])) {
          return true;
        }

        return false;
      });
    }

    if (key.toLowerCase() === "color") {
      filterList = filterArray.filter((item) => {
        if (item["Color Category"] && value.includes(item["Color Category"])) {
          return true;
        }

        return false;
      });
    }

    if (key.toLowerCase() === "text") {
      filterList = filterArray.filter((item) => {
        return compareStrings(
          item["Fabric Name/Color"].toLowerCase(),
          value.toLowerCase()
        );
      });
    }
  });

  if (filterList.length > 0) {
    filterValues = valueFromThreekit.filter((value: any) => {
      const findInFilter = filterList.find((item) => {
        let sku = value.metadata.sku ? value.metadata.sku : undefined;
        const labelStr = value.label.toLowerCase().trim().replace("_", " ");
        const itemStr = item["Fabric Name/Color"]
          .toLowerCase()
          .trim()
          .replace("_", " ");
        return sku === item["Part #"];
        // return compareStrings(sku, item["Part #"]);
        // return labelStr.includes(itemStr);
      });

      if (findInFilter) {
        return true;
      }

      return false;
    });
  }

  filterValues.sort((a: any, b: any) => a.label.localeCompare(b.label));
  return filterValues;
};

const getFilteredValuesThreekitFromArrMaterials = (
  arrMaterials: any[],
  arrValueFromThreekit: any[]
) => {
  if (arrMaterials.length < 1) return [];
  const filterValuesThreekit = arrValueFromThreekit.filter((value: any) => {
    let sku =
      value.metadata && value.metadata.sku ? value.metadata.sku : undefined;
    const findInFilter = arrMaterials.find((item) => sku === item["Part #"]);
    if (findInFilter) {
      return true;
    }
    return false;
  });
  return filterValuesThreekit;
};

export type FiltersControlsT = {
  [key in string]: {
    name: string;
    values: {
      value: string;
      label: string;
      disabled?: boolean;
    }[];
  };
};

export const getActiveFilterControlsValues = (
  filterObj: { [key: string]: string },
  valueFromThreekit: any[],
  activeFilters: any,
  changedFilterKey: string
): FiltersControlsT => {
  const patternOrSolidValue = filterObj["Pattern or Solid"];
  const materialValue = filterObj["Material"];
  const colorValue = filterObj["Color"];

  let objFiltersControls: FiltersControlsT = {
    "Pattern or Solid": {
      name: "Pattern or Solid",
      values: [
        {
          value: "",
          label: "Select All",
        },
      ],
    },
    Material: {
      name: "Material",
      values: [
        {
          value: "",
          label: "Select All",
        },
      ],
    },
    Color: {
      name: "Color",
      values: [
        {
          value: "",
          label: "Select All",
        },
      ],
    },
  };

  let arrFilteredMaterials: any[] = [];

  filterRules["CURRENT"].forEach((element: any) => {
    const solidOrPattern = element["Solid or Pattern"];
    const material = element["Material Type"];
    const color = element["Color Category"];

    const isFilteredValue =
      (!!patternOrSolidValue ? patternOrSolidValue === solidOrPattern : true) &&
      (!!materialValue ? materialValue === material : true) &&
      (!!colorValue ? colorValue === color : true);

    if (isFilteredValue) {
      arrFilteredMaterials.push(element);
    }

    if (!!solidOrPattern) {
      const indexFilterOptionValue = objFiltersControls["Pattern or Solid"][
        "values"
      ].findIndex((objValue) => objValue["value"] === solidOrPattern);
      if (indexFilterOptionValue === -1) {
        objFiltersControls["Pattern or Solid"]["values"].push({
          value: solidOrPattern,
          label: solidOrPattern,
          disabled: false,
        });
      }
    }

    if (!!material) {
      const indexFilterOptionValue = objFiltersControls["Material"][
        "values"
      ].findIndex((objValue) => objValue["value"] === material);
      if (indexFilterOptionValue === -1) {
        objFiltersControls["Material"]["values"].push({
          value: material,
          label: material,
          disabled: false,
        });
      }
    }

    if (!!color) {
      const indexFilterOptionValue = objFiltersControls["Color"][
        "values"
      ].findIndex((objValue) => objValue["value"] === color);
      if (indexFilterOptionValue === -1) {
        objFiltersControls["Color"]["values"].push({
          value: color,
          label: color,
          disabled: false,
        });
      }
    }
  });

  Object.keys(objFiltersControls).forEach((filterKey: string) => {
    const arrValues = objFiltersControls[filterKey]["values"];

    if (filterKey === changedFilterKey && filterObj[filterKey] !== "") {
      objFiltersControls[filterKey] =
        !!activeFilters && !!activeFilters[filterKey]
          ? activeFilters[filterKey]
          : objFiltersControls[filterKey];
      return;
    }

    arrValues.forEach((value) => {
      if (!!!value["value"]) return;

      if (filterKey === "Pattern or Solid") {
        const filterOptionValue = arrFilteredMaterials.filter(
          (objValue) => objValue["Solid or Pattern"] === value["value"]
        );
        const filteredValuesThreekit =
          getFilteredValuesThreekitFromArrMaterials(
            filterOptionValue,
            valueFromThreekit
          );
        if (filteredValuesThreekit.length < 1) value["disabled"] = true;
      }

      if (filterKey === "Material") {
        const filterOptionValue = arrFilteredMaterials.filter(
          (objValue) => objValue["Material Type"] === value["value"]
        );
        const filteredValuesThreekit =
          getFilteredValuesThreekitFromArrMaterials(
            filterOptionValue,
            valueFromThreekit
          );
        if (filteredValuesThreekit.length < 1) value["disabled"] = true;
      }

      if (filterKey === "Color") {
        const filterOptionValue = arrFilteredMaterials.filter(
          (objValue) => objValue["Color Category"] === value["value"]
        );
        const filteredValuesThreekit =
          getFilteredValuesThreekitFromArrMaterials(
            filterOptionValue,
            valueFromThreekit
          );
        if (filteredValuesThreekit.length < 1) value["disabled"] = true;
      }
    });
  });

  return objFiltersControls;
};

export const filterFabricUI = [
  {
    name: "Pattern or Solid",
    values: [
      {
        value: "",
        label: "Select All",
      },
      {
        value: "Pattern",
        label: "Pattern",
      },
      {
        value: "Solid",
        label: "Solid",
      },
    ],
  },
  {
    name: "Material",
    values: [
      {
        value: "",
        label: "Select All",
      },
      {
        value: "Canvas/Cotton",
        label: "Canvas/Cotton",
      },
      {
        value: "Chenille",
        label: "Chenille",
      },
      {
        value: "Linen Look",
        label: "Linen Look",
      },
      {
        value: "Velvet",
        label: "Velvet",
      },
      {
        value: "Woven Texture",
        label: "Woven Texture",
      },
      {
        value: "Faux Fur",
        label: "Faux Fur",
      },
      {
        value: "Polyurethane",
        label: "Polyurethane",
      },
      {
        value: "Jacquard",
        label: "Jacquard",
      },
      {
        value: "Geometric",
        label: "Geometric",
      },
      {
        value: "Print",
        label: "Print",
      },
      {
        value: "Leather",
        label: "Leather",
      },
    ],
  },
  {
    name: "Color",
    values: [
      {
        value: "",
        label: "Select All",
      },
      {
        label: "Blacks & Purples",
        value: "Blacks & Purples",
      },
      {
        label: "Blues",
        value: "Blues",
      },
      {
        label: "Browns",
        value: "Browns",
      },
      {
        label: "Greens & Yellows",
        value: "Greens & Yellows",
      },
      {
        label: "Greys & Charcoals",
        value: "Greys & Charcoals",
      },
      {
        label: "Pinks, Reds, Oranges",
        value: "Pink, Red, & Orange",
      },
      {
        label: "Whites & Neutrals",
        value: "Whites & Neutrals",
      },
    ],
  },
];
