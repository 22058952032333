import React from "react";
import {
    getActiveParamFromIdComponentUI,
    getValuesFromIdAttribute,
} from "../../redux/selectors/ui.selector";
import { finishTypesForImgRadiobutton } from "../../utils/Constants/finishTypesForImgRadiobutton";
import { useAppSelector } from "../../utils/hooks";
import { ImgRadio } from "../ImgRadio/ImgRadio";
//@ts-ignore
import s from "./FinishGroup.module.scss";

export const FinishGroup = ({ ...props }: any) => {
    const { id, label, typeOptions, values, additionalProperties } = props;

    const valuesOptionsFromThreekit = useAppSelector((state) =>
        getValuesFromIdAttribute(id)(state)
    );
    const valueActiveParamUILegsSelector = useAppSelector(getActiveParamFromIdComponentUI("leg"));

    const changeOptional = (info: any) => {
        //dispatch id: value
    };

    const isMetalLegs =
        !!valueActiveParamUILegsSelector &&
        valueActiveParamUILegsSelector.includes("metal");

    const StyledLabel = () => {
        if (!!label && label.includes("Step")) {
            const stepPart = label.split(".")[0] + ".";
            const labelPart = label.split(".")[1];
            return (
                <div className={s.finishGroupTitle}>
                    <span className={s.finishGroupTitle_accent}>{stepPart}</span>
                    {labelPart}
                </div>
            );
        } else {
            return <div className={s.finishGroupTitle}>{label}</div>;
        }
    };

    return (
        <div className={s.finishGroup}>
            {!!label && <StyledLabel />}
            <div className={s.finishGroupRow}>
                <ImgRadio
                    {...props}
                    values={valuesOptionsFromThreekit}
                    label=""
                />
                {/*isMetalLegs ? (
                    <ImgRadio
                        {...props}
                        values={valuesOptionsFromThreekit}
                        label=""
                    />
                ) : (
                    <ImgRadio
                        {...props}
                        values={valuesOptionsFromThreekit}
                        label=""
                    />
                )*/}
            </div>
        </div>
    );
};
