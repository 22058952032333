import { isSectionalConfigurator } from "../../utils/supportUtils";

export const getCouchPoin = (ModelId = false) => {
  let couchPoin;
  if (isSectionalConfigurator()) {
    const activeModel = window.player.scene.get({
      id: ModelId,
      evalNode: true,
    });
    const activeModelId = activeModel.Null.link.instanceId;
    const sceneModel = window.player.scene.get({
      from: activeModelId,
      evalNode: true,
    });
    const sceneModelId = sceneModel.Proxy.proxy.instanceId;
    couchPoin = window.player.scene.get({
      from: sceneModelId,
      name: "couch_poin",
      evalNode: true,
    });
    if (!couchPoin) {
      const mainSceneChildren = window.player.scene.getAll({
        from: sceneModelId,
        evalNode: true,
      });
      Object.keys(mainSceneChildren).map((key) => {
        let mainSceneKey = mainSceneChildren[key].name.includes("_assembly");
        if (mainSceneKey) {
          const mainScene = mainSceneChildren[key];
          if (mainScene.Null) {
            const mainSceneId = mainScene.Null.link.instanceId;
            couchPoin = window.player.scene.get({
              from: mainSceneId,
              name: "couch_poin",
              evalNode: true,
            });
          }
        }
      });
    }
  } else {
    const itemInstanceId = window.player.scene.get({
      from: window.player.instanceId,
      evalNode: true,
    });
    const sceneInstanceId = itemInstanceId.Proxy.proxy.instanceId;
    const sofaPoint = window.player.scene.get({
      from: sceneInstanceId,
      name: "sofa point",
      evalNode: true,
    });
    const sofaPointId = sofaPoint.Null.link.Proxy.nodeId;
    const activeModel = window.player.scene.get({
      from: sofaPointId,
      evalNode: true,
    });
    const activeModelId = activeModel.Proxy.proxy.instanceId;
    couchPoin = window.player.scene.get({
      from: activeModelId,
      name: "couch_poin",
      evalNode: true,
    });
  }


  return couchPoin;
};
