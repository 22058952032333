import { isSectionalConfigurator } from "../../utils/supportUtils";

export const modelHitBox = (couchPoin: any, ray: any, angle: any = undefined) => {
  const couchPoinId = couchPoin.node.sceneId;

  let hitPos;
  if (isSectionalConfigurator()) {
    const couchPoinChildren = window.player.scene.getAll({
      from: couchPoinId,
      evalNode: true,
    });
    let couchBackKey = Object.keys(couchPoinChildren).find((key) =>
      couchPoinChildren[key].name.includes("_top")
    );
    let couchBackBoundingBox;

    if (couchBackKey) {
      const couchBack = couchPoinChildren[couchBackKey];
      couchBackBoundingBox = couchBack.getBoundingBox();
    } else {
      const box = couchPoin.getBoundingBox();
      couchBackBoundingBox = {
        min: box.min,
        max: {
          x: box.max.x + 0.3,
          y: box.max.y,
          z: box.min.z + 0.3,
        },
      };
    }



    // видалити цю заглушку як поправлю баг
    // const box = couchPoin.getBoundingBox();

    //  0 
    //  max: minZ + 0.3
    // -90
    //  min: maxX + 0.3
    // -180
    //  min: maxZ + 0.3
    // -270
    //  max: minX + 0.3
    //  const maxZ = angle === 0 ? box.min.z + 0.3 : box.max.z
    //  const minX = angle === -90 ? box.max.x + 0.3 : box.min.x
    //  const minZ = angle === -180 ? box.max.z + 0.3 : box.min.z
    //  const maxX = angle === -270 ? box.min.x + 0.3 : box.max.x
    // couchBackBoundingBox = {
    //     min: {
    //         x: minX,
    //         y: box.min.y,
    //         z: minZ,
    //     },
    //     max: {
    //       x: maxX,
    //       y: box.max.y,
    //       z: maxZ,
    //     },
    //   };
    //

    hitPos = ray.intersectBox(couchBackBoundingBox);
  } else {
    const couchPoinChildren = window.player.scene.getAll({
      from: couchPoinId,
      evalNode: true,
    });
    const couchBackKey: any = Object.keys(couchPoinChildren).find((key) =>
      couchPoinChildren[key].name.includes("_top")
    );
    const couchBack = couchPoinChildren[couchBackKey];
    const couchBackBoundingBox = couchBack.getBoundingBox();
    hitPos = ray.intersectBox(couchBackBoundingBox);
  }

  return hitPos;
};
