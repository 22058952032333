import { filterFabricUI } from "../filterFabrics";
import { modelsSectionalPresets } from "./modelsSectionalPresets";

export const configurationSectionalSofa = {
  objTabs: {
    create: [
      "accordionAdditionalSections",
      "accordionArmSilhouetteWidth",
      "accordionSeatTypeCushionsAppearanceFirmness",
      "accordionLegStyleFinish",
      "accordionFurnitureFabrics",
    ],
    pillows: ["accordionPillows", "accordionPillowsFabrics"],
  },
  accordions: {
    accordionAdditionalSections: {
      type: "Accordion",
      data: {
        id: "accordionAdditionalSections",
        label: "Choose Additional Sections",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "AdditionalItems",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionArmSilhouetteWidth: {
      type: "Accordion",
      data: {
        id: "accordionArmSilhouetteWidth",
        label: "Choose Arm Silhouette",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          // {
          //     type: "SwitchCenter",
          //     data: {
          //         id: "ArmSelectorWidth",
          //         label: "Choose Silhouette Width",
          //         typeOptions: "configurationOptions",
          //         values: [
          //             {
          //                 value: 'classic',
          //                 label: 'Classic Width',
          //             },
          //             {
          //                 value: 'slope',
          //                 label: 'Slope Width',
          //             },
          //         ],
          //         value: 'classic',
          //     },
          // },
          {
            type: "StandartOptionsWrap",
            data: {
              id: "style",
              // label: "Choose Arm Silhouette",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionSeatTypeCushionsAppearanceFirmness: {
      type: "Accordion",
      data: {
        id: "accordionSeatTypeCushionsAppearanceFirmness",
        label: "Choose Seat Depth & Cushions",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "depth",
              label: "Step 1. Choose Seat Depth",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
          {
            type: "StandartOptionsWrap",
            data: {
              id: "comfort",
              label: "Step 2. Choose Cushions Type",
              note: "*Please note that there will be no visual changes",
              typeOptions: "configurationOptions",
              values: [
                {
                  name: "Gel Plush",
                  label: "Pure Form",
                  visible: true,
                  enabled: true,
                  popupInfo: 'Three foam layers provide body-contouring support with a resilient, put-together appearance.',
                },
                {
                  name: "Pluma Plush",
                  label: "Pluma Plush",
                  visible: true,
                  enabled: true,
                  popupInfo: 'Premium down blend combines with resilient foam for lasting comfort.',
                },
              ],
              value: "Gel Plush",
            },
          },
        ],
      },
    },
    accordionLegStyleFinish: {
      type: "Accordion",
      data: {
        id: "accordionLegStyleFinish",
        label: "Choose Leg Style & Finish",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "leg",
              label: "Step 1. Choose Leg Style",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
          {
            type: "FinishGroup",
            data: {
              id: "leg_material",
              label: "Step 2. Choose Leg Material",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionPresets: {
      type: "Accordion",
      data: {
        id: "accordionPresets",
        label: "Presets",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "PresetsOption",
            data: {
              id: "Presets",
              label: "",
              typeOptions: "configurationOptions",
              values: modelsSectionalPresets,
            },
          },
        ],
      },
    },
    accordionFurnitureFabrics: {
      type: "Accordion",
      data: {
        id: "accordionFurnitureFabrics",
        label: "Choose from over 500 fabrics",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "fabric",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionPillows: {
      type: "Accordion",
      data: {
        id: "accordionPillows",
        label: "Choose Pillow",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        embeddedView: [
          {
            type: "StandartOptionsWrap",
            data: {
              id: "pillows",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
    accordionPillowsFabrics: {
      type: "Accordion",
      data: {
        id: "accordionPillowsFabrics",
        label: "Pillows Fabrics",
        typeOptions: "groupingOptionsSwitching",
        switchingOptions: false,
        filter: filterFabricUI,
        embeddedView: [
          {
            type: "ImgRadio",
            data: {
              id: "PillowMaterial",
              label: "",
              typeOptions: "configurationOptions",
              values: [],
            },
          },
        ],
      },
    },
  },
};
