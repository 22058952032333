import React, { useEffect, useState } from 'react'
import s from './ErrorMessageEmailModal.module.scss'

export const ErrorMessageEmailModal = ({ errors }: any) => {

    const isErrorsEmail = errors && errors['email'] && errors['email'].length > 0;

    const [showModal, setShowModal] = useState(false);
    const [timerId, setTimerId] = useState(null) as any;

    const handleMouseEnter = () => {
        setShowModal(true);
    };

    const handleMouseLeave = () => {
        setShowModal(false);
    };

    const handleTouchStart = () => {
        setShowModal(!showModal);
    };


    useEffect(() => {
        if (showModal && window.innerWidth < 992) {
            const id: any = setTimeout(() => {
                setShowModal(false);
            }, 3000);
            setTimerId(id);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
                setTimerId(null);
            }
        };
    }, [showModal]);

    return isErrorsEmail ? (
        <div className={s.error} onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart} >
            <div className={s.errorIcon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"  >
                    <circle cx="11.5" cy="11.5" r="11.5" fill="#B57345"  ></circle>
                    <path d="M10.1667 9.96266H12.8333V18H10.1667V9.96266ZM10.4333 7.76902C10.1444 7.44537 10 7.06178 10 6.61826C10 6.17474 10.1444 5.79714 10.4333 5.48548C10.7333 5.16183 11.0889 5 11.5 5C11.9111 5 12.2611 5.16183 12.55 5.48548C12.85 5.79714 13 6.17474 13 6.61826C13 7.06178 12.85 7.44537 12.55 7.76902C12.2611 8.08068 11.9111 8.23651 11.5 8.23651C11.0889 8.23651 10.7333 8.08068 10.4333 7.76902Z" fill="white" ></path>
                </svg>
            </div>
            {showModal && <div className={s.errorModel}>
                <div className={s.listError}>
                    {errors['email'].map((error: string, index: number) => <div className={s.itemError}>
                        <span>Field {index + 1}:</span> {error} </div>)}

                </div>
            </div>}

        </div>
    ) : <></>
}
